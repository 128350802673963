import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLanguage } from '../common/languageSwitcherSlice';
import { formattedDate } from '../../helpers/DateHelper';
import axios from 'axios';
import { Loading, setLoading } from '../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../app/slices/loadingMessageSlice';
import { setAlerts } from '../../app/slices/alertSlice';
import { useTranslate } from '../../resources/useTranslate';
import envVars from '../../resources/envVars';
import ActionRequest from '../common/ActionRequest';
import { getAxiosConfig } from '../../helpers/utils';
import authService from '../api-authorization/AuthorizeService';

type SignatureRequestProps = {
  signature: {
    taskId: string;
    signingRequestTaskUrl: string;
    taskSubject: {
      fullName: string;
    };
    taskDocumentActions: {
      documentName: string;
    };
    expiryDateTime: string;
  };
};

const SignatureRequest = ({ signature }: SignatureRequestProps) => {
  const dispatch = useDispatch();

  const ns = 'construo.signatureRequests';
  const translations = {
    noSignatureName: useTranslate(`${ns}.noSignatureName`),
    signBefore: useTranslate(`${ns}.signBefore`),
    redirectToESignLoadingText: useTranslate(
      `${ns}.redirectToESignLoadingText`
    ),
  };

  const eSignTaskId = signature.taskId;
  const taskSubject = signature.taskSubject;
  const taskDocumentActions: any = signature.taskDocumentActions;
  const signatureName = taskDocumentActions[0].documentName;
  const heading = !!signatureName
    ? signatureName
    : translations.noSignatureName;

  let expiryDateTime: string | undefined = signature.expiryDateTime;
  expiryDateTime = expiryDateTime.replace(/\./g, ':'); // replace all dots (.) with (:)
  expiryDateTime = expiryDateTime.replace(/:([^:]*)$/, '.$1'); // replace last (:) with (.)

  const languageId = useSelector(selectLanguage);
  let date = formattedDate(expiryDateTime, languageId);
  // We don't want to use US date format in case of English language
  if (languageId === 'en-US') {
    date = formattedDate(expiryDateTime, 'nb');
  }

  const signingRequestsBaseUri =
    envVars.API_BASE_URI + '/user/signing-requests/';

  const redirectToESign = async (e: React.MouseEvent) => {
    e.preventDefault();
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    const url = signingRequestsBaseUri + eSignTaskId + '/redirectUrl';

    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.redirectToESignLoadingText));

    await axios
      .get(url, config)
      .then(response => {
        window.location.assign(response.data);
      })
      .catch(error => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message after submission fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  const cardNotifications = () => {
    if (!!expiryDateTime) {
      return [`${translations.signBefore} ${date}`];
    } else {
      return null;
    }
  };

  return (
    <ActionRequest
      type='signatureRequest'
      onClick={e => redirectToESign(e)}
      heading={heading}
      subHeadings={[taskSubject.fullName]}
      notifications={cardNotifications()}
    />
  );
};

export default SignatureRequest;
