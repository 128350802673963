import React from 'react';
import { useDispatch } from 'react-redux';
import { replaceAlerts } from '../../app/slices/alertSlice';

import './Alerts.scss';

const Alerts = ({ alerts }: any) => {
  const dispatch = useDispatch();
  const closeAlert = (e: any) => {
    const index = Number(e.currentTarget.dataset.index);
    const newAlerts = alerts.filter((alert: any, i: number) => {
      return alerts[i] !== alerts[index];
    });
    dispatch(replaceAlerts(newAlerts));
  };

  return (
    <>
      {alerts.length > 0 && (
        <div className='alerts'>
          {alerts.map((alert: any, index: number) => {
            const cssClass = `alert ${
              alert.type === 'info'
                ? 'alert-info'
                : alert.type === 'warning'
                ? 'alert-warning'
                : alert.type === 'error'
                ? 'alert-danger'
                : alert.type === 'success'
                ? 'alert-success'
                : 'alert-secondary'
            }`;
            const cssIconClass = `fas ${
              alert.type === 'info'
                ? 'fa-info-circle'
                : alert.type === 'warning'
                ? 'fa-exclamation-triangle'
                : alert.type === 'error'
                ? 'fa-times-circle'
                : alert.type === 'success'
                ? 'fa-check-circle'
                : ''
            }`;

            return (
              <div key={index} className={cssClass}>
                <div className='container'>
                  <i className={cssIconClass} />
                  {alert.message}
                  <div
                    className='close'
                    data-index={index}
                    onClick={closeAlert}
                  >
                    <i className='fal fa-times' />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Alerts;
