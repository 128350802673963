import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Input, TextArea } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import envVars from '../../../resources/envVars';
import HelmetTitle from '../../common/HelmetTitle';
import { useTranslate } from '../../../resources/useTranslate';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';
import {
  fetchTopics,
  resetConversationsList,
  selectTopics,
  selectTopicsFetched,
} from './conversationsSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';

import './NewMessage.scss';

const NewMessage = () => {
  const dispatch = useDispatch();

  const ns = 'construo.conversations';
  const translations = {
    backToInbox: useTranslate(`${ns}.backToInbox`),
    createNewMessage: useTranslate(`${ns}.createNewMessage`),
    messageTopic: useTranslate(`${ns}.messageTopic`),
    messageSubject: useTranslate(`${ns}.messageSubject`),
    messageText: useTranslate(`${ns}.messageText`),
    send: useTranslate(`${ns}.send`),
  };

  const baseUrl = envVars.API_BASE_URI;

  const handleNewMessageSubmit = async (submit: any) => {
    const subject = submit.messageSubject;
    const message = submit.messageText;
    const topicGuid = submit.messageTopic.topicGuid;

    const url = `${baseUrl}/user/conversations`;
    const data = JSON.stringify({
      conversation: {
        conversationTypeId: 2,
        friendlyName: subject,
        topicGuid: topicGuid,
      },
      activity: {
        content: message,
        activityTypeId: 2,
        contentTypeId: 1,
      },
    });
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, data, config);
      dispatch(resetConversationsList());
      window.location.replace('/conversations');
      return response;
    } catch (error) {
      // Show error message after Complete Action Event fails
      let errorResponse = error.response.data.Message;
      errorResponse = !!errorResponse ? errorResponse : error.message;
      dispatch(setAlerts({ message: errorResponse, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
  };

  const topics = useSelector(selectTopics);
  const topicsFetched = useSelector(selectTopicsFetched);

  useEffect(() => {
    if (topicsFetched === null) {
      const url = `${baseUrl}/topics`;
      const dataObject = {
        url,
      };
      dispatch(fetchTopics(dataObject));
    }
    return () => {
      dispatch(resetAlerts());
    };
  }, [dispatch, baseUrl, topicsFetched]);

  return (
    <>
      <HelmetTitle title={translations.createNewMessage} />
      <section className='main-section new-message'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <header>
                <NavLink className='back-link' to='/conversations'>
                  {translations.backToInbox}
                </NavLink>
                <h1>{translations.createNewMessage}</h1>
              </header>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <Form
                initialValues={{}}
                onSubmit={handleNewMessageSubmit}
                render={(formRenderProps: FormRenderProps) => (
                  <FormElement>
                    <Field
                      name={'messageTopic'}
                      data={topics}
                      textField='name'
                      dataItemKey='topicGuid'
                      value='Value'
                      component={DropDownList}
                      label={translations.messageTopic}
                    />
                    <Field
                      name={'messageSubject'}
                      component={Input}
                      label={translations.messageSubject}
                    />
                    <Field
                      name={'messageText'}
                      component={TextArea}
                      label={translations.messageText}
                      placeholder={translations.messageText}
                    />
                    <Button primary onClick={formRenderProps.onSubmit}>
                      {translations.send}
                    </Button>
                  </FormElement>
                )}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewMessage;
