import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const RequiredActionSkeleton = () => {
  const item = (
    <div className='action-request'>
      <div className='k-card k-card-horizontal'>
        <figure>
          <Skeleton
            shape='rectangle'
            style={{ height: 80 }}
            className='action-request-image'
          />
        </figure>
        <div className='k-card-body'>
          <Skeleton shape='text' style={{ maxWidth: 150 }} />
          <Skeleton shape='text' style={{ maxWidth: 500, height: 40 }} />
          <Skeleton shape='text' style={{ maxWidth: 200 }} />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {item}
      {item}
      {item}
    </>
  );
};

export default RequiredActionSkeleton;
