import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@progress/kendo-react-inputs';
import { useTranslate } from '../../../resources/useTranslate';

import {
  setStateFilters,
  setPageNumber,
  selectDocumentsFilter,
} from './documentsSlice';

import './QuickSearch.scss';

type QuickSearchProps = {};

const QuickSearch = (props: QuickSearchProps) => {
  const dispatch = useDispatch();
  const ns = 'construo.documents';
  const translations = {
    filterByDocumentName: useTranslate(`${ns}.filterByDocumentName`),
  };
  const filter = useSelector(selectDocumentsFilter);
  // Filter by document name - quick search
  const handleFilterByDocumentName = (value: string | null) => {
    dispatch(setPageNumber(1));
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'DocumentName';
    });
    dispatch(
      setStateFilters({
        ...filter,
        filters: !!value
          ? [
              ...filtersReduced,
              { field: 'DocumentName', operator: 'contains', value: value },
            ]
          : [...filtersReduced],
      })
    );
  };

  return (
    <div className='quick-search row'>
      <div className='col-md-12'>
        <Input
          type='text'
          name='document_name'
          label={translations.filterByDocumentName}
          onChange={e => handleFilterByDocumentName(e.value)}
        />
        <i className='fal fa-search' />
      </div>
    </div>
  );
};

export default QuickSearch;
