import React, { useEffect } from 'react';
import authService, { AuthenticationResultStatus } from './AuthorizeService';
import {
  QueryParameterNames,
  LogoutActions,
  ApplicationPaths,
} from './ApiAuthorizationConstants';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage, removeUser, selectUser } from './userSlice';
import { NavLink } from 'react-router-dom';
import { useTranslate } from '../../resources/useTranslate';
import { navigateToReturnUrl } from '../../helpers/utils';
import OverallLoader from '../common/OverallLoader';

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
interface Props {
  action: string;
}

export const Logout = ({ action }: Props) => {
  const dispatch = useDispatch();
  const userState = useSelector(selectUser);

  const loginURL = ApplicationPaths.Login;

  const ns = 'construo.errors';
  const translations = {
    login: useTranslate('construo.homepage.login'),
    processingLogout: useTranslate('construo.global.processingLogout'),
    processingLogoutCallback: useTranslate(
      'construo.global.processingLogoutCallback'
    ),
    successfullyLoggedOut: useTranslate(
      'construo.global.successfullyLoggedOut'
    ),
    gotoHomepage: useTranslate('construo.global.gotoHomepage'),
    invalidAuth: useTranslate(`${ns}.invalidAuth`),
    shouldNotRedirect: useTranslate(`${ns}.shouldNotRedirect`),
    invalidAction: useTranslate(`${ns}.invalidAction`),
    invalidReturnUrl: useTranslate(`${ns}.invalidReturnUrl`),
  };

  useEffect(() => {
    const logout = async (returnUrl: string): Promise<void> => {
      if (userState.authenticated) {
        const result = await authService.signOut();
        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            break;
          case AuthenticationResultStatus.Success:
            dispatch(removeUser());
            navigateToReturnUrl(returnUrl);
            break;
          case AuthenticationResultStatus.Fail:
            dispatch(showMessage(result.message));
            break;
          default:
            throw new Error(translations.invalidAuth);
        }
      } else {
        dispatch(showMessage(translations.successfullyLoggedOut));
      }
    };
    const getReturnUrl = (state?: any) => {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(QueryParameterNames.ReturnUrl);
      if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error(translations.invalidReturnUrl);
      }
      return (
        (state && state.returnUrl) ||
        fromQuery ||
        `${window.location.origin}${ApplicationPaths.LoggedOut}`
      );
    };

    const processLogoutCallback = async (): Promise<void> => {
      if (userState.authenticated) {
        const url = window.location.href;
        const result = await authService.completeSignOut(url);
        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            // There should not be any redirects as the only time completeAuthentication finishes
            // is when we are doing a redirect sign in flow.
            throw new Error(translations.shouldNotRedirect);
          case AuthenticationResultStatus.Success:
            dispatch(removeUser());
            navigateToReturnUrl(getReturnUrl(result.state));
            break;
          case AuthenticationResultStatus.Fail:
            dispatch(showMessage(result.message));
            break;
          default:
            throw new Error(translations.invalidAuth);
        }
      } else {
        navigateToReturnUrl(getReturnUrl(null));
      }
    };

    switch (action) {
      case LogoutActions.Logout:
        logout(getReturnUrl());
        break;
      case LogoutActions.LogoutCallback:
        processLogoutCallback();
        break;
      case LogoutActions.LoggedOut:
        dispatch(showMessage(translations.successfullyLoggedOut));
        break;
      default:
        throw new Error(`${translations.invalidAction} '${action}'`);
    }
    return () => {
      dispatch(showMessage(''));
    };
  }, [
    action,
    dispatch,
    userState.authenticated,
    userState.username,
    translations.invalidAction,
    translations.invalidAuth,
    translations.invalidReturnUrl,
    translations.shouldNotRedirect,
    translations.successfullyLoggedOut,
  ]);

  if (!!userState.message) {
    return (
      <>
        <div className='main-section'>
          <div className='container'>
            <h1>{userState.message}</h1>
            <p>
              {translations.gotoHomepage} &nbsp;
              <NavLink to={loginURL}>{translations.login}</NavLink>
            </p>
          </div>
        </div>
      </>
    );
  } else {
    switch (action) {
      case LogoutActions.Logout:
        return <OverallLoader message={translations.processingLogout} />;
      case LogoutActions.LogoutCallback:
        return (
          <OverallLoader message={translations.processingLogoutCallback} />
        );
      case LogoutActions.LoggedOut:
        return (
          <>
            <div className='main-section'>
              <div className='container'>
                <h2>{userState.message}</h2>
                <p>
                  {translations.gotoHomepage}&nbsp;
                  <NavLink to={loginURL}>{translations.login}</NavLink>
                </p>
              </div>
            </div>
          </>
        );
      default:
        throw new Error(`${translations.invalidAction} '${action}'`);
    }
  }
};
