export const noFormioMessages = {
  unsavedRowsError: 'Vennligst lagre alle rader før du går videre.',
  invalidRowsError: 'Vennligst korriger ugyldige rader før du går videre.',
  invalidRowError: 'Ugyldig rad. Vennligst korriger eller slett.',
  alertMessageWithLabel: '{{label}}: {{message}}',
  alertMessage: '{{message}}',
  complete: 'Innsending gjennomført',
  error: 'Vennligst korriger følgende feil før innsending.',
  errorListHotkey: 'Tast Ctrl + Alt + X for å gå tilbake til errorliste.',
  errorsListNavigationMessage:
    'Klikk for å navigere til felt med følgende feil.',
  submitError:
    'Vennligst kontroller skjemaet og korriger alle feil før innsending.',
  required: '{{field}} er obligatorisk',
  unique: '{{field}} må være unikt',
  array: '{{field}} må være en array',
  array_nonempty: '{{field}} må være en array som ikke er tom',
  nonarray: '{{field}} kan ikke være en array',
  select: '{{field}} inneholder et ugyldig valg',
  pattern: '{{field}} samsvarer ikke med mønsteret {{pattern}}',
  minLength: '{{field}} må inneholde minst {{length}} tegn.',
  maxLength: '{{field}} kan ikke inneholde flere enn {{length}} tegn.',
  minWords: '{{field}} må inneholde minst {{length}} ord.',
  maxWords: '{{field}} kan ikke inneholde flere enn {{length}} ord.',
  min: '{{field}} kan ikke være mindre enn {{min}}.',
  max: '{{field}} kan ikke være større enn {{max}}.',
  maxDate: '{{field}} skal ikke inneholde dato etter {{- maxDate}}',
  minDate: '{{field}} skal ikke inneholde dato før {{- minDate}}',
  maxYear: '{{field}} skal ikke inneholde årstall etter {{maxYear}}',
  minYear: '{{field}} skal ikke inneholde årstall før {{minYear}}',
  invalid_email: '{{field}} må være en gyldig e-post.',
  invalid_url: '{{field}} må være en gyldig url.',
  invalid_regex: '{{field}} samsvarer ikke med mønsteret {{regex}}.',
  invalid_date: '{{field}} er ikke en gyldig dato.',
  invalid_day: '{{field}} er ikke en gyldig dag.',
  mask: '{{field}} samsvarer ikke med masken.',
  valueIsNotAvailable: '{{ field }} er en ugyldig verdi.',
  stripe: '{{stripe}}',
  month: 'måned',
  Month: 'Måned',
  day: 'dag',
  Day: 'Dag',
  year: 'år',
  Year: 'År',
  january: 'januar',
  January: 'Januar',
  february: 'februar',
  February: 'Februar',
  march: 'mars',
  March: 'Mars',
  april: 'april',
  April: 'April',
  may: 'mai',
  May: 'Mai',
  june: 'juni',
  June: 'Juni',
  july: 'juli',
  July: 'Juli',
  august: 'august',
  August: 'August',
  september: 'september',
  September: 'September',
  october: 'oktober',
  October: 'Oktober',
  november: 'november',
  November: 'November',
  december: 'desember',
  December: 'Desember',
  next: 'Neste',
  previous: 'Forrige',
  cancel: 'Avbryt',
  submit: 'Send inn skjema',
  confirmCancel: 'Er du sikker på at du vil avbryte?',
  saveDraftInstanceError:
    'Kan ikke lagre kladd fordi formio-instans ikke finnes.',
  saveDraftAuthError: 'Kan ikke lagre kladd med mindre bruker er autentisert.',
  restoreDraftInstanceError:
    'Kan ikke gjenopprette kladd fordi formio-instans ikke finnes.',
  time: 'Ugyldig tidspunkt',
};
