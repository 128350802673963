import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export enum Modal {
  Show = 'SHOW',
  Hide = 'HIDE',
}

const initialState = Modal.Hide;

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal(state, action: PayloadAction<Modal>) {
      return action.payload;
    },
  },
});

export const { setModal } = modalSlice.actions;

export const selectModal = (state: RootState) => state.modal;

export default modalSlice.reducer;
