import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import { selectUser, UserState } from '../api-authorization/userSlice';
import {
  setVisibilityMenu,
  VisibilityMenu,
} from '../../app/slices/visibilityMenuSlice';
import { GlobalMenuLabelTypes } from './GlobalMenu';

import './LoginMenu.scss';

export interface LoginMenuProps {
  onHideMenu: () => void;
  userProps: UserState;
  labels: GlobalMenuLabelTypes;
}

const LoginMenu = ({ onHideMenu, userProps, labels }: LoginMenuProps) => {
  const dispatch = useDispatch();
  const userStateSelect = useSelector(selectUser);

  const userState = userProps === undefined ? userStateSelect : userProps;

  const loginPath = `${ApplicationPaths.Login}`;
  const logoutPath = `${ApplicationPaths.LogOut}`;

  return (
    <>
      {!userState.authenticated && (
        <>
          <div className='user-info'>
            <NavLink
              className='k-button k-primary btn-sm'
              to={loginPath}
              onClick={e => {
                dispatch(setVisibilityMenu(VisibilityMenu.Hide));
              }}
            >
              {labels.login}
            </NavLink>
          </div>
        </>
      )}

      {userState.authenticated && (
        <div className='user-info'>
          <p className='h3'>{userState.username}</p>
          <p>
            <NavLink
              className='k-button k-primary btn-sm'
              to={logoutPath}
              onClick={onHideMenu}
            >
              {labels.logout}
            </NavLink>
          </p>
        </div>
      )}
    </>
  );
};

export default LoginMenu;
