import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import HelmetTitle from '../common/HelmetTitle';
import { useTranslate } from '../../resources/useTranslate';
import { Login } from './Login';
import { Logout } from './Logout';
import {
  ApplicationPaths,
  LoginActions,
  LogoutActions,
} from './ApiAuthorizationConstants';

export const ApiAuthorizationRoutes = () => {
  const ns = 'construo.global';
  const translations = {
    login: useTranslate(`${ns}.loginTitle`),
    logout: useTranslate(`${ns}.logoutTitle`),
  };

  const LoginAction = (name: string) => {
    return (
      <>
        <HelmetTitle title={translations.login} />
        <Login action={name}></Login>
      </>
    );
  };
  const LogoutAction = (name: string) => {
    return (
      <>
        <HelmetTitle title={translations.logout} />
        <Logout action={name}></Logout>
      </>
    );
  };

  return (
    <Fragment>
      <Route
        path={ApplicationPaths.Login}
        render={() => LoginAction(LoginActions.Login)}
      />
      <Route
        path={ApplicationPaths.LoginFailed}
        render={() => LoginAction(LoginActions.LoginFailed)}
      />
      <Route
        path={ApplicationPaths.LoginCallback}
        render={() => LoginAction(LoginActions.LoginCallback)}
      />
      <Route
        path={ApplicationPaths.LogOut}
        render={() => LogoutAction(LogoutActions.Logout)}
      />
      <Route
        path={ApplicationPaths.LogOutCallback}
        render={() => LogoutAction(LogoutActions.LogoutCallback)}
      />
      <Route
        path={ApplicationPaths.LoggedOut}
        render={() => LogoutAction(LogoutActions.LoggedOut)}
      />
    </Fragment>
  );
};
