import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { useTranslate } from '../../../resources/useTranslate';
import DocumentModal from '../documents/DocumentModal';
import DocumentsList from '../documents/DocumentsList';
import SignatureRequestList from '../../signatures/SignatureRequestList';
import UploadRequestList from '../../upload-requests/UploadRequestList';
import { Loading, selectLoading } from '../../../app/slices/loadingSlice';
import { Modal, selectModal } from '../../../app/slices/modalSlice';
import {
  clearDocumentsError,
  populateDocumentsData,
  resetDocuments,
  selectDocError,
  selectDocFetched,
  selectDocumentsData,
} from '../documents/documentsSlice';
import {
  clearSignaturesError,
  DigitalIdentityProps,
  selectDigitalIdentity,
  selectSignatureError,
  selectSignatures,
  selectSignaturesFetched,
} from '../../signatures/signaturesSlice';
import {
  clearUploadsError,
  selectUploadError,
  selectUploads,
  selectUploadsFetched,
} from '../../upload-requests/uploadSlice';
import {
  selectSubmissions,
  selectSubmissionsError,
  selectSubmissionsFetched,
} from '../../submissions/submissionsSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import envVars from '../../../resources/envVars';
import RequiredActionSkeleton from './RequiredActionSkeleton';
import SubmissionsList from '../../submissions/SubmissionsList';
import WorkflowsList from '../../workflows/WorkflowsList';
import {
  selectWorkflows,
  selectWorkflowsError,
  selectWorkflowsFetched,
} from '../../workflows/workflowSlice';

type HomePrivateProps = {
  userName: string | null;
};

const HomePrivate = (props: HomePrivateProps) => {
  const { userName } = props;

  const isDocumentListFeature = envVars.FEATURES?.includes('documentList');
  const isESignRequestsFeature = envVars.FEATURES?.includes('eSignRequests');
  const isUploadRequestsFeature = envVars.FEATURES?.includes('uploadRequests');
  const isFormSharingFeature = envVars.FEATURES?.includes('formRequests');
  const isWorkflowsFeature = envVars.FEATURES?.includes('workflows');

  const [docErrorSet, setDocErrorSet] = useState(false);
  const [workflowErrorSet, setWorkflowErrorSet] = useState(false);
  const [submissionErrorSet, setSubmissionErrorSet] = useState(false);
  const [uploadErrorSet, setUploadErrorSet] = useState(false);
  const [signatureErrorSet, setSignatureErrorSet] = useState(false);
  const [isLoadingRequiredActions, setIsLoadingRequiredActions] = useState(
    true
  );

  const [areActionsVisible, setAreActionsVisible] = useState(true);

  const dispatch = useDispatch();

  const signatures: object[] = useSelector(selectSignatures);
  const signaturesFetched = useSelector(selectSignaturesFetched);
  const signatureError = useSelector(selectSignatureError);

  const submissions: object[] = useSelector(selectSubmissions);
  const submissionsFetched = useSelector(selectSubmissionsFetched);
  const submissionError = useSelector(selectSubmissionsError);

  const uploads: object[] = useSelector(selectUploads);
  const uploadsFetched = useSelector(selectUploadsFetched);
  const uploadError = useSelector(selectUploadError);

  const workflows: object[] = useSelector(selectWorkflows);
  const workflowsFetched = useSelector(selectWorkflowsFetched);
  const workflowError = useSelector(selectWorkflowsError);

  const documents = useSelector(selectDocumentsData);
  const docFetched = useSelector(selectDocFetched);
  const docError = useSelector(selectDocError);

  useEffect(() => {
    if (!!signatureError && !signatureErrorSet) {
      setSignatureErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: signatureError, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
    if (!!uploadError && !uploadErrorSet) {
      setUploadErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: uploadError, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
    if (!!submissionError && !submissionErrorSet) {
      setSubmissionErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: submissionError, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }

    if (!!workflowError && !workflowErrorSet) {
      setWorkflowErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: workflowError, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }

    if (!!docError && !docErrorSet) {
      setDocErrorSet(true);
      // Show error message
      dispatch(setAlerts({ message: docError, type: 'error' }));
      dispatch(clearDocumentsError()); // Clear documents -> error prop immediately when doc alert is added - setAlerts for docs
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
  }, [
    dispatch,
    workflowError,
    workflowErrorSet,
    submissionError,
    submissionErrorSet,
    uploadError,
    uploadErrorSet,
    signatureError,
    signatureErrorSet,
    docError,
    docErrorSet,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearSignaturesError());
      dispatch(clearUploadsError());
      dispatch(resetDocuments());
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const urlString = window.location.href;
  const url = new URL(urlString);
  const esignStatus = url.searchParams.get('esign');

  const API_BASE_URI = envVars.API_BASE_URI;
  const RECENT_DOC_NO = envVars.RECENT_DOC_NO;
  const recentDocNo: number = RECENT_DOC_NO || 5;

  const ns = 'construo.homepage';
  const translations = {
    welcome: useTranslate(`${ns}.welcome`, userName),
    actionRequired: useTranslate(`${ns}.actionRequired`),
    latestDocuments: useTranslate(`${ns}.latestDocuments`, recentDocNo),
    goToDocuments: useTranslate(`${ns}.goToDocuments`),
    signingCompleted: useTranslate(`${ns}.signingCompleted`),
    signingCompletedText: useTranslate(`${ns}.signingCompletedText`),
    signingUncompleted: useTranslate(`${ns}.signingUncompleted`),
    signingUncompletedText: useTranslate(`${ns}.signingUncompletedText`),
  };

  useEffect(() => {
    const dataObject = {
      baseUrl: API_BASE_URI,
      recent: recentDocNo,
    };
    if (docFetched === null && isDocumentListFeature) {
      dispatch(populateDocumentsData(dataObject));
    }
  }, [dispatch, docFetched, isDocumentListFeature, API_BASE_URI, recentDocNo]);

  // Found out about user Digital Identity
  const digitalIdentity: DigitalIdentityProps | null = useSelector(
    selectDigitalIdentity
  );
  const nin = digitalIdentity?.nin;
  const idp = digitalIdentity?.idp;
  const isUserMissingNinOrIdp = !nin || !idp?.includes('bankid');

  useEffect(() => {
    if (
      ((signaturesFetched || !isESignRequestsFeature) &&
        (uploadsFetched || !isUploadRequestsFeature) &&
        (submissionsFetched || !isFormSharingFeature) &&
        (workflowsFetched || !isWorkflowsFeature)) ||
      (signaturesFetched === null && isUserMissingNinOrIdp) // when user doesn't have NIN or Idp signature requests can't be fetched
    ) {
      setIsLoadingRequiredActions(false);
    } else {
      setIsLoadingRequiredActions(true);
    }
  }, [
    dispatch,
    signaturesFetched,
    uploadsFetched,
    submissionsFetched,
    workflowsFetched,
    isESignRequestsFeature,
    isFormSharingFeature,
    isUploadRequestsFeature,
    isWorkflowsFeature,
    isLoadingRequiredActions,
    isUserMissingNinOrIdp,
  ]);

  const filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };
  const sort: SortDescriptor[] = [{ field: 'DocumentDate', dir: 'desc' }];

  const modalShow = useSelector(selectModal);
  const docLoading = useSelector(selectLoading) === Loading.Show;

  const allFetched =
    signaturesFetched &&
    uploadsFetched &&
    submissionsFetched &&
    workflowsFetched;

  const noActions =
    signatures.length === 0 &&
    uploads.length === 0 &&
    submissions.length === 0 &&
    workflows.length === 0;

  useEffect(() => {
    if (allFetched && noActions) {
      setAreActionsVisible(false);
    }
  }, [allFetched, noActions]);

  return (
    <>
      {documents.length > 0 && modalShow === Modal.Show && !docLoading && (
        <DocumentModal />
      )}

      {!!esignStatus && (
        <section className='main-section  '>
          <div className='container'>
            {esignStatus === 'success' && (
              <div className='alert alert-success alert-signing' role='alert'>
                <div className='icon'>
                  <i
                    className='fas fa-check-circle'
                    style={{ fontSize: '24px' }}
                  />
                </div>
                <div className='text'>
                  <h5>{translations.signingCompleted}</h5>
                  <p>{translations.signingCompletedText}</p>
                </div>
              </div>
            )}
            {esignStatus === 'failed' && (
              <div className='alert alert-danger alert-signing' role='alert'>
                <div className='icon'>
                  <i
                    className='fas fa-exclamation-circle'
                    style={{ fontSize: '24px' }}
                  />
                </div>
                <div className='text'>
                  <h5>{translations.signingUncompleted}</h5>
                  <p>{translations.signingUncompletedText}</p>
                </div>
              </div>
            )}
          </div>
        </section>
      )}

      <header className='header main-section'>
        <div className='container'>
          <h1>
            {/* TODO - Adjust welcome message depending on time of the day */}
            {translations.welcome}
          </h1>
        </div>
      </header>

      <section
        className={`action-required main-section ${
          areActionsVisible ? '' : 'hidden'
        }`}
      >
        <div className='container'>
          <h2>{translations.actionRequired}</h2>

          <div className='requests'>
            {/* E-SIGN REQUESTS */}
            {isESignRequestsFeature && <SignatureRequestList />}

            {/* DOCUMENT UPLOAD REQUESTS */}
            {isUploadRequestsFeature && <UploadRequestList />}

            {/* SUBMISSION REQUESTS */}
            {isFormSharingFeature && <SubmissionsList />}

            {/* WORKFLOWS */}
            {isWorkflowsFeature && <WorkflowsList />}

            {isLoadingRequiredActions && <RequiredActionSkeleton />}
          </div>
        </div>
      </section>

      {/* RECENT DOCUMENTS - Latest N documents defined in configuration file  */}
      {isDocumentListFeature && (
        <section className='latest-documents main-section'>
          <div className='container'>
            <h2>{translations.latestDocuments}</h2>
            <DocumentsList
              filtered={documents}
              documentsLength={0}
              filter={filter}
              sort={sort}
              docFetched={docFetched}
            />
            {documents.length > 0 && (
              <div className='goto-documents'>
                <NavLink to='/documents'>{translations.goToDocuments}</NavLink>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default HomePrivate;
