import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../api-authorization/userSlice';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import HomePrivate from './HomePrivate';
import HomePublic from './HomePublic';
import HelmetTitle from '../../common/HelmetTitle';
import { useTranslate } from '../../../resources/useTranslate';

import './Home.scss';

const Home = () => {
  const userState = useSelector(selectUser);
  const isUserLogged = userState.authenticated;
  const userName = userState.username;
  const loginPath = ApplicationPaths.Login;

  return (
    <>
      <HelmetTitle title={useTranslate('construo.menu.home')} />

      {isUserLogged ? (
        <HomePrivate userName={userName} />
      ) : (
        <HomePublic loginPath={loginPath} />
      )}
    </>
  );
};

export default Home;
