import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const ConversationsListSkeleton = () => {
  const item = (
    <div className='list-item'>
      <div className='list-item-conversation'>
        <div className='list-item-conversation-name'>
          <Skeleton shape={'text'} style={{ width: 250 }} />
        </div>
        <div className='list-item-date'>
          <Skeleton shape={'text'} style={{ width: 80 }} />
        </div>
      </div>
    </div>
  );

  return (
    <div className='k-listview conversations-list'>
      {item}
      {item}
      {item}
    </div>
  );
};

export default ConversationsListSkeleton;
