import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface SettingsState {
  messagesLoaded: boolean;
  stylesLoaded: boolean;
}

const initialState: SettingsState = {
  messagesLoaded: false,
  stylesLoaded: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  reducers: {
    setMessagesLoaded(state) {
      state.messagesLoaded = true;
    },
    setStylesLoaded(state) {
      state.stylesLoaded = true;
    },
  },
});

export const { setMessagesLoaded, setStylesLoaded } = settingsSlice.actions;

export const selectMessagesLoaded = (state: RootState) =>
  state.settings.messagesLoaded;
export const selectStylesLoaded = (state: RootState) =>
  state.settings.stylesLoaded;

export default settingsSlice.reducer;
