import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
} from '@progress/kendo-react-layout';

import './Task.scss';

export interface TaskStatusProps {
  subtitleClassName: string;
  subtitle: string;
  statusIconClassName:
    | 'hidden-icon'
    | 'quarter-circle'
    | 'half-circle'
    | 'three-quarters-circle'
    | 'check-mark';
}

export interface TaskProps {
  taskId: string;
  taskName: string;
  taskStatus: TaskStatusProps;
  conversationsUnreadMessages: number;
  unreadMessage: string;
}
const Task = ({
  taskId,
  taskName,
  taskStatus,
  conversationsUnreadMessages,
  unreadMessage,
}: TaskProps) => {
  return (
    <NavLink
      key={taskId}
      to={`/user/tasks/${taskId}`}
      className='workflow-task'
    >
      <Card>
        <CardBody className='workflow-task-body'>
          <div className='task-text'>
            <CardTitle>{taskName}</CardTitle>
            {!!taskStatus.subtitle && (
              <CardSubtitle className={taskStatus.subtitleClassName}>
                {taskStatus.subtitle}
              </CardSubtitle>
            )}
            {!!conversationsUnreadMessages && (
              <span className='badge bg-primary rounded-pill'>
                {unreadMessage}
              </span>
            )}
          </div>

          <div
            className={`task-status-icon ${taskStatus.statusIconClassName}`}
          />
        </CardBody>
      </Card>
    </NavLink>
  );
};

export default Task;
