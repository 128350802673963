import { useLocalization } from '@progress/kendo-react-intl';

export const useTranslate = (term: string, ...args: any) => {
  const localizationService = useLocalization();
  const key: string = term;
  const defaultValue: string = term;
  let message = localizationService.toLanguageString(key, defaultValue);

  if (/\{[0-9]+}/gi.test(message)) {
    args.forEach((value: any, i: number) => {
      var regEx = new RegExp(`(\\{${i}\\})+`, 'gi');
      message = message.replace(regEx, value);
    });
  }

  return message;
};
