export const svFormioMessages = {
  unsavedRowsError: '[SV] Please save all rows before proceeding.',
  invalidRowsError: '[SV] Please correct invalid rows before proceeding.',
  invalidRowError: '[SV] Invalid row. Please correct it or delete.',
  alertMessageWithLabel: '[SV] {{label}}: {{message}}',
  alertMessage: '[SV] {{message}}',
  complete: '[SV] Submission Complete',
  error: '[SV] Please fix the following errors before submitting.',
  errorListHotkey: '[SV] Press Ctrl + Alt + X to go back to the error list.',
  errorsListNavigationMessage:
    '[SV] Click to navigate to the field with following error.',
  submitError:
    '[SV] Please check the form and correct all errors before submitting.',
  required: '[SV] {{field}} is required',
  unique: '[SV] {{field}} must be unique',
  array: '[SV] {{field}} must be an array',
  array_nonempty: '[SV] {{field}} must be a non-empty array',
  nonarray: '[SV] {{field}} must not be an array',
  select: '[SV] {{field}} contains an invalid selection',
  pattern: '[SV] {{field}} does not match the pattern {{pattern}}',
  minLength: '[SV] {{field}} must have at least {{length}} characters.',
  maxLength: '[SV] {{field}} must have no more than {{length}} characters.',
  minWords: '[SV] {{field}} must have at least {{length}} words.',
  maxWords: '[SV] {{field}} must have no more than {{length}} words.',
  min: '[SV] {{field}} cannot be less than {{min}}.',
  max: '[SV] {{field}} cannot be greater than {{max}}.',
  maxDate: '[SV] {{field}} should not contain date after {{- maxDate}}',
  minDate: '[SV] {{field}} should not contain date before {{- minDate}}',
  maxYear: '[SV] {{field}} should not contain year greater than {{maxYear}}',
  minYear: '[SV] {{field}} should not contain year less than {{minYear}}',
  invalid_email: '[SV] {{field}} must be a valid email.',
  invalid_url: '[SV] {{field}} must be a valid url.',
  invalid_regex: '[SV] {{field}} does not match the pattern {{regex}}.',
  invalid_date: '[SV] {{field}} is not a valid date.',
  invalid_day: '[SV] {{field}} is not a valid day.',
  mask: '[SV] {{field}} does not match the mask.',
  valueIsNotAvailable: '[SV] {{ field }} is an invalid value.',
  stripe: '[SV] {{stripe}}',
  month: '[SV] Month',
  Month: '[SV] Month',
  day: '[SV] Day',
  Day: '[SV] Day',
  year: '[SV] Year',
  Year: '[SV] Year',
  january: '[SV] January',
  January: '[SV] January',
  february: '[SV] February',
  February: '[SV] February',
  march: '[SV] March',
  March: '[SV] March',
  april: '[SV] April',
  April: '[SV] April',
  may: '[SV] May',
  May: '[SV] May',
  june: '[SV] June',
  June: '[SV] June',
  july: '[SV] July',
  July: '[SV] July',
  august: '[SV] August',
  August: '[SV] August',
  september: '[SV] September',
  September: '[SV] September',
  october: '[SV] October',
  October: '[SV] October',
  november: '[SV] November',
  November: '[SV] November',
  december: '[SV] December',
  December: '[SV] December',
  next: '[SV] Next',
  previous: '[SV] Previous',
  cancel: '[SV] Cancel',
  submit: '[SV] Submit Form',
  confirmCancel: '[SV] Are you sure you want to cancel?',
  saveDraftInstanceError:
    '[SV] Cannot save draft because there is no formio instance.',
  saveDraftAuthError: '[SV] Cannot save draft unless a user is authenticated.',
  restoreDraftInstanceError:
    '[SV] Cannot restore draft because there is no formio instance.',
  time: '[SV] Invalid time',
};
