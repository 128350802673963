import React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { formattedDate } from '../../helpers/DateHelper';
import { Loading, setLoading } from '../../app/slices/loadingSlice';
import { Modal, setModal } from '../../app/slices/modalSlice';
import { selectLanguage } from './languageSwitcherSlice';
import {
  selectBulkDownloadItems,
  setBulkDownloadItems,
  setPreviewProps,
} from '../pages/documents/documentsSlice';
import { setLoadingMessage } from '../../app/slices/loadingMessageSlice';
import { setAlerts } from '../../app/slices/alertSlice';
import { useTranslate } from '../../resources/useTranslate';
import envVars from '../../resources/envVars';
import { getAxiosConfig } from '../../helpers/utils';
import authService from '../api-authorization/AuthorizeService';

import './ListItem.scss';

export interface ListItemProps {
  props: any;
  checkedArray: [];
}

const ListItem = ({ props, checkedArray }: any) => {
  const dispatch = useDispatch();

  const ns = 'construo.documents';
  const translations = {
    loadingDocumentPreview: useTranslate(`${ns}.loadingDocumentPreview`),
  };

  let item = props.dataItem;
  const itemId = item._id;

  const checked =
    checkedArray.length > 0 &&
    checkedArray.filter((checked: any) => checked._id === itemId);
  const isChecked = checked.length > 0;

  const languageId = useSelector(selectLanguage);
  let date = formattedDate(item.DocumentDate, languageId);
  // We don't want to use US date format in case of English language
  if (languageId === 'en-US') {
    date = formattedDate(item.DocumentDate, 'nb');
  }

  const bulkDownloadItems = useSelector(selectBulkDownloadItems);

  const API_BASE_URI = envVars.API_BASE_URI;

  type itemProps = {
    _id: string;
    Category: string;
    DocumentName: string;
    FileExtension: string;
  };

  const handleDocumentNameClick = async (
    e: React.MouseEvent,
    item: itemProps,
    contacts: object[]
  ) => {
    e.preventDefault();

    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.loadingDocumentPreview));

    const name = item.DocumentName;
    const ext = item.FileExtension;
    const documentCategory = item.Category;
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'blob');
    const url =
      API_BASE_URI + '/user/documents/CustomerPortal/' + itemId + '/download';

    await axios
      .get(url, config)
      .then((response: any) => response.data)
      .then((blob: any) => {
        dispatch(setModal(Modal.Show));
        showDocument(blob, name, ext, documentCategory, contacts);
      })
      .catch(error => {
        // Hide and reset loading msg
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message if getting document fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  const showDocument = (
    blob: any,
    name: string,
    ext: string,
    category: string,
    contacts: object[]
  ) => {
    const newBlob = new Blob([blob], { type: blob.type });

    // // IE doesn't allow using a blob object directly as link href
    // // instead it is necessary to use msSaveOrOpenBlob
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveOrOpenBlob(newBlob);
    //   return;
    // }
    // // For other browsers:
    // // Create a link pointing to the ObjectURL containing the blob.
    // const data = window.URL.createObjectURL(newBlob);

    const fileURL = window.URL.createObjectURL(newBlob);

    dispatch(setLoading(Loading.Hide));
    dispatch(setLoadingMessage(null));

    dispatch(
      setPreviewProps({
        docType: blob.type,
        url: fileURL,
        name: `${name}${ext}`,
        category: category,
        contacts: contacts,
      })
    );

    const link = document.createElement('a');
    link.textContent = ` ${name}${ext}`;
    link.href = fileURL;
    link.setAttribute('target', '_blank');
    const appendContainer: HTMLElement | null = document.getElementById(
      'append-container'
    );
    appendContainer?.appendChild(link);
  };

  const handleCheckboxChange = (e: any, item: any) => {
    const checkObject = {
      _id: itemId,
      FileName: item.FileName,
      checked: e.value,
    };
    let newArray: object[];
    if (checkObject.checked) {
      // add element to array
      newArray = [...bulkDownloadItems, checkObject];
    } else {
      // remove element from array
      newArray = bulkDownloadItems.filter(
        (el: any) => el._id !== checkObject._id
      );
    }
    dispatch(setBulkDownloadItems(newArray));
  };

  const contactsList =
    item.Contacts?.length > 0
      ? item.Contacts?.map((contact: any, index: number) => {
          const fullName = contact.FullName;
          return <span key={index}>{fullName}</span>;
        })
      : '';

  return (
    <>
      <div className='list-item'>
        <div className='list-item-checkbox'>
          <Checkbox
            checked={isChecked}
            onChange={e => handleCheckboxChange(e, item)}
          />
        </div>

        <div className='list-item-document'>
          <div className='list-item-document-name'>
            {item.Category && (
              <div className='list-item-document-category'>{item.Category}</div>
            )}
            <a
              className='h5'
              href={`/documents/download/${itemId}`}
              onClick={e => handleDocumentNameClick(e, item, item.Contacts)}
            >
              {item.DocumentName}
            </a>
            <div className='list-item-document-contacts'>{contactsList}</div>
          </div>
          <div className='list-item-date'>{date}</div>
        </div>
      </div>
    </>
  );
};

export default ListItem;
