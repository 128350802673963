import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslate } from '../../resources/useTranslate';

const FormInaccessible = () => {
  const ns = 'construo.forms';
  const translations = {
    formInaccessibleHeading: useTranslate(`${ns}.formInaccessibleHeading`),
    formInaccessibleText: useTranslate(`${ns}.formInaccessibleText`),
    reload: useTranslate(`${ns}.reload`),
  };

  return (
    <>
      <h1>{translations.formInaccessibleHeading}</h1>
      <p>{translations.formInaccessibleText}</p>
      <Button primary onClick={() => window.location.reload()}>
        {translations.reload}
      </Button>
    </>
  );
};

export default FormInaccessible;
