import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axios from 'axios';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';

// miragejs version - getting documents from fake MirageJS server
// export const populateDocumentsData = createAsyncThunk(
//   'populateDocumentsData',
//   async () => {
//     const url = '/api/v1/user/documents';
//     const response = await axios.get(url);
//     const data = response.data;
//     return data;
//   }
// );

// Gateway API version - getting documents from Gateway API
export const populateDocumentsData = createAsyncThunk(
  'populateDocumentsData',
  async (dataObject: any) => {
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');

    const url = !!dataObject.recent
      ? dataObject.baseUrl + '/user/documents/?pageSize=' + dataObject.recent
      : dataObject.baseUrl + '/user/documents/';

    const response = await axios.get(url, config);
    const data = JSON.parse(response.data.results);
    return data;
  }
);

interface DocumentsDataState {
  pageNumber: number;
  filter: {
    logic: 'and' | 'or';
    filters: object[];
  };
  filterStatus: boolean;
  data: object[];
  error: string | null;
  docFetched: boolean | null;
  sort: SortDescriptor[];
  currentSortOption: number;
  previewProps: {
    docType: string;
    url: string;
    name: string;
    category: string;
    contacts: object[];
  };
  bulkDownloadItems: object[];
}

const initialState: DocumentsDataState = {
  pageNumber: 1,
  filter: {
    logic: 'and',
    filters: [],
  } as CompositeFilterDescriptor,
  filterStatus: false,
  data: [],
  error: null,
  docFetched: null,
  sort: [{ field: 'DocumentDate', dir: 'desc' }],
  currentSortOption: 1,
  previewProps: {
    docType: '',
    url: '',
    name: '',
    category: '',
    contacts: [],
  },
  bulkDownloadItems: [],
};

// const initialFilterValue = {
//   logic: 'and',
//   filters: [],
// } as CompositeFilterDescriptor;

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setStateFilters: (state, action) => {
      state.filter = action.payload;
    },
    setPageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },
    setFiltersStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setStateSort: (state, action) => {
      state.sort = action.payload;
    },
    setCurrentSortOption: (state, action) => {
      state.currentSortOption = action.payload;
    },
    setPreviewProps: (state, action) => {
      state.previewProps = action.payload;
    },
    setBulkDownloadItems: (state, action) => {
      state.bulkDownloadItems = action.payload;
    },
    resetDocuments: state => {
      state.data = [];
      state.docFetched = null;
    },
    resetFilters: state => {
      state.filter.filters = [];
    },
    clearDocumentsError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(populateDocumentsData.pending, (state, action) => {
        // console.log('DOCUMENTS LOADING');
        state.docFetched = false;
      })
      .addCase(populateDocumentsData.fulfilled, (state, action) => {
        // console.log('DOCUMENTS FULFILLED');
        state.data = action.payload;
        state.docFetched = true;
      })
      .addCase(populateDocumentsData.rejected, (state, action) => {
        state.docFetched = true;
        // console.log('DOCUMENTS REJECTED', action);
        state.error = !!action.error.message
          ? `Fetch Documents: ${action.error.message}`
          : null;
      });
  },
});

export const {
  setStateFilters,
  setPageNumber,
  setFiltersStatus,
  setStateSort,
  setCurrentSortOption,
  setPreviewProps,
  setBulkDownloadItems,
  resetDocuments,
  resetFilters,
  clearDocumentsError,
} = documentsSlice.actions;

export const selectData = (state: RootState) => state.documents;
export const selectDocumentsData = (state: RootState) => state.documents.data;
export const selectDocError = (state: RootState) => state.documents.error;
export const selectDocFetched = (state: RootState) =>
  state.documents.docFetched;
export const selectDocumentsFilter = (state: RootState) =>
  state.documents.filter;
export const selectFiltersStatus = (state: RootState) =>
  state.documents.filterStatus;
export const selectDocumentsSort = (state: RootState) => state.documents.sort;
export const selectDocumentsCurrentSortOption = (state: RootState) =>
  state.documents.currentSortOption;
export const selectPageNumber = (state: RootState) =>
  state.documents.pageNumber;
export const selectPreviewProps = (state: RootState) =>
  state.documents.previewProps;
export const selectBulkDownloadItems = (state: RootState) =>
  state.documents.bulkDownloadItems;

export default documentsSlice.reducer;
