import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ConfigChecker from './components/common/ConfigChecker';
import CustomizationProvider from './components/common/CustomizationProvider';
import ScrollToTop from './components/common/ScrollToTop';

// import { makeServer } from './app/server';

// if (process.env.NODE_ENV === 'development') {
//   makeServer({ environment: 'development' });
// }

const baseUrl =
  document.getElementsByTagName('base')[0].getAttribute('href') || undefined;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename={baseUrl}>
        <ScrollToTop />
        <ConfigChecker>
          <HelmetProvider>
            <CustomizationProvider>
              <App />
            </CustomizationProvider>
          </HelmetProvider>
        </ConfigChecker>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
