import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import envVars from '../../resources/envVars';
import SubmissionRequest from './SubmissionRequest';
import {
  fetchSubmissionRequests,
  selectSubmissions,
  selectSubmissionsFetched,
} from './submissionsSlice';

const SubmissionsList = () => {
  const dispatch = useDispatch();

  const submissions: object[] = useSelector(selectSubmissions);
  const submissionsFetched = useSelector(selectSubmissionsFetched);

  const API_BASE_URI = envVars.API_BASE_URI || '';

  useEffect(() => {
    if (submissionsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchSubmissionRequests(dataObject));
    }
  }, [dispatch, submissionsFetched, API_BASE_URI]);

  return (
    <>
      {submissions.map((submission: any) => {
        return (
          <SubmissionRequest
            key={submission.FormSubmissionGuid}
            submission={submission}
          />
        );
      })}
    </>
  );
};

export default SubmissionsList;
