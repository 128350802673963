import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUploads,
  fetchUploadRequests,
  selectUploadsFetched,
} from './uploadSlice';
import UploadRequest from './UploadRequest';
import envVars from '../../resources/envVars';

const UploadRequestList = () => {
  const uploads: object[] = useSelector(selectUploads);
  const uploadsFetched = useSelector(selectUploadsFetched);

  const dispatch = useDispatch();

  const API_BASE_URI = envVars.API_BASE_URI || '';

  useEffect(() => {
    if (uploadsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchUploadRequests(dataObject));
    }
  }, [dispatch, uploadsFetched, API_BASE_URI]);

  return (
    <>
      {uploads.map((upload: any) => {
        return <UploadRequest key={upload._id} upload={upload} />;
      })}
    </>
  );
};

export default UploadRequestList;
