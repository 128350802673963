import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';

// Gateway API version - getting specific Task in the Workflow by workflow id and task id
export const fetchTask = createAsyncThunk(
  'fetchTask',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const apiBaseUri = dataObject.baseUrl;
    const workflowId = dataObject.workflowId;
    const taskId = dataObject.taskId;
    const url = `${apiBaseUri}/user/workflows/${workflowId}/tasks/${taskId}`;
    const config = getAxiosConfig(token, 'json');
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  }
);

interface TaskDetailsState {
  data: any;
  taskDetailsFetched: boolean | null;
  taskDetailsError?: string;
}

const initialState: TaskDetailsState = {
  data: [],
  taskDetailsFetched: null,
};

export const taskDetailsSlice = createSlice({
  name: 'taskDetails',
  initialState,
  reducers: {
    resetTaskDetailsData: state => {
      state.data = [];
      state.taskDetailsFetched = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTask.pending, (state, action) => {
        // console.log('TASK DETAILS LOADING');
        state.taskDetailsFetched = false;
      })
      .addCase(fetchTask.fulfilled, (state, action) => {
        // console.log('TASK DETAILS FULFILLED');
        state.data = action.payload;
        state.taskDetailsFetched = true;
      })
      .addCase(fetchTask.rejected, (state, action) => {
        state.taskDetailsFetched = true;
        // console.log('TASK DETAILS REJECTED', action);
        state.taskDetailsError = action.error.message;
      });
  },
});

export const { resetTaskDetailsData } = taskDetailsSlice.actions;

export const selectTaskDetails = (state: RootState) => state.taskDetails.data;

export const selectTaskDetailsFetched = (state: RootState) =>
  state.taskDetails.taskDetailsFetched;

export const selectTaskDetailsError = (state: RootState) =>
  state.taskDetails.taskDetailsError;

export default taskDetailsSlice.reducer;
