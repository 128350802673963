import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import HelmetTitle from '../common/HelmetTitle';
import {
  updateSubmissionData,
  resetSubmissionData,
  selectSubmission,
  selectSubmissionJSON,
  selectSubmissionFetched,
  setSubmissionFetched,
  selectSubmissionError,
  fetchUserPrivateSubmission,
} from '../pages/submission-forms/submissionSlice';
import { Loading, setLoading } from '../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../app/slices/loadingMessageSlice';
import { selectUser } from '../api-authorization/userSlice';
import { Languages, selectLanguage } from '../common/languageSwitcherSlice';
import { resetAlerts, setAlerts } from '../../app/slices/alertSlice';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { useTranslate } from '../../resources/useTranslate';
import envVars from '../../resources/envVars';
import FormioForm from '../common/FormioForm';
import TempResponse from '../submissions/TempResponse';
import { setSubmissionsFetched } from '../submissions/submissionsSlice';
import FormSealed from '../common/FormSealed';
import { getAxiosConfig } from '../../helpers/utils';
import authService from '../api-authorization/AuthorizeService';

import './UserSubmissionForm.scss';

export interface FormRouteParams {
  guid: string;
}

interface FormioSubmissionProps {
  match: match<FormRouteParams>;
  isSubmitted: boolean;
}

const UserSubmissionForm = ({
  match,
  isSubmitted = false,
}: FormioSubmissionProps) => {
  const API_BASE_URI = envVars.API_BASE_URI;
  const GATEWAY_HUB_URL: string = envVars.GATEWAY_HUB_URL || '';

  const SUBMISSION_AUTO_LOCK_TIME = envVars.SUBMISSION_AUTO_LOCK_TIME;
  const submissionAutoLockTime: number = SUBMISSION_AUTO_LOCK_TIME || 300; // default "autolock" time is 300 seconds
  const SUBMISSION_AUTO_SAVE_TIME = envVars.SUBMISSION_AUTO_SAVE_TIME;
  const submissionAutoSaveTime: number = SUBMISSION_AUTO_SAVE_TIME || 60; // default "autosave" time is 60 seconds

  const autoLockTime = submissionAutoLockTime * 1000;
  const autoSaveTime = submissionAutoSaveTime * 1000;

  const dispatch = useDispatch();
  const navigate = useHistory();

  let languageId = useSelector(selectLanguage);

  const guid = match.params.guid;

  if (languageId === 'en-US') {
    languageId = Languages.NB;
  }

  const formSubmission: any = useSelector(selectSubmission);
  const controlGuid = formSubmission?.ControlGuid;

  const activeControlGuid = useRef(null);
  activeControlGuid.current = controlGuid;

  const submissionData = useSelector(selectSubmissionJSON);
  const submissionFetched = useSelector(selectSubmissionFetched);

  const [useTempResponse, setUseTempResponse] = useState<boolean | null>(null);

  const tempResponse = formSubmission.TempResponse?.Response;
  const isTempResponse = formSubmission.TempResponse !== null;

  const isFormSealed = formSubmission?.FormSubmissionStatus === 'Sealed';
  const form = !!submissionData && JSON.parse(submissionData);
  const submissionError = useSelector(selectSubmissionError);

  let customerResponse =
    !!formSubmission.CustomerResponse &&
    JSON.parse(formSubmission.CustomerResponse);
  customerResponse = !!customerResponse ? customerResponse : {};

  const submissionGuid = formSubmission.SubmissionGuid;
  const submissionTitle = formSubmission?.Title;

  const FORM_SUBMISSIONS_URI = API_BASE_URI + '/kyc/FormSubmissions/external';

  const [connection, setConnection] = useState<null | HubConnection>(null);
  const currentConnectionId: any = useRef(null);

  const [isFormSubmitted, setIsFormSubmitted] = useState(isSubmitted);
  const formSubmitted = useRef(false);

  const [isFormSaved, setIsFormSaved] = useState<boolean>(false);
  const formSaved = useRef(false);

  const signalrLogLevel =
    process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Debug;

  const userState = useSelector(selectUser);
  const isUserLogged = userState.authenticated;

  const ns = 'construo.forms';
  const translations = {
    loaderCreatingSigningRequest: useTranslate(
      `${ns}.loaderCreatingSigningRequest`
    ),
    loaderSubmittingForm: useTranslate(`${ns}.loaderSubmittingForm`),
    loaderGeneratingPdf: useTranslate(`${ns}.loaderGeneratingPdf`),
    alertErrorCreatingSigningRequest: useTranslate(
      `${ns}.alertErrorCreatingSigningRequest`
    ),
    formSubmittedHeading: useTranslate(`${ns}.formSubmittedHeading`),
    formSubmittedText: useTranslate(`${ns}.formSubmittedText`),
    submissionCouldNotBeLoaded: useTranslate(
      `${ns}.submissionCouldNotBeLoaded`
    ),
    otherUserWorkingOnSubmission: useTranslate(
      `${ns}.otherUserWorkingOnSubmission`
    ),
    submissionSavedTitle: useTranslate(`${ns}.submissionSavedTitle`),
    submissionSavedText: useTranslate(`${ns}.submissionSavedText`),
    saveForLater: useTranslate(`${ns}.saveForLater`),
    saveForLaterNote: useTranslate(`${ns}.saveForLaterNote`),
    returnHome: useTranslate(`${ns}.returnHome`),
    formLockedByUser: useTranslate(`${ns}.formLockedByUser`),
    formSaved: useTranslate(`${ns}.formSaved`),
    formNotSaved: useTranslate(`${ns}.formNotSaved`),
    formSubmitted: useTranslate(`${ns}.formSubmitted`),
    formSubmissionNot: useTranslate(`${ns}.formSubmissionNot`),
    locked: useTranslate(`${ns}.locked`),
    unlocked: useTranslate(`${ns}.unlocked`),
    submitError: useTranslate(`${ns}.submitError`),
    autoSaveError: useTranslate(`${ns}.autoSaveError`),
  };

  const lockSubmissionMemo = useCallback(
    async status => {
      const url =
        API_BASE_URI +
        '/kyc/FormSubmissions/external/' +
        guid +
        '/locking?lockForm=' +
        status +
        '&controlGuid=' +
        activeControlGuid.current;
      const accessToken = await authService.getAccessToken();
      const config = getAxiosConfig(accessToken, 'json');
      await axios
        .patch(url, null, config)
        .then(response => response.data)
        .then((response: any) => {
          // TODO: Show user that form submission is locked/unlocked, message is shown for limited period of time, for example 5 seconds
        })
        .catch((error: any) => {
          dispatch(
            setAlerts({
              type: 'error',
              message: `${translations.formSubmissionNot} ${
                status ? translations.locked : translations.unlocked
              }. ${error.message}`,
            })
          );
        });
    },
    [
      dispatch,
      API_BASE_URI,
      guid,
      translations.formSubmissionNot,
      translations.locked,
      translations.unlocked,
    ]
  );

  useEffect(() => {
    return () => {
      if (!isLockedByOtherUser.current) {
        const isUnlockRequired =
          !formSubmitted.current && !formSaved.current && form;
        if (isUnlockRequired) {
          lockSubmissionMemo(false);
        }
        alreadyLocked.current = false;
        isAutoSaveActive.current = true;
        clearInterval(autoSaveResponseInterval.current);
        clearInterval(autoLockInterval.current);
      }
      dispatch(resetAlerts());
      dispatch(setSubmissionFetched(null));
      dispatch(resetSubmissionData());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (submissionFetched === null) {
      const url =
        API_BASE_URI +
        '/kyc/FormSubmissions/external/' +
        guid +
        '?includeTempResponse=true';
      const dataObject = {
        url,
      };
      dispatch(setLoading(Loading.Show));
      dispatch(fetchUserPrivateSubmission(dataObject));
    } else if (submissionFetched) {
      dispatch(setLoading(Loading.Hide));
    }
  }, [
    dispatch,
    API_BASE_URI,
    FORM_SUBMISSIONS_URI,
    guid,
    isUserLogged,
    submissionFetched,
  ]);

  // In case of submission fetching error you're redirected to "No Resource Page"
  const [showError, setShowError] = useState<boolean>(false);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setShowError(true);
    }
  }, []);
  useEffect(() => {
    if (submissionFetched && !form && submissionError) {
      navigate.push(
        `/no-resource?type=submission&guid=${guid}${
          showError ? `&error=${submissionError}` : ``
        }`
      );
    }
  }, [navigate, submissionFetched, form, submissionError, guid, showError]);

  useEffect(() => {
    if (formSubmission.DirectSign) {
      // OPEN SignalR Connection
      if (!connection) {
        // SET Connection
        const connect = new HubConnectionBuilder()
          .withUrl(GATEWAY_HUB_URL)
          .configureLogging(signalrLogLevel)
          .withAutomaticReconnect()
          .build();
        setConnection(connect);
      }
      // START (if not started) SignalR Connection and LISTEN for EVENTS ('Notify' and 'RedirectToSign')
      if (!!connection && !currentConnectionId.current) {
        connection
          .start()
          .then(() => {
            currentConnectionId.current = connection.connectionId;
            connection.on('RedirectToSign', redirectURL => {
              window.location.href = redirectURL;
            });
            connection.on('Notify', message => {
              // Show messages (translations) depending of message.status, status values can be 1 or 0
              if (message.status === 1) {
                dispatch(
                  setLoadingMessage(translations.loaderCreatingSigningRequest)
                );
              } else {
                dispatch(setLoading(Loading.Hide));
                dispatch(setLoadingMessage(null));
                dispatch(
                  setAlerts({
                    message: translations.alertErrorCreatingSigningRequest,
                    type: 'error',
                  })
                );
              }
            });
          })
          .catch(error => {
            dispatch(setAlerts({ message: error.message, type: 'error' }));
          });
      }
    }
  }, [
    dispatch,
    formSubmission,
    connection,
    GATEWAY_HUB_URL,
    signalrLogLevel,
    translations.loaderCreatingSigningRequest,
    translations.alertErrorCreatingSigningRequest,
  ]);

  const onSubmit = async (submission: any) => {
    delete submission.metadata;
    delete submission.state;

    dispatch(updateSubmissionData(JSON.stringify(submission)));
    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.loaderSubmittingForm));

    const accessToken = await authService.getAccessToken();
    const config = getAxiosConfig(accessToken, 'json');
    const data = {
      SubmissionGuid: submissionGuid,
      CustomerResponse: JSON.stringify(submission),
      FormSubmissionStatus: 'Submitted by client',
      ControlGuid: activeControlGuid.current,
      CurrentConnectionId: !!currentConnectionId.current
        ? currentConnectionId.current
        : null,
    };
    const dataStringify = JSON.stringify(data);
    const url = FORM_SUBMISSIONS_URI;

    await axios
      .put(url, dataStringify, config)
      .then(response => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // When isFormSubmitted prop is set to true we show success message
        setIsFormSubmitted(true);
        formSubmitted.current = true;

        dispatch(
          setAlerts({
            message: translations.formSubmitted,
            type: 'success',
          })
        );
        /**
         * Clear submission(S) Fetched prop so that list on landing page is loaded again,
         * it needs to be refreshed since one of the submissions is successfully submitted
         */
        dispatch(setSubmissionsFetched(null));
        // Stop form locking and form auto save
        clearInterval(autoSaveResponseInterval.current);
        clearInterval(autoLockInterval.current);
        autoLockInterval.current = null;
        window.scrollTo(0, 0);
        if (formSubmission.DirectSign) {
          dispatch(setLoading(Loading.Show));
          dispatch(setLoadingMessage(translations.loaderGeneratingPdf));
        }
      })
      .catch(error => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message after submission fails
        dispatch(
          setAlerts({
            message: `${translations.submitError} ${error.message}`,
            type: 'error',
          })
        );
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  const isAutoSaveActive: any = useRef(null);
  const autoSaveResponseInterval: any = useRef(null);
  const customerResponsePrevious: any = useRef(null);
  const customerResponseNext: any = useRef(null);

  const onChange = (submission: any, schema: any, event: any) => {
    if (!!submission.changed && !!event) {
      customerResponseNext.current = { data: submission.data };

      if (!isAutoSaveActive.current) {
        isAutoSaveActive.current = true;

        autoSaveResponseInterval.current = setInterval(async () => {
          const responseChanged =
            customerResponsePrevious.current !== customerResponseNext.current;

          if (responseChanged) {
            customerResponsePrevious.current = customerResponseNext.current;
            const tempResponseBodyData = {
              FormSubmissionGuid: formSubmission.SubmissionGuid,
              Response: JSON.stringify(customerResponseNext.current),
              ControlGuid: formSubmission.ControlGuid,
            };

            // Auto Save - Temp Response
            const accessToken = await authService.getAccessToken();
            const url = API_BASE_URI + '/kyc/FormSubmissions/tempresponse';
            const bodyData = tempResponseBodyData;
            const config = getAxiosConfig(accessToken, 'json');
            await axios
              .post(url, bodyData, config)
              .then(response => response.data)
              .then((response: any) => {
                // TODO: Show user that form submission is auto saved, message is shown for limited period of time, for example 5 seconds
              })
              .catch((error: any) => {
                dispatch(
                  setAlerts({
                    type: 'error',
                    message: `${translations.autoSaveError} ${error.message}`,
                  })
                );
              })
              .finally(() => {
                //
              });
          }
        }, autoSaveTime);
      }
    }

    // File Upload component in FormIO - remove download link for the file, add pointer cursor for "X" remove button
    const fileLink = document.querySelector(
      '.formio-component-upload a[ref=fileLink]'
    );
    fileLink?.setAttribute('style', 'pointer-events: none;');
    const removeLink = document.querySelector(
      '.formio-component-upload i[ref=removeLink]'
    );
    removeLink?.setAttribute('style', 'cursor: pointer;');
    if (
      !!submission.changed &&
      submission.changed.component.type === 'file' &&
      submission.changed.value.length &&
      submission.isValid
    ) {
      fileLink?.removeAttribute('href');
    }
  };

  const isLockedByOtherUser = useRef<boolean | null>(null);
  const lockedAlert = useRef<boolean>(false);
  const alreadyLocked = useRef<boolean>(false);
  const autoLockInterval: any = useRef(null);

  useEffect(() => {
    if (submissionFetched && !!form) {
      // Set response to chosen user response when there are auto-save and user-save versions
      customerResponseNext.current = !!useTempResponse
        ? JSON.parse(tempResponse)
        : customerResponse;

      const isLocked = !!formSubmission.DateLocked;
      if (isLocked) {
        const isLockedByOthers = formSubmission.LockedBy !== userState.userSub;
        isLockedByOtherUser.current = isLockedByOthers;
        if (isLockedByOthers && !lockedAlert.current) {
          lockedAlert.current = true;
          dispatch(
            setAlerts({
              message: translations.formLockedByUser,
              type: 'info',
            })
          );
        }
      }
      if (!alreadyLocked.current && !isLockedByOtherUser.current) {
        dispatch(resetAlerts());
        lockSubmissionMemo(true);
        alreadyLocked.current = true;
        autoLockInterval.current = setInterval(() => {
          lockSubmissionMemo(true);
        }, autoLockTime);
      }
    }
  }, [
    dispatch,
    submissionFetched,
    form,
    useTempResponse,
    tempResponse,
    customerResponse,
    formSubmission.DateLocked,
    formSubmission.LockedBy,
    userState.userSub,
    isTempResponse,
    autoLockTime,
    lockSubmissionMemo,
    translations.formLockedByUser,
  ]);

  const onSaveForLater = async (e: any) => {
    const accessToken = await authService.getAccessToken();
    const url = API_BASE_URI + '/kyc/FormSubmissions/external';
    const bodyData = {
      CustomerResponse: JSON.stringify(customerResponseNext.current),
      SubmissionGuid: submissionGuid,
      FormSubmissionStatus: 'Saved by client',
      ControlGuid: activeControlGuid.current,
    };
    const config = getAxiosConfig(accessToken, 'json');
    await axios
      .put(url, bodyData, config)
      .then(response => response.data)
      .then((response: any) => {
        dispatch(
          setAlerts({
            type: 'success',
            message: translations.formSaved,
          })
        );
        setIsFormSaved(true);
        formSaved.current = true;
        // Stop auto lock and auto save
        clearInterval(autoSaveResponseInterval.current);
        clearInterval(autoLockInterval.current);
        // Unlock form when successfully saved for later
        lockSubmissionMemo(false);
      })
      .catch((error: any) => {
        dispatch(
          setAlerts({
            type: 'error',
            message: `${translations.formNotSaved} ${error.message}`,
          })
        );
      });
  };

  const chooseTemp = () => {
    setUseTempResponse(true);
  };

  const chooseLatest = () => {
    setUseTempResponse(false);
  };

  const currentSubmission = !!useTempResponse
    ? JSON.parse(tempResponse)
    : customerResponse;

  return (
    <>
      <HelmetTitle title={submissionTitle} />

      <section className='main-section'>
        {(useTempResponse !== null || !isTempResponse) &&
          !!form &&
          submissionFetched &&
          !isLockedByOtherUser.current &&
          !isFormSaved &&
          !isFormSubmitted &&
          !isFormSealed && (
            <div className='support-btns sticky'>
              <div className='container'>
                <div className='note'>{translations.saveForLaterNote}</div>
                <button
                  className='btn btn-secondary btn-sm'
                  onClick={onSaveForLater}
                >
                  {translations.saveForLater}
                </button>
              </div>
            </div>
          )}

        <div className='container'>
          {isFormSealed && <FormSealed />}

          {isLockedByOtherUser.current && (
            <>
              <h1 style={{ maxWidth: '800px' }}>
                {translations.otherUserWorkingOnSubmission}
              </h1>
              <p>
                <NavLink to='/'>{translations.returnHome}</NavLink>
              </p>
            </>
          )}

          {isFormSaved && (
            <div className='submission-saved'>
              <h1>{translations.submissionSavedTitle}</h1>
              <p>{translations.submissionSavedText}</p>
              <p>
                <NavLink to='/'>{translations.returnHome}</NavLink>
              </p>
            </div>
          )}

          {submissionFetched &&
            !isFormSaved &&
            (isFormSubmitted ? (
              <>
                <h1>{translations.formSubmittedHeading}</h1>
                <p>{translations.formSubmittedText}</p>
                <p>
                  <NavLink to='/'>{translations.returnHome}</NavLink>
                </p>
              </>
            ) : useTempResponse === null &&
              isTempResponse &&
              !!form &&
              submissionFetched &&
              !isLockedByOtherUser.current &&
              !isFormSealed ? (
              <TempResponse
                chooseTemp={chooseTemp}
                chooseLatest={chooseLatest}
              />
            ) : (
              !!form &&
              submissionFetched &&
              !isLockedByOtherUser.current &&
              !isFormSealed && (
                <>
                  <div lang={formSubmission?.Language?.Code}>
                    <h1>
                      {!!formSubmission.Title
                        ? formSubmission.Title
                        : submissionTitle}
                    </h1>

                    {!!formSubmission.Description && (
                      <p>{formSubmission.Description}</p>
                    )}

                    {!!formSubmission.Message && (
                      <p>{formSubmission.Message}</p>
                    )}

                    <FormioForm
                      form={form}
                      submission={currentSubmission}
                      onSubmit={onSubmit}
                      onChange={(submission: any, schema: any, event: any) =>
                        onChange(submission, schema, event)
                      }
                      languageCode={
                        !!formSubmission?.Language?.Code
                          ? formSubmission?.Language?.Code
                          : 'en'
                      }
                    />
                  </div>
                </>
              )
            ))}
        </div>
      </section>
    </>
  );
};

export default UserSubmissionForm;
