import React from 'react';
import { NavLink } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';
import HelmetTitle from '../common/HelmetTitle';
import { useTranslate } from '../../resources/useTranslate';

const ReportPage = () => {
  const ns = 'construo.homepage';
  const translations = {
    signingCompleted: useTranslate(`${ns}.signingCompleted`),
    signingCompletedText: useTranslate(`${ns}.signingCompletedText`),
    signingUncompleted: useTranslate(`${ns}.signingUncompleted`),
    signingUncompletedText: useTranslate(`${ns}.signingUncompletedText`),
    userLogoutMessage: useTranslate(`${ns}.userLogoutMessage`),
    login: useTranslate(`${ns}.login`),
  };

  const loginPath = ApplicationPaths.Login;

  const urlString = window.location.href;
  const url = new URL(urlString);
  const esignStatus = url.searchParams.get('esign');

  return (
    <>
      {!!esignStatus && (
        <>
          <HelmetTitle
            title={
              esignStatus === 'success'
                ? translations.signingCompleted
                : translations.signingUncompleted
            }
          />
          <section className='main-section'>
            <div className='container'>
              {esignStatus === 'success' && (
                <div className='alert alert-success alert-signing' role='alert'>
                  <div className='icon'>
                    <i
                      className='fas fa-check-circle'
                      style={{ fontSize: '24px' }}
                    />
                  </div>
                  <div className='text'>
                    <h5>{translations.signingCompleted}</h5>
                    <p>{translations.signingCompletedText}</p>
                  </div>
                </div>
              )}
              {esignStatus === 'failed' && (
                <div className='alert alert-danger alert-signing' role='alert'>
                  <div className='icon'>
                    <i
                      className='fas fa-exclamation-circle'
                      style={{ fontSize: '24px' }}
                    />
                  </div>
                  <div className='text'>
                    <h5>{translations.signingUncompleted}</h5>
                    <p>{translations.signingUncompletedText}</p>
                  </div>
                </div>
              )}
            </div>
          </section>
        </>
      )}

      <div className='main-section'>
        <div className='container'>
          <h1>{translations.userLogoutMessage}</h1>
          <NavLink className='k-button k-primary' to={loginPath}>
            {translations.login}
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default ReportPage;
