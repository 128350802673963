import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axios from 'axios';

// Gateway API version - getting public form template from Gateway API
export const fetchPublicForm = createAsyncThunk(
  'fetchPublicForm',
  async (fetchData: any, thunkAPI) => {
    const url = fetchData.API_BASE_URI + '/kyc/formTemplate/' + fetchData.guid;
    const response = await axios.get(url);
    const data = response.data;
    return data;
  }
);

interface FormTemplateState {
  data: any;
  formFetched: boolean | null;
  formError?: string;
}

const initialState: FormTemplateState = {
  data: [],
  formFetched: null,
};

export const publicFormSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormFetched: (state, action) => {
      state.formFetched = action.payload;
    },
    updateFormData: (state, action) => {
      state.data.CustomerResponse = action.payload;
    },
    resetFormData: state => {
      state.data = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPublicForm.pending, (state, action) => {
        // console.log('FORM LOADING');
        state.formFetched = false;
      })
      .addCase(fetchPublicForm.fulfilled, (state, action) => {
        // console.log('FORM FULFILLED');
        state.data = action.payload;
        state.formFetched = true;
      })
      .addCase(fetchPublicForm.rejected, (state, action) => {
        state.formFetched = true;
        // console.log('FORM REJECTED', action);
        state.formError = action.error.message;
      });
  },
});

export const {
  setFormFetched,
  updateFormData,
  resetFormData,
} = publicFormSlice.actions;

export const selectTemplateData = (state: RootState) => state.publicForm.data;

export const selectPublicFormData = (state: RootState) =>
  state.publicForm.data.FormTemplateJsonValue;

export const selectFormFetched = (state: RootState) =>
  state.publicForm.formFetched;

export const selectFormError = (state: RootState) => state.publicForm.formError;

export default publicFormSlice.reducer;
