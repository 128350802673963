import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import envVars from '../../resources/envVars';
import { useTranslate } from '../../resources/useTranslate';
import { selectUser, UserState } from '../api-authorization/userSlice';
import {
  fetchUnreadConversations,
  selectUnreadConversations,
  selectUnreadConversationsFetched,
} from '../pages/conversations/conversationsSlice';
import GlobalMenu, { GlobalMenuLabelTypes } from './GlobalMenu';
import { NavigationPropTypes } from './Navigation';

export interface GlobalMenuDataProviderProps {
  onHideMenu: () => void;
  userState?: UserState;
  labels?: GlobalMenuLabelTypes;
  navigationProps?: NavigationPropTypes;
  unreadConversationsFetched?: boolean;
}

const GlobalMenuDataProvider = ({
  onHideMenu,
  userState,
  labels,
  navigationProps,
  unreadConversationsFetched: unreadConversationsFetchedProp,
}: GlobalMenuDataProviderProps) => {
  const isMultiLanguage = !!envVars.LANGUAGES
    ? envVars.LANGUAGES?.length > 1
    : false;

  const dispatch = useDispatch();

  const ns = 'construo.menu';
  const translations = {
    home: useTranslate(`${ns}.home`),
    documents: useTranslate(`${ns}.documents`),
    messages: useTranslate(`${ns}.messages`),
    login: useTranslate(`${ns}.login`),
    logout: useTranslate(`${ns}.logout`),
    languageSwitcherLabel: useTranslate(`${ns}.languageSwitcherLabel`),
    english: useTranslate(`${ns}.english`),
    norwegian: useTranslate(`${ns}.norwegian`),
    finnish: useTranslate(`${ns}.finnish`),
  };

  const isDocumentListFeature = envVars.FEATURES?.includes('documentList');
  const isConversationsFeature = envVars.FEATURES?.includes('conversations');

  const userStateSelect = useSelector(selectUser);

  userState = userState === undefined ? userStateSelect : userState;

  const isUserLogged = userState.authenticated;
  let unreadConversations = useSelector(selectUnreadConversations);

  const unreadConversationsFetchedSelect = useSelector(
    selectUnreadConversationsFetched
  );

  const unreadConversationsFetched =
    unreadConversationsFetchedProp === undefined
      ? unreadConversationsFetchedSelect
      : unreadConversationsFetchedProp;

  unreadConversations =
    (!!unreadConversations && unreadConversations) < 100
      ? unreadConversations
      : 99;

  const baseUrl = envVars.API_BASE_URI;

  useEffect(() => {
    if (
      unreadConversationsFetched === null &&
      isUserLogged &&
      isConversationsFeature
    ) {
      const url = `${baseUrl}/user/conversations/count`;
      const dataObject = {
        url,
      };
      dispatch(fetchUnreadConversations(dataObject));
    }
  }, [
    dispatch,
    isUserLogged,
    isConversationsFeature,
    unreadConversationsFetched,
    baseUrl,
  ]);

  const hasUnreadConversations = unreadConversations > 0;

  navigationProps =
    navigationProps === undefined
      ? {
          isUserLogged,
          isDocumentListFeature,
          isConversationsFeature,
          hasUnreadConversations,
          unreadConversations,
        }
      : navigationProps;

  labels = labels === undefined ? translations : labels;

  return (
    <GlobalMenu
      isMultiLanguageProp={isMultiLanguage}
      userProps={userState}
      labels={labels}
      navigationProps={navigationProps}
      onHideMenu={onHideMenu}
    />
  );
};

export default GlobalMenuDataProvider;
