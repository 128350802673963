import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../resources/useTranslate';
import envVars from '../../resources/envVars';
import { selectUser } from '../api-authorization/userSlice';
import { selectUnreadConversations } from '../pages/conversations/conversationsSlice';
import Logo from './Logo';

import './Header.scss';

export interface HeaderProps {
  onShowMenu: () => void;
  onHideMenu: () => void;
  menuBtnLabel?: string;
  showUnreadConversations?: boolean;
}

const Header = ({
  onShowMenu,
  onHideMenu,
  menuBtnLabel,
  showUnreadConversations: showUnreadConversationsProp,
}: HeaderProps) => {
  const ns = 'construo.header';
  const translations = {
    menuBtnLabel: useTranslate(`${ns}.menuBtnLabel`),
  };
  menuBtnLabel = menuBtnLabel || translations.menuBtnLabel;

  const userState = useSelector(selectUser);
  const isUserLogged = userState.authenticated;
  const isConversationsFeature = envVars.FEATURES?.includes('conversations');
  const unreadConversations = useSelector(selectUnreadConversations);
  const hasUnreadConversations = unreadConversations > 0;
  const showUnreadConversations =
    !!isUserLogged && isConversationsFeature && hasUnreadConversations;
  showUnreadConversationsProp =
    showUnreadConversationsProp === undefined
      ? showUnreadConversations
      : showUnreadConversationsProp;

  return (
    <header className='global-header'>
      <div className='container'>
        <NavLink className='logo' to='/' onClick={onHideMenu}>
          <Logo />
        </NavLink>
        <button
          type='button'
          className='btn btn-link btn-menu'
          onClick={onShowMenu}
        >
          {menuBtnLabel}
          <i
            className={`fal fa-bars ${
              showUnreadConversationsProp ? 'notification-badge' : ''
            }`}
          />
        </button>
      </div>
    </header>
  );
};

export default Header;
