import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';
import { getAxiosConfig } from '../../helpers/utils';
import authService from '../api-authorization/AuthorizeService';

// Gateway API version - getting external user Form Submission Requests from Gateway API
export const fetchSubmissionRequests = createAsyncThunk(
  'fetchSubmissionRequests',
  async (dataObject: any) => {
    const token = await authService.getAccessToken();
    const baseUrl = dataObject.baseUrl;
    const url = baseUrl + '/kyc/FormSubmissions/external';
    const config = getAxiosConfig(token, 'json');
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  }
);

interface SubmissionsRequestState {
  data: any;
  error: string | null;
  submissionsFetched: boolean | null;
}

const initialState: SubmissionsRequestState = {
  data: [],
  error: null,
  submissionsFetched: null,
};

export const submissionsSlice = createSlice({
  name: 'submissions',
  initialState,
  reducers: {
    setSubmissionsFetched: (state, action) => {
      state.submissionsFetched = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSubmissionRequests.pending, (state, action) => {
        // console.log('SUBMISSIONS LOADING');
        state.submissionsFetched = false;
      })
      .addCase(fetchSubmissionRequests.fulfilled, (state, action) => {
        // console.log('SUBMISSIONS FULFILLED');
        state.data = action.payload.Data;
        state.submissionsFetched = true;
      })
      .addCase(fetchSubmissionRequests.rejected, (state, action) => {
        // console.log('SUBMISSIONS REJECTED', action);
        state.submissionsFetched = true;
        state.error = !!action.error.message
          ? `Fetch Submission Requests: ${action.error.message}`
          : null;
      });
  },
});

export const { setSubmissionsFetched } = submissionsSlice.actions;

export const selectSubmissions = (state: RootState) => state.submissions.data;
export const selectSubmissionsError = (state: RootState) =>
  state.submissions.error;
export const selectSubmissionsFetched = (state: RootState) =>
  state.submissions.submissionsFetched;

export default submissionsSlice.reducer;
