import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import authService, { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames } from './ApiAuthorizationConstants';
import { navigateToReturnUrl } from '../../helpers/utils';
import { showMessage, setUser, selectUser } from './userSlice';
import OverallLoader from '../common/OverallLoader';
import { useTranslate } from '../../resources/useTranslate';

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.

interface Props {
  action: string;
}
export const Login = ({ action }: Props) => {
  const dispatch = useDispatch();
  const userState = useSelector(selectUser);

  // Get redirectToSubmissionURL from session storage
  let redirectToSubmissionURL = sessionStorage.getItem(
    'redirectToSubmissionURL'
  );
  redirectToSubmissionURL =
    !!redirectToSubmissionURL && JSON.parse(redirectToSubmissionURL);

  const notFirstAttemptRef: any = useRef(null);

  const ns = 'construo.errors';
  const translations = {
    invalidStatus: useTranslate(`${ns}.invalidStatus`),
    shouldNotRedirect: useTranslate(`${ns}.shouldNotRedirect`),
    invalidAuth: useTranslate(`${ns}.invalidAuth`),
    invalidAction: useTranslate(`${ns}.invalidAction`),
    invalidReturnUrl: useTranslate(`${ns}.invalidReturnUrl`),
    processingLogin: useTranslate(`construo.global.processingLogin`),
    processingLoginCallback: useTranslate(
      `construo.global.processingLoginCallback`
    ),
  };

  useEffect(() => {
    const login = async (returnUrl: string): Promise<void> => {
      const result = await authService.signIn(returnUrl);
      switch (result?.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          const name = `${result?.user?.profile?.given_name} ${result?.user?.profile?.family_name}`;
          dispatch(setUser({ username: name }));
          navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          dispatch(showMessage(result?.message));
          break;
        default:
          throw new Error(`${translations.invalidStatus} ${result?.status}.`);
      }
    };

    const getReturnUrl = (state?: any) => {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(QueryParameterNames.ReturnUrl);
      if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error(translations.invalidReturnUrl);
      }
      var returnUrl = state || fromQuery || `${window.location.origin}/`;
      return returnUrl;
    };

    const processLoginCallback = async (): Promise<void> => {
      const url = window.location.href;
      const result = await authService.completeSignIn(url);
      switch (result?.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeSignIn finishes
          // is when we are doing a redirect sign in flow.
          throw new Error(translations.shouldNotRedirect);
        case AuthenticationResultStatus.Success:
          navigateToReturnUrl(getReturnUrl(result?.state));
          const name = `${result?.user?.profile?.given_name} ${result?.user?.profile?.family_name}`;
          dispatch(setUser({ username: name }));
          // Redirect if necessary for "BankId" Form Submission
          if (!!redirectToSubmissionURL && !notFirstAttemptRef.current) {
            notFirstAttemptRef.current = true;
            navigateToReturnUrl(redirectToSubmissionURL);
          }
          break;
        case AuthenticationResultStatus.Fail:
          dispatch(showMessage(result?.message));
          break;
        default:
          throw new Error(`${translations.invalidAuth} '${result?.status}'.`);
      }
    };

    switch (action) {
      case LoginActions.Login:
        login(getReturnUrl());
        break;
      case LoginActions.LoginCallback:
        processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        const params = new URLSearchParams(window.location.search);
        const error = params.get(QueryParameterNames.Message);
        dispatch(showMessage(error));
        break;
      default:
        throw new Error(`${translations.invalidAction} '${action}'`);
    }
  }, [
    action,
    dispatch,
    redirectToSubmissionURL,
    translations.invalidAuth,
    translations.invalidAction,
    translations.invalidStatus,
    translations.invalidReturnUrl,
    translations.shouldNotRedirect,
  ]);

  if (!!userState.message) {
    return (
      <>
        <div className='main-section'>
          <div className='container'>
            <h1>{userState.message}</h1>
          </div>
        </div>
      </>
    );
  } else {
    switch (action) {
      case LoginActions.Login:
        return <OverallLoader message={translations.processingLogin} />;
      case LoginActions.LoginCallback:
        return <OverallLoader message={translations.processingLoginCallback} />;
      default:
        throw new Error(`${translations.invalidAction} '${action}'`);
    }
  }
};
