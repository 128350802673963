import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const DetailsSkeleton = () => {
  return (
    <section className='main-section task-details'>
      <div className='container'>
        <Skeleton shape={'text'} style={{ maxWidth: 200 }} />
        <h1>
          <Skeleton shape={'text'} style={{ maxWidth: 300 }} />
        </h1>
        <div className='row'>
          <div className='col-lg-8 col-xl-9'>
            <p>
              <Skeleton shape={'text'} style={{ maxWidth: 400 }} />
              <Skeleton shape={'text'} style={{ maxWidth: 450 }} />
              <Skeleton shape={'text'} style={{ maxWidth: 350 }} />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DetailsSkeleton;
