import React from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setVisibilityMenu,
  VisibilityMenu,
} from '../../app/slices/visibilityMenuSlice';
import { GlobalMenuLabelTypes } from './GlobalMenu';

export type NavigationPropTypes = {
  isUserLogged: boolean | null;
  isDocumentListFeature: boolean | undefined;
  isConversationsFeature: boolean | undefined;
  hasUnreadConversations: boolean;
  unreadConversations: number;
};
export interface NavigationProps {
  labels: GlobalMenuLabelTypes;
  navigationProps: NavigationPropTypes;
}

const Navigation = ({ labels, navigationProps }: NavigationProps) => {
  const {
    isUserLogged,
    isDocumentListFeature,
    isConversationsFeature,
    hasUnreadConversations,
    unreadConversations,
  } = navigationProps;

  const dispatch = useDispatch();

  return (
    <>
      {isUserLogged && (
        <>
          <nav className='global-navigation'>
            <ul>
              <li>
                <NavLink
                  exact={true}
                  to='/'
                  onClick={() => {
                    dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                  }}
                >
                  <span>{labels.home}</span>
                </NavLink>
              </li>
              {isDocumentListFeature && (
                <li>
                  <NavLink
                    to='/documents'
                    onClick={() => {
                      dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                    }}
                  >
                    <span>{labels.documents}</span>
                  </NavLink>
                </li>
              )}
              {isConversationsFeature && (
                <li>
                  <NavLink
                    to='/conversations'
                    onClick={() => {
                      dispatch(setVisibilityMenu(VisibilityMenu.Hide));
                    }}
                  >
                    <span>{labels.messages}</span>
                    {hasUnreadConversations && (
                      <span className='badge'>{unreadConversations}</span>
                    )}
                  </NavLink>
                </li>
              )}
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default Navigation;
