import React, { useEffect, useState } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import {
  setMessagesLoaded,
  setStylesLoaded,
} from '../../app/slices/settingsSlice';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import envVars from '../../resources/envVars';

export interface CustomizationProviderProps {
  children: React.ReactNode;
}

const CustomizationProvider = ({ children }: CustomizationProviderProps) => {
  const customFolderName = envVars.CUSTOM_FOLDER_NAME;
  const customerName = envVars.CUSTOMER_NAME ? envVars.CUSTOMER_NAME : '';
  const dispatch = useDispatch();
  const [enMessagesLoaded, setEnMessagesLoaded] = useState(false);
  const [nbMessagesLoaded, setNbMessagesLoaded] = useState(false);
  const [fiMessagesLoaded, setFiMessagesLoaded] = useState(false);
  const [favicon, setFavicon] = useState();
  const [appleTouchIcon, setAppleTouchIcon] = useState();
  const [touchIcon, setTouchIcon] = useState();

  useEffect(() => {
    if (customFolderName) {
      // Loading custom messages for English language
      import(`../../custom/${customFolderName}/messages/custom.en-US`).then(
        msg => {
          loadMessages(msg.enCustomMessages, 'en-US');
          setEnMessagesLoaded(true);
        }
      );
      // Loading custom messages for Norwegian language
      import(`../../custom/${customFolderName}/messages/custom.nb`).then(
        msg => {
          loadMessages(msg.nbCustomMessages, 'nb');
          setNbMessagesLoaded(true);
        }
      );
      // Loading custom messages for Finnish language
      import(`../../custom/${customFolderName}/messages/custom.fi`).then(
        msg => {
          loadMessages(msg.fiCustomMessages, 'fi');
          setFiMessagesLoaded(true);
        }
      );
      // Loading custom styles
      import(`../../custom/${customFolderName}/assets/scss/global.scss`).then(
        () => {
          dispatch(setStylesLoaded());
        }
      );
      // Loading custom favicons
      import(`../../custom/${customFolderName}/assets/media/favicon.ico`).then(
        image => {
          setFavicon(image.default);
        }
      );
      import(
        `../../custom/${customFolderName}/assets/media/apple-touch-icon.png`
      ).then(image => {
        setAppleTouchIcon(image.default);
      });
      import(
        `../../custom/${customFolderName}/assets/media/touch-icon.png`
      ).then(image => {
        setTouchIcon(image.default);
      });
    }
  }, [dispatch, customFolderName]);

  useEffect(() => {
    if (enMessagesLoaded && nbMessagesLoaded && fiMessagesLoaded) {
      dispatch(setMessagesLoaded());
    }
  }, [dispatch, enMessagesLoaded, nbMessagesLoaded, fiMessagesLoaded]);

  return (
    <>
      <Helmet
        defaultTitle={customerName}
        titleTemplate={`%s | ${customerName}`}
      >
        <link rel='shortcut icon' href={favicon} />
        <link rel='apple-touch-icon' href={appleTouchIcon} />
        <link rel='icon' href={touchIcon} />
      </Helmet>

      {children}
    </>
  );
};

export default CustomizationProvider;
