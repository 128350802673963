import React from 'react';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { NavLink } from 'react-router-dom';
import { useTranslate } from '../../../resources/useTranslate';

interface DetailsProps {
  workflowId: string | null;
  workflowName: string | false;
  taskTitle: string | null;
  taskDescription: string | null;
  taskContacts: [];
  taskId: string;
}

const Details = ({
  workflowId,
  workflowName,
  taskTitle,
  taskDescription,
  taskContacts,
  taskId,
}: DetailsProps) => {
  const ns = 'construo.workflows';
  const translations = {
    accessedBy: useTranslate(`${ns}.accessedBy`),
    noWorkflowName: useTranslate(`${ns}.noWorkflowName`),
  };

  return (
    <>
      <section className='main-section task-details'>
        <div className='container'>
          <NavLink to={`/user/workflows/${workflowId}`}>
            {!!workflowName ? workflowName : translations.noWorkflowName}
          </NavLink>
          :<h1>{taskTitle}</h1>
          <div className='row'>
            <div className='col-lg-8 col-xl-9'>
              {!!taskDescription && (
                /**
                 * dangerouslySetInnerHTML would be dangerous if we would use it with 3rd party APIs, still
                 * DOMPurify library can sanitize source HTML code that will be used in dangerouslySetInnerHTML
                 * other option would be to use external library called "html-react-parser", but we still need to sanitize
                 * more info: https://vhudyma-blog.eu/render-raw-html-in-react/
                 */
                <div dangerouslySetInnerHTML={{ __html: taskDescription }} />
              )}
            </div>
            <div className='col-lg-4 col-xl-3'>
              {taskContacts?.length > 1 && (
                <Card>
                  <CardBody>
                    <CardTitle>{translations.accessedBy}</CardTitle>
                    <ul className='list-unstyled'>
                      {taskContacts?.map((contact: { fullName: string }) => {
                        return (
                          <li key={`${contact.fullName}-${taskId}`}>
                            {contact.fullName}
                          </li>
                        );
                      })}
                    </ul>
                  </CardBody>
                </Card>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Details;
