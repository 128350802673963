import React from 'react';
import HelmetTitle from '../../common/HelmetTitle';
import { useTranslate } from '../../../resources/useTranslate';

export interface NoMatchProps {
  location?: any;
}

const NoMatch = ({ location }: NoMatchProps) => {
  const pathName = `${location?.pathname}`;
  const ns = 'construo.global';
  const translations = {
    page404Title: useTranslate(`${ns}.page404Title`),
    page404Text: useTranslate(`${ns}.page404Text`, pathName),
  };
  return (
    <>
      <HelmetTitle title={translations.page404Title} />

      <div className='main-section'>
        <div className='container'>
          <h1>{translations.page404Title}</h1>
          <p>{translations.page404Text}</p>
        </div>
      </div>
    </>
  );
};

export default NoMatch;
