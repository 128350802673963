import React, { useEffect, useRef } from 'react';
import { useTranslate } from '../../resources/useTranslate';
import ActionRequest from '../common/ActionRequest';

interface WorkflowRequestProps {
  workflow: any;
  conversations: any[];
}

const WorkflowRequest = ({ workflow, conversations }: WorkflowRequestProps) => {
  const ns = 'construo.workflows';
  const translations = {
    noWorkflowName: useTranslate(`${ns}.noWorkflowName`),
    unreadMessages: useTranslate(`${ns}.unreadMessages`),
  };
  const workflowId = workflow.id;
  const workflowName = workflow.displayName;
  const heading = !!workflowName ? workflowName : translations.noWorkflowName;
  const tasksInWorkflow = workflow.tasks;

  const unreadMessagesNumber = useRef(0);
  useEffect(() => {
    const findNumberOfUnreadMessagesPerWorkflow = () => {
      const conversationsWithUnreadMessages = tasksInWorkflow.map(
        (task: any) => {
          const conversationsWithUnreadMessagesInTasks = conversations?.filter(
            (c: any) => c.resourceName === task.id
          );
          return conversationsWithUnreadMessagesInTasks;
        }
      );

      const amountsOfUnreadMessagesPerConversation = conversationsWithUnreadMessages.map(
        (c: any) => {
          const unreadMessagesNo = !!c && c[0]?.numberOfUnreadActivities;
          return !!unreadMessagesNo ? unreadMessagesNo : 0;
        }
      );

      const totalUnreadMessagesPerWorkflow = amountsOfUnreadMessagesPerConversation.reduce(
        (partialSum: number, a: number) => partialSum + a,
        0
      );

      return totalUnreadMessagesPerWorkflow;
    };

    const numberOfUnreadMessagesPerWorkflow = findNumberOfUnreadMessagesPerWorkflow();

    unreadMessagesNumber.current = numberOfUnreadMessagesPerWorkflow;
  }, [conversations, tasksInWorkflow]);

  const cardNotifications = () => {
    if (!!unreadMessagesNumber.current) {
      return [`${translations.unreadMessages}`];
    } else {
      return null;
    }
  };

  return (
    <ActionRequest
      type='workflowRequest'
      href={`/user/workflows/${workflowId}`}
      heading={heading}
      notifications={cardNotifications()}
    />
  );
};

export default WorkflowRequest;
