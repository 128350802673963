import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface UserState {
  username: string | null;
  userSub: string | null;
  authenticated: boolean | null;
  message: string | null;
  populated: boolean | null;
}

const initialState: UserState = {
  username: null,
  userSub: null,
  authenticated: null,
  message: null,
  populated: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      state.authenticated = true;
      state.username = action.payload.username;
      state.userSub = action.payload.userSub;
      state.populated = true;
    },
    removeUser(state) {
      state.username = null;
      state.authenticated = null;
      state.populated = false;
    },
    showMessage: (state, action) => {
      state.message = action.payload;
    },
    populateUser: state => {
      state.populated = true;
    },
  },
});

export const {
  setUser,
  removeUser,
  showMessage,
  populateUser,
} = userSlice.actions;
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
