import { AxiosRequestConfig } from 'axios';

export const navigateToReturnUrl = (returnUrl: string) => {
  // It's important that we do a replace here so that we remove the callback uri with the
  // fragment containing the tokens from the browser history.
  window.location.replace(returnUrl);
};

/**
 * Make object comparable - replace all values with empty string while keeping nested structure
 * @param obj
 */
export const getKeys = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] !== null && typeof obj[key] === 'object') {
      getKeys(obj[key]);
    } else {
      obj[key] = '';
    }
  });
  return obj;
};

/**
 * Axios Config - get config object for axios calls
 */

export const getAxiosConfig = (
  token: string | null | undefined,
  responseType: any
) => {
  const config: AxiosRequestConfig = {
    headers: !token
      ? {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }
      : {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
    responseType: responseType,
  };
  return config;
};
