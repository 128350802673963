export const formattedDate = (dateString?: string, languageId?: string) => {
  let formattedDate = '';
  if (typeof dateString !== 'undefined' && dateString.length) {
    const date = new Date(dateString);
    const languageCode = languageId;
    formattedDate = new Intl.DateTimeFormat(languageCode, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(date);
  }
  return formattedDate;
};

export const convertMilisecondsToDateTime = (
  ms: number,
  languageId: string
) => {
  const date = new Date(ms * 1000).toLocaleString(languageId);
  return date;
};
