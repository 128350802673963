import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import React, { useEffect, useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { Button } from '@progress/kendo-react-buttons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../resources/useTranslate';
import envVars from '../../resources/envVars';
import authService, {
  AuthenticationResultStatus,
} from '../api-authorization/AuthorizeService';
import {
  removeUser,
  selectUser,
  showMessage,
} from '../api-authorization/userSlice';
import { navigateToReturnUrl } from '../../helpers/utils';

import './IdleTimerContainer.scss';

const IdleTimerContainer = () => {
  const dispatch = useDispatch();

  const ns = 'construo.idleTimer';
  const translations = {
    modalTitle: useTranslate(`${ns}.modalTitle`),
    modalMessage: useTranslate(`${ns}.modalMessage`),
    logMeOut: useTranslate(`${ns}.logMeOut`),
    keepMeSignedIn: useTranslate(`${ns}.keepMeSignedIn`),
    successfullyLoggedOut: useTranslate(
      'construo.global.successfullyLoggedOut'
    ),
    getAccessTokenError: useTranslate('construo.errors.getAccessTokenError'),
    silentAuthError: useTranslate('construo.errors.silentAuthError'),
    invalidAuth: useTranslate('construo.errors.invalidAuth'),
  };

  const idleTimerRef: any = useRef(null);
  const sessionTimeoutRef: any = useRef(null);
  const isIdleExpiredRef: any = useRef(null);

  const interval: any = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const allowedIdleDuration: number = envVars.IDLE_TIME || 300;
  const modalIdleDuration: number = envVars.IDLE_DIALOG_TIME || 30;

  /**
   * Temp display box for timeRemaining()... to be used for debugging purposes
   */

  // useEffect(() => {
  //   if (!document.getElementById('timer_id')) {
  //     const timerElement: any = document.createElement('div');
  //     timerElement.setAttribute('id', 'timer_id');
  //     timerElement.setAttribute(
  //       'style',
  //       'position: fixed; bottom: 10px; right: 10px; z-index: 800; font-size: 30px; color: white; background-color: red; padding: 10px;'
  //     );
  //     const body = document.getElementsByTagName('body')[0];
  //     body.appendChild(timerElement);
  //     interval.current = setInterval(() => {
  //       timerElement.innerHTML =
  //         Math.round(idleTimerRef.current?.getRemainingTime() / 1000) + ' sec';
  //       if (
  //         Math.round(idleTimerRef.current?.getRemainingTime() / 1000) !== 0 &&
  //         !!isIdleExpiredRef.current
  //       ) {
  //         setIsModalOpen(false);
  //         isIdleExpiredRef.current = false;
  //       }
  //     }, 1000);
  //   }
  // });

  useEffect(() => {
    interval.current = setInterval(() => {
      if (
        Math.round(idleTimerRef.current?.getRemainingTime() / 1000) !== 0 &&
        !!isIdleExpiredRef.current
      ) {
        setIsModalOpen(false);
        isIdleExpiredRef.current = false;
      }
    }, 1000);
    return () => {
      clearInterval(interval.current);
    };
  });

  const onIdle = () => {
    isIdleExpiredRef.current = true;
    idleTimerRef.current?.pause();
    setIsModalOpen(true);
    sessionTimeoutRef.current = setTimeout(() => {
      if (Math.round(idleTimerRef.current?.getRemainingTime() / 1000) === 0) {
        logOut();
      } else {
        setIsModalOpen(false);
      }
    }, modalIdleDuration * 1000); // Time in seconds
  };

  const logOut = () => {
    isIdleExpiredRef.current = false;
    clearTimeout(sessionTimeoutRef.current);
    setIsModalOpen(false);
    clearInterval(interval.current);
    // Logout user
    dispatch(removeUser());
    setTimeout(() => {
      logout('/');
    }, 500);
  };

  const stayLoggedIn = () => {
    isIdleExpiredRef.current = false;
    idleTimerRef.current?.reset();
    clearTimeout(sessionTimeoutRef.current);
    setIsModalOpen(false);
  };

  const userState = useSelector(selectUser);

  const logout = async (returnUrl: string): Promise<void> => {
    if (userState.authenticated) {
      const result = await authService.signOut();
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          dispatch(removeUser());
          navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          dispatch(showMessage(result.message));
          break;
        default:
          throw new Error(translations.invalidAuth);
      }
    } else {
      dispatch(showMessage(translations.successfullyLoggedOut));
    }
  };

  return (
    <>
      {isModalOpen && (
        <Dialog
          className='idle-time-modal'
          closeIcon={false}
          title={translations.modalTitle}
        >
          <p>{translations.modalMessage}</p>
          <DialogActionsBar>
            <Button className='k-secondary' onClick={logOut}>
              {translations.logMeOut}
            </Button>
            <Button primary onClick={stayLoggedIn}>
              {translations.keepMeSignedIn}
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}

      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * allowedIdleDuration} // Time in seconds
        onIdle={onIdle}
        // onActive={onActive}
        // onAction={onAction}
        debounce={250}
        startOnMount={true}
        crossTab={{
          emitOnAllTabs: true,
        }}
      />
    </>
  );
};

export default IdleTimerContainer;
