import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, NavLink } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import HelmetTitle from '../../common/HelmetTitle';
import {
  Form,
  Field,
  FormElement,
  FieldWrapper,
} from '@progress/kendo-react-form';
import { Label, Hint, Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import {
  fetchPublicSubmission,
  fetchSMSSubmission,
  fetchBankIdSubmission,
  updateSubmissionData,
  resetSubmissionData,
  selectSubmission,
  selectSubmissionJSON,
  selectSubmissionFetched,
  setSubmissionFetched,
  selectSubmissionError,
} from './submissionSlice';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import { selectUser } from '../../api-authorization/userSlice';
import { Languages, selectLanguage } from '../../common/languageSwitcherSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import envVars from '../../../resources/envVars';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { useTranslate } from '../../../resources/useTranslate';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import { convertMilisecondsToDateTime } from '../../../helpers/DateHelper';
import FormioForm from '../../common/FormioForm';
import FormSealed from '../../common/FormSealed';
import FormInaccessible from '../../common/FormInaccessible';
import { getAxiosConfig } from '../../../helpers/utils';

export interface FormRouteParams {
  token: string;
}

interface FormioSubmissionProps {
  match: match<FormRouteParams>;
  isSubmitted: boolean;
}

const SubmissionForm = ({
  match,
  isSubmitted = false,
}: FormioSubmissionProps) => {
  const dispatch = useDispatch();
  let languageId = useSelector(selectLanguage);

  const token = match.params.token;
  const tokenDecoded: any = !!token && jwt_decode(token);
  const authenticationType = tokenDecoded.AuthenticationType;

  // Check IF token expired and when
  const timeNow = Date.now();
  const isTokenExpired = !!tokenDecoded
    ? Number(tokenDecoded.exp) * 1000 < timeNow
    : false;

  if (languageId === 'en-US') {
    languageId = Languages.NB;
  }
  const tokenExpiredOn =
    isTokenExpired &&
    convertMilisecondsToDateTime(Number(tokenDecoded.exp), languageId);

  const API_BASE_URI = envVars.API_BASE_URI;
  const GATEWAY_HUB_URL: string = envVars.GATEWAY_HUB_URL || '';

  const formSubmission: any = useSelector(selectSubmission);
  const submissionFetched = useSelector(selectSubmissionFetched);
  const submissionData = useSelector(selectSubmissionJSON);
  const isFormSealed = formSubmission?.FormSubmissionStatus === 'Sealed';
  const form = !!submissionData && JSON.parse(submissionData);

  const submissionError = useSelector(selectSubmissionError);

  let customerResponse =
    !!formSubmission.CustomerResponse &&
    JSON.parse(formSubmission.CustomerResponse);
  customerResponse = !!customerResponse ? customerResponse : {};

  const submissionGuid = formSubmission.SubmissionGuid;
  const submissionTitle = formSubmission?.FormTemplate?.Title;

  const FORM_SUBMISSIONS_URI = API_BASE_URI + '/kyc/FormSubmissions';

  const [connection, setConnection] = useState<null | HubConnection>(null);
  const currentConnectionId: any = useRef(null);

  const [invokeSMS, setInvokeSMS] = useState(true);
  const [isFormSubmitted, setIsFormSubmitted] = useState(isSubmitted);

  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeNeedResend, setIsCodeNeedResend] = useState(false);

  const codeRemainingTime = useRef(60);
  // const [displayRemainingTime, setDisplayRemainingTime] = useState<any>();

  const signalrLogLevel =
    process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Debug;

  const userState = useSelector(selectUser);

  const isUserLogged = userState.authenticated;
  const loginPath = ApplicationPaths.Login;

  const ns = 'construo.forms';
  const translations = {
    login: useTranslate(`construo.homepage.login`),
    gotoLoginPage: useTranslate(`construo.global.gotoLoginPage`),
    loaderCreatingSigningRequest: useTranslate(
      `${ns}.loaderCreatingSigningRequest`
    ),
    loaderSubmittingForm: useTranslate(`${ns}.loaderSubmittingForm`),
    loaderGeneratingPdf: useTranslate(`${ns}.loaderGeneratingPdf`),
    alertErrorCreatingSigningRequest: useTranslate(
      `${ns}.alertErrorCreatingSigningRequest`
    ),
    inputValidatorText: useTranslate(`${ns}.inputValidatorText`),
    formSubmittedHeading: useTranslate(`${ns}.formSubmittedHeading`),
    formSubmittedText: useTranslate(`${ns}.formSubmittedText`),
    fieldHint: useTranslate(`${ns}.fieldHint`),

    smsSecurityCode: useTranslate(`${ns}.smsSecurityCode`),
    inOrderToAccess: useTranslate(`${ns}.inOrderToAccess`),
    sendCode: useTranslate(`${ns}.sendCode`),
    sentSixDigitCode: useTranslate(`${ns}.sentSixDigitCode`),
    enterBelow: useTranslate(`${ns}.enterBelow`),
    accessBtnLabel: useTranslate(`${ns}.accessBtnLabel`),
    didNotReceiveCode: useTranslate(`${ns}.didNotReceiveCode`),
    requestCode: useTranslate(`${ns}.requestCode`),
    waitToRequestCode: useTranslate(`${ns}.waitToRequestCode`),

    bankIdLoginWarningHeading: useTranslate(`${ns}.bankIdLoginWarningHeading`),
    bankIdLoginWarningText: useTranslate(`${ns}.bankIdLoginWarningText`),
    tokenExpiredHeading: useTranslate(`${ns}.tokenExpiredHeading`),
    tokenExpiredText: useTranslate(`${ns}.tokenExpiredText`, tokenExpiredOn),
  };

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
      dispatch(setSubmissionFetched(null));
      dispatch(resetSubmissionData());
    };
  }, [dispatch]);

  const codeRemainingTimeInterval: any = useRef(null);

  const smsCodeResult = useRef();

  const reusableSetInterval = () => {
    return setInterval(() => {
      if (codeRemainingTime.current > 0) {
        const remainingTime: number = codeRemainingTime.current - 1;
        console.log('remainingTime', remainingTime);
        codeRemainingTime.current = remainingTime;
        // setDisplayRemainingTime(remainingTime);
      } else if (codeRemainingTime.current === 0) {
        setIsCodeNeedResend(true);
      }
    }, 1000);
  };

  useEffect(() => {
    if (
      !isCodeNeedResend &&
      isCodeSent &&
      codeRemainingTimeInterval.current === null
    ) {
      // TODO: start counter for sms code
      codeRemainingTimeInterval.current = reusableSetInterval();
    } else if (isCodeNeedResend) {
      dispatch(resetAlerts());
      clearInterval(codeRemainingTimeInterval.current);
    }
  }, [dispatch, codeRemainingTime, isCodeNeedResend, isCodeSent]);

  const sendSMSCode = useCallback(async () => {
    const smsCodeURL = `${FORM_SUBMISSIONS_URI}/SMS?token=${token}`;
    const config = {};
    await axios
      .get(smsCodeURL, config)
      .then(response => {
        smsCodeResult.current = response.data;
      })
      .catch(error => {
        dispatch(
          setAlerts({ message: error.response?.data?.Message, type: 'error' })
        );
        if (error.response.status === 409) {
          setIsCodeNeedResend(false);
        }
      });
  }, [dispatch, FORM_SUBMISSIONS_URI, token]);

  useEffect(() => {
    const formSubmissionData = {
      token,
      API_BASE_URI,
    };

    if (submissionFetched === null) {
      if (authenticationType === 'Public' && !isTokenExpired) {
        dispatch(setLoading(Loading.Show));
        dispatch(fetchPublicSubmission(formSubmissionData));
      } else if (
        authenticationType === 'Sms authentication' &&
        !isTokenExpired
      ) {
        // Send request to the Gateway to send SMS with a code to specified mobile phone number
        if (!smsCodeResult.current && invokeSMS && isCodeSent) {
          setInvokeSMS(false);
          sendSMSCode();
        }
      } else if (
        authenticationType === 'BankId authentication' &&
        !isTokenExpired
      ) {
        // Check IF User is Logged
        if (isUserLogged) {
          // Remove redirectToSubmissionURL from session storage once "BankId" Form Submission is accessed, User is logged in
          sessionStorage.removeItem('redirectToSubmissionURL');
          const bankIdFormSubmissionData = {
            token: token,
            url: API_BASE_URI + '/kyc/FormSubmissions/NIN?token=',
          };
          dispatch(setLoading(Loading.Show));
          dispatch(fetchBankIdSubmission(bankIdFormSubmissionData));
        }
      }
    } else if (submissionFetched) {
      dispatch(setLoading(Loading.Hide));
    }
  }, [
    dispatch,
    isUserLogged,
    isTokenExpired,
    token,
    API_BASE_URI,
    FORM_SUBMISSIONS_URI,
    submissionFetched,
    authenticationType,
    invokeSMS,
    isCodeSent,
    sendSMSCode,
  ]);

  // Display error alert if submission is not accessible
  useEffect(() => {
    if (submissionFetched && !form && submissionError) {
      dispatch(setAlerts({ message: submissionError, type: 'error' }));
    }
  }, [dispatch, submissionFetched, form, submissionError]);

  useEffect(() => {
    if (formSubmission.DirectSign) {
      // OPEN SignalR Connection
      if (!connection) {
        // SET Connection
        const connect = new HubConnectionBuilder()
          .withUrl(GATEWAY_HUB_URL)
          .configureLogging(signalrLogLevel)
          .withAutomaticReconnect()
          .build();
        setConnection(connect);
      }
      // START (if not started) SignalR Connection and LISTEN for EVENTS ('Notify' and 'RedirectToSign')
      if (!!connection && !currentConnectionId.current) {
        connection
          .start()
          .then(() => {
            currentConnectionId.current = connection.connectionId;
            connection.on('RedirectToSign', redirectURL => {
              window.location.href = redirectURL;
            });
            connection.on('Notify', message => {
              // Show messages (translations) depending of message.status, status values can be 1 or 0
              if (message.status === 1) {
                dispatch(
                  setLoadingMessage(translations.loaderCreatingSigningRequest)
                );
              } else {
                dispatch(setLoading(Loading.Hide));
                dispatch(setLoadingMessage(null));
                dispatch(
                  setAlerts({
                    message: translations.alertErrorCreatingSigningRequest,
                    type: 'error',
                  })
                );
              }
            });
          })
          .catch(error => {
            dispatch(setAlerts({ message: error, type: 'error' }));
          });
      }
    }
  }, [
    dispatch,
    formSubmission,
    connection,
    GATEWAY_HUB_URL,
    signalrLogLevel,
    translations.loaderCreatingSigningRequest,
    translations.alertErrorCreatingSigningRequest,
  ]);

  const saveRedirectUrl = () => {
    sessionStorage.setItem(
      'redirectToSubmissionURL',
      JSON.stringify(window.location.pathname)
    );
  };

  const onSubmit = async (submission: any) => {
    delete submission.metadata;
    delete submission.state;

    dispatch(updateSubmissionData(JSON.stringify(submission)));
    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.loaderSubmittingForm));

    const config = getAxiosConfig(null, 'json');
    const data = {
      SubmissionGuid: submissionGuid,
      UserToken: token,
      CustomerResponse: JSON.stringify(submission),
      FormSubmissionStatus: 'Submitted by client',
      CurrentConnectionId: !!currentConnectionId.current
        ? currentConnectionId.current
        : null,
    };
    const dataStringify = JSON.stringify(data);
    const url = FORM_SUBMISSIONS_URI;

    await axios
      .put(url, dataStringify, config)
      .then(response => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show success message
        setIsFormSubmitted(true);
        window.scrollTo(0, 0);
        if (formSubmission.DirectSign) {
          dispatch(setLoading(Loading.Show));
          dispatch(setLoadingMessage(translations.loaderGeneratingPdf));
        }
      })
      .catch(error => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message after submission fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  const onChange = (submission: any, schema: any) => {
    const fileLink = document.querySelector(
      '.formio-component-upload a[ref=fileLink]'
    );
    fileLink?.setAttribute('style', 'pointer-events: none;');

    const removeLink = document.querySelector(
      '.formio-component-upload i[ref=removeLink]'
    );
    removeLink?.setAttribute('style', 'cursor: pointer;');

    if (
      !!submission.changed &&
      submission.changed.component.type === 'file' &&
      submission.changed.value.length &&
      submission.isValid
    ) {
      fileLink?.removeAttribute('href');
    }
  };

  const max = 6;
  const inputValidator = (value: any) =>
    !value ? translations.inputValidatorText : '';

  const FormInput = (fieldRenderProps: any) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      max,
      value,
      ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : '';
    const errorId = showValidationMessage ? `${id}_error` : '';

    return (
      <>
        <FieldWrapper>
          <Label
            editorId={id}
            editorValid={valid}
            editorDisabled={disabled}
            optional={optional}
          >
            {label}
          </Label>
          <div className={'k-form-field-wrap'}>
            <Input
              valid={valid}
              type={type}
              id={id}
              disabled={disabled}
              maxLength={max}
              ariaDescribedBy={`${hindId} ${errorId}`}
              {...others}
            />
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              {showHint && <Hint id={hindId}>{hint}</Hint>}
              {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
              )}
              <Hint style={{ marginLeft: 'auto' }}>
                {value.length} / {max}
              </Hint>
            </div>
          </div>
        </FieldWrapper>
      </>
    );
  };

  const handleSMSCodeSubmit = (dataItem: any) => {
    const smsCode = dataItem.smsCode;
    // send request to the Gateway to send submission form
    const smsFormSubmissionData = {
      token: token,
      url: FORM_SUBMISSIONS_URI,
      smsCode: smsCode,
    };
    setIsCodeNeedResend(true);
    // clearInterval(codeRemainingTimeInterval.current);
    dispatch(setLoading(Loading.Show));
    dispatch(fetchSMSSubmission(smsFormSubmissionData));
  };

  return (
    <>
      <HelmetTitle title={submissionTitle} />

      <section className='main-section'>
        <div className='container'>
          {submissionError && <FormInaccessible />}

          {submissionFetched &&
            (isFormSubmitted ? (
              <>
                <h1>{translations.formSubmittedHeading}</h1>
                <p>{translations.formSubmittedText}</p>
                <p>
                  <NavLink to={loginPath}>{translations.gotoLoginPage}</NavLink>
                </p>
              </>
            ) : (
              !!form &&
              submissionFetched &&
              !isTokenExpired &&
              !isFormSealed &&
              (authenticationType === 'Public' ||
                authenticationType === 'Sms authentication' ||
                authenticationType === 'BankId authentication') && (
                <div lang={formSubmission?.Language?.Code}>
                  {!!formSubmission.Title && <h1>{formSubmission.Title}</h1>}
                  {!!formSubmission.Description && (
                    <p>{formSubmission.Description}</p>
                  )}
                  <FormioForm
                    form={form}
                    submission={customerResponse}
                    onSubmit={onSubmit}
                    onChange={onChange}
                    languageCode={formSubmission?.Language?.Code}
                  />
                </div>
              )
            ))}

          {!submissionFetched &&
            !isTokenExpired &&
            !isFormSealed &&
            authenticationType === 'Sms authentication' && (
              <>
                {!!formSubmission.Title && <h1>{formSubmission.Title}</h1>}
                <h5>{translations.smsSecurityCode}</h5>
              </>
            )}

          {!submissionFetched &&
            !isTokenExpired &&
            !isFormSealed &&
            !isCodeSent &&
            authenticationType === 'Sms authentication' && (
              <>
                <p>{translations.inOrderToAccess}</p>
                <Button primary={true} onClick={() => setIsCodeSent(true)}>
                  {translations.sendCode}
                </Button>
              </>
            )}

          {!submissionFetched &&
            !isTokenExpired &&
            !isFormSealed &&
            isCodeSent &&
            authenticationType === 'Sms authentication' && (
              <>
                <p>
                  {translations.sentSixDigitCode}
                  <br />
                  {translations.enterBelow}
                </p>

                <Form
                  initialValues={{
                    smsCode: '',
                  }}
                  onSubmit={handleSMSCodeSubmit}
                  render={formRenderProps => (
                    <FormElement style={{ maxWidth: '400px' }}>
                      <fieldset className={'k-form-fieldset'}>
                        <Field
                          id={'smsCode'}
                          name={'smsCode'}
                          label={'SMSLogin:'}
                          max={max}
                          value={formRenderProps.valueGetter('smsCode')}
                          hint={translations.fieldHint}
                          component={FormInput}
                          validator={inputValidator}
                        />
                        <div className='k-form-buttons k-justify-content-start'>
                          <Button
                            primary={true}
                            type={'submit'}
                            disabled={!formRenderProps.allowSubmit}
                          >
                            {translations.accessBtnLabel}
                          </Button>
                        </div>
                      </fieldset>
                    </FormElement>
                  )}
                />
                <div style={{ marginTop: '20px' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      marginRight: '10px',
                      verticalAlign: 'middle',
                    }}
                  >
                    {translations.didNotReceiveCode}
                  </span>

                  {isCodeNeedResend ? (
                    <>
                      <button
                        className='btn btn-link'
                        style={{ padding: '0' }}
                        onClick={e => {
                          e.preventDefault();
                          sendSMSCode();
                          dispatch(resetAlerts());
                          setIsCodeNeedResend(false);
                          setIsCodeSent(true);
                          codeRemainingTime.current = 60;
                          codeRemainingTimeInterval.current = null;
                          // codeRemainingTimeInterval.current = reusableSetInterval();
                        }}
                      >
                        {translations.requestCode}
                      </button>
                    </>
                  ) : (
                    <span
                      style={{
                        display: 'inline-block',
                        marginRight: '10px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {translations.waitToRequestCode}
                      {/* <span> {displayRemainingTime} </span> */}
                    </span>
                  )}
                </div>
              </>
            )}

          {!submissionFetched &&
            !isTokenExpired &&
            !isFormSealed &&
            authenticationType === 'BankId authentication' && (
              <>
                <h1>{translations.bankIdLoginWarningHeading}</h1>
                <p>{translations.bankIdLoginWarningText}</p>
                <NavLink
                  className='k-button k-primary'
                  to={loginPath}
                  onClick={saveRedirectUrl}
                >
                  {translations.login}
                </NavLink>
              </>
            )}

          {isTokenExpired && (
            <>
              <h1>{translations.tokenExpiredHeading}</h1>
              <p>{translations.tokenExpiredText}</p>
            </>
          )}
          {isFormSealed && <FormSealed />}
        </div>
      </section>
    </>
  );
};

export default SubmissionForm;
