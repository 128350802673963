import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HelmetTitle from '../../common/HelmetTitle';
import { useTranslate } from '../../../resources/useTranslate';
import envVars from '../../../resources/envVars';
import {
  orderBy,
  filterBy,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import {
  selectDocumentsData,
  populateDocumentsData,
  selectDocumentsFilter,
  setFiltersStatus,
  selectFiltersStatus,
  selectDocumentsSort,
  selectDocFetched,
  selectDocError,
  resetDocuments,
  setBulkDownloadItems,
  resetFilters,
  clearDocumentsError,
} from './documentsSlice';
import { Loading, selectLoading } from '../../../app/slices/loadingSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { Modal, selectModal } from '../../../app/slices/modalSlice';
import DocumentModal from './DocumentModal';
import QuickSearch from './QuickSearch';
import Filters from './Filters';
import DocumentsList from './DocumentsList';
import { Button } from '@progress/kendo-react-buttons';

import './Documents.scss';

const Documents = () => {
  const dispatch = useDispatch();
  // Translations
  const ns = 'construo.documents';
  const translations = {
    title: useTranslate(`${ns}.title`),
    filters: useTranslate(`${ns}.filters`),
    documents: useTranslate('construo.menu.documents'),
  };

  const [docErrorSet, setDocErrorSet] = useState(false);
  const documents = useSelector(selectDocumentsData);
  const docFetched = useSelector(selectDocFetched);
  const docError = useSelector(selectDocError);

  useEffect(() => {
    if (!!docError && !docErrorSet) {
      setDocErrorSet(true);
      dispatch(setAlerts({ message: docError, type: 'error' }));
      dispatch(clearDocumentsError()); // Clear documents -> error prop immediately when doc alert is added - setAlerts for docs
    }
  }, [dispatch, docError, docErrorSet]);

  useEffect(() => {
    return () => {
      dispatch(resetDocuments());
      dispatch(resetFilters()); // Clear filters
      dispatch(setFiltersStatus(false)); // Hide filters
      dispatch(resetAlerts());
      dispatch(setBulkDownloadItems([]));
    };
  }, [dispatch]);

  const filtersStatus = useSelector(selectFiltersStatus);
  const [filteredDocuments, setFilteredDocuments] = useState(documents);
  const selectedFilter = useSelector(
    selectDocumentsFilter
  ) as CompositeFilterDescriptor;
  const filter = selectedFilter;
  const sort = useSelector(selectDocumentsSort);

  const API_BASE_URI = envVars.API_BASE_URI;

  useEffect(() => {
    const dataObject = {
      baseUrl: API_BASE_URI,
      recent: null,
    };
    if (!!docFetched) {
      setFilteredDocuments(documents);
    } else if (docFetched === null) {
      dispatch(populateDocumentsData(dataObject));
    }
  }, [dispatch, docFetched, documents, API_BASE_URI]);

  useEffect(() => {
    const filtered = filterBy(documents, filter);
    setFilteredDocuments(orderBy(filtered, sort));
  }, [documents, filter, sort]);

  const documentsLength: number = !!documents ? documents.length : 0;

  const modalShow = useSelector(selectModal);

  const docLoading = useSelector(selectLoading) === Loading.Show;

  return (
    <>
      <HelmetTitle title={translations.documents} />

      {/* MODAL DIALOG */}
      {filteredDocuments.length > 0 &&
        modalShow === Modal.Show &&
        !docLoading && <DocumentModal />}

      <div className='main-section documents'>
        <div className='container'>
          <header>
            {/* PAGE TITLE */}
            {<h1>{translations.title}</h1>}
            {/* FILTERS BUTTON */}
            {documents.length > 0 && (
              <Button
                onClick={() => dispatch(setFiltersStatus(!filtersStatus))}
                className='filters-btn'
                iconClass='order-2 ml-1 fal fa-filter'
              >
                {translations.filters}
              </Button>
            )}
          </header>

          {/* FILTERS */}
          {<Filters documents={documents} />}

          {/* QUICK SEARCH */}
          {<QuickSearch />}

          {/* DOCUMENTS LIST */}
          <DocumentsList
            filtered={filteredDocuments}
            documentsLength={documentsLength}
            filter={filter}
            sort={sort}
            docFetched={docFetched}
          />
        </div>
      </div>
    </>
  );
};

export default Documents;
