import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, NavLink } from 'react-router-dom';
import HelmetTitle from '../../common/HelmetTitle';
import axios from 'axios';
import {
  fetchPublicForm,
  resetFormData,
  selectFormError,
  selectFormFetched,
  selectPublicFormData,
  selectTemplateData,
  setFormFetched,
  updateFormData,
} from './publicFormSlice';
import { Loading, setLoading } from '../../../app/slices/loadingSlice';
import { setLoadingMessage } from '../../../app/slices/loadingMessageSlice';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import envVars from '../../../resources/envVars';
import { useTranslate } from '../../../resources/useTranslate';
import FormioForm from '../../common/FormioForm';
import { getAxiosConfig } from '../../../helpers/utils';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';

type FormRouteParams = {
  guid: string;
};

export interface FormioFormProps {
  match: match<FormRouteParams>;
  isSubmitted: boolean;
}

/**
 * TODO: Provide a way to translate messages and error messages, language settings comes from Form Template (gateway response) - KYC API sets Form Language
 */

const PublicForm = ({ match, isSubmitted = false }: FormioFormProps) => {
  const ns = 'construo.forms';
  const translations = {
    login: useTranslate(`construo.homepage.login`),
    gotoLoginPage: useTranslate(`construo.global.gotoLoginPage`),
    alertTest: useTranslate(`${ns}.alertTest`),
    alertErrorCreatingSigningRequest: useTranslate(
      `${ns}.alertErrorCreatingSigningRequest`
    ),
    loaderCreatingSigningRequest: useTranslate(
      `${ns}.loaderCreatingSigningRequest`
    ),
    loaderSubmittingForm: useTranslate(`${ns}.loaderSubmittingForm`),
    loaderGeneratingPdf: useTranslate(`${ns}.loaderGeneratingPdf`),
    formSubmittedHeading: useTranslate(`${ns}.formSubmittedHeading`),
    formSubmittedText: useTranslate(`${ns}.formSubmittedText`),
    formInaccessibleHeading: useTranslate(`${ns}.formInaccessibleHeading`),
    formInaccessibleText: useTranslate(`${ns}.formInaccessibleText`),
  };

  const dispatch = useDispatch();
  let guid = match.params.guid;

  const loginPath = ApplicationPaths.Login;

  // Determine IF page render is a TEST preview, isTest=true
  const params = new URLSearchParams(window.location.search);
  const isTestValue = params.get('isTest');
  const isTest = isTestValue === 'true';

  if (isTest) {
    guid = guid + '?isTest=true';
  }

  const publicForm: any = useSelector(selectTemplateData);
  const formFetched = useSelector(selectFormFetched);
  const formData = useSelector(selectPublicFormData);
  const form = !!formData && JSON.parse(formData);
  const formError = useSelector(selectFormError);

  const API_BASE_URI = envVars.API_BASE_URI;
  const GATEWAY_HUB_URL: string = envVars.GATEWAY_HUB_URL || '';

  const [connection, setConnection] = useState<null | HubConnection>(null);
  const currentConnectionId: any = useRef(null);

  const [isFormSubmitted, setIsFormSubmitted] = useState(isSubmitted);

  const signalrLogLevel =
    process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Debug;

  // Load the form initially
  useEffect(() => {
    const fetchPublicFormData = {
      guid,
      API_BASE_URI,
    };

    if (formFetched === null) {
      dispatch(setLoading(Loading.Show));
      dispatch(fetchPublicForm(fetchPublicFormData));
    } else if (formFetched) {
      dispatch(setLoading(Loading.Hide));
    }
  }, [dispatch, formFetched, guid, API_BASE_URI]);

  // Display error alert if form is not accessible
  useEffect(() => {
    if (formFetched && !form && formError) {
      dispatch(setAlerts({ message: formError, type: 'error' }));
    }
  }, [dispatch, formFetched, form, formError]);

  // Display warning alert if form is in test mode
  useEffect(() => {
    if (isTest) {
      dispatch(setAlerts({ message: translations.alertTest, type: 'warning' }));
    }
  }, [dispatch, isTest, translations.alertTest]);

  useEffect(() => {
    return () => {
      dispatch(resetAlerts());
      dispatch(setLoading(Loading.Hide));
      dispatch(setFormFetched(null));
      dispatch(resetFormData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (publicForm.DirectSign) {
      // OPEN SignalR Connection
      if (!connection) {
        // SET Connection
        const connect = new HubConnectionBuilder()
          .withUrl(GATEWAY_HUB_URL)
          .configureLogging(signalrLogLevel)
          .withAutomaticReconnect()
          .build();
        setConnection(connect);
      }
      // START (if not started) SignalR Connection and LISTEN for EVENTS ('Notify' and 'RedirectToSign')
      if (!!connection && !currentConnectionId.current) {
        connection
          .start()
          .then(() => {
            currentConnectionId.current = connection.connectionId;
            connection.on('RedirectToSign', redirectURL => {
              window.location.href = redirectURL;
            });
            connection.on('Notify', message => {
              // Show messages (translations) depending of message.status, status values can be 1 or 0
              if (message.status === 1) {
                dispatch(
                  setLoadingMessage(translations.loaderCreatingSigningRequest)
                );
              } else {
                dispatch(setLoading(Loading.Hide));
                dispatch(setLoadingMessage(null));
                dispatch(
                  setAlerts({
                    message: translations.alertErrorCreatingSigningRequest,
                    type: 'error',
                  })
                );
              }
            });
          })
          .catch(error => {
            dispatch(setAlerts({ message: error, type: 'error' }));
          });
      }
    }
  }, [
    dispatch,
    publicForm,
    connection,
    GATEWAY_HUB_URL,
    signalrLogLevel,
    translations.loaderCreatingSigningRequest,
    translations.alertErrorCreatingSigningRequest,
  ]);

  const FORM_SUBMISSIONS_URI = envVars.API_BASE_URI + '/kyc/FormSubmissions';

  const GUID = publicForm.GUID;

  const onSubmit = async (submission: any) => {
    delete submission.metadata;
    delete submission.state;

    dispatch(updateFormData(JSON.stringify(submission)));
    dispatch(setLoading(Loading.Show));
    dispatch(setLoadingMessage(translations.loaderSubmittingForm));

    const url = FORM_SUBMISSIONS_URI;
    const config = getAxiosConfig(null, 'json');
    const data = {
      TemplateGuid: GUID,
      CustomerResponse: JSON.stringify(submission),
      FormSubmissionStatus: 'Submitted by client',
      // Saml: null,
      CurrentConnectionId: !!currentConnectionId.current
        ? currentConnectionId.current
        : null,
    };

    const dataStringify = JSON.stringify(data);

    await axios
      .post(url, dataStringify, config)
      .then(response => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show success message
        setIsFormSubmitted(true);
        window.scrollTo(0, 0);

        if (publicForm.DirectSign) {
          dispatch(setLoading(Loading.Show));
          dispatch(setLoadingMessage(translations.loaderGeneratingPdf));
        }
      })
      .catch(error => {
        dispatch(setLoading(Loading.Hide));
        dispatch(setLoadingMessage(null));
        // Show error message after submission fails
        dispatch(setAlerts({ message: error.message, type: 'error' }));
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  const onChange = (submission: any, schema: any) => {
    const fileLink = document.querySelector(
      '.formio-component-upload a[ref=fileLink]'
    );
    fileLink?.setAttribute('style', 'pointer-events: none;');

    const removeLink = document.querySelector(
      '.formio-component-upload i[ref=removeLink]'
    );
    removeLink?.setAttribute('style', 'cursor: pointer;');

    if (
      !!submission.changed &&
      submission.changed.component.type === 'file' &&
      submission.changed.value.length &&
      submission.isValid
    ) {
      fileLink?.removeAttribute('href');
    }
  };

  return (
    <>
      <HelmetTitle title={publicForm?.Title} />

      <section className='main-section'>
        <div className='container'>
          {formFetched &&
            (isFormSubmitted ? (
              <>
                <h1>{translations.formSubmittedHeading}</h1>
                <p>{translations.formSubmittedText}</p>
                <p>
                  <NavLink to={loginPath}>{translations.gotoLoginPage}</NavLink>
                </p>
              </>
            ) : (
              <>
                {form ? (
                  <>
                    <div lang={publicForm.LanguageCode}>
                      <h1>{publicForm.Title}</h1>
                      {publicForm.Description && (
                        <p>{publicForm.Description}</p>
                      )}
                      <FormioForm
                        form={form}
                        submission={publicForm.customerResponse}
                        onSubmit={onSubmit}
                        onChange={onChange}
                        languageCode={publicForm.LanguageCode}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <h1>{translations.formInaccessibleHeading}</h1>
                    <p>{translations.formInaccessibleText}</p>
                  </>
                )}
              </>
            ))}
        </div>
      </section>
    </>
  );
};

export default PublicForm;
