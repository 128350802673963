import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';
import envVars from '../../../resources/envVars';
import { useTranslate } from '../../../resources/useTranslate';
import UploadAttachmentsModal from './UploadAttachmentsModal';
import { setAlerts } from '../../../app/slices/alertSlice';
import { resetTaskDetailsData } from './taskDetailsSlice';
import { resetWorkflowData } from '../workflowSlice';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';

import './Actions.scss';

interface ActionsProps {
  workflowId: string | null;
  taskId: string;
  actions: [];
}

const Actions = ({ workflowId, taskId, actions }: ActionsProps) => {
  const dispatch = useDispatch();

  const ns = 'construo.workflows';
  const translations = {
    noAttachmentsAdded: useTranslate(`${ns}.noAttachmentsAdded`),
    addFirstDocument: useTranslate(`${ns}.addFirstDocument`),
    addDocuments: useTranslate(`${ns}.addDocuments`),
    reviewingSubmission: useTranslate(`${ns}.reviewingSubmission`),
    completeBtnLabel: useTranslate(`${ns}.completeBtnLabel`),
    noActionAdded: useTranslate(`${ns}.noActionAdded`),
    completeActionSuccess: useTranslate(`${ns}.completeActionSuccess`),
    completeActionError: useTranslate(`${ns}.completeActionError`),
  };

  const selectedActionId = useRef<string | null>(null);

  const [isShowModal, setIsShowModal] = useState(false);

  const showModal = (modalState: boolean) => {
    setIsShowModal(modalState);
  };

  const completeAction = async (actionId: any) => {
    const token = await authService.getAccessToken();
    const API_BASE_URI = envVars.API_BASE_URI;
    const workflowsUrl = `${API_BASE_URI}/user/workflows`;
    const url = `${workflowsUrl}/${workflowId}/tasks/${taskId}/actions/${actionId}/complete`;
    const data = {};
    const config = getAxiosConfig(token, 'json');
    await axios
      .post(url, data, config)
      .then(response => response.data)
      .then(response => {
        // Reset task details data so that relevant section of the page is reloaded/updated
        dispatch(resetTaskDetailsData());
        // Reset workflows so that task statuses are updated in redux store
        dispatch(resetWorkflowData());
        return response;
      })
      .catch(error => {
        // Show error message after Complete Action Event fails
        dispatch(
          setAlerts({
            message: `${translations.completeActionError} ${error.response.data.Message}`,
            type: 'error',
          })
        );
        // And scroll to top so that alert is visible
        window.scrollTo(0, 0);
      });
  };

  return (
    <>
      {actions?.length > 0 ? (
        <>
          {actions.map(
            (
              action: {
                id: string;
                displayName: string;
                inputDataBody: string;
                outputData: string;
                status: string;
              },
              index: number
            ) => {
              const actionId = action.id;
              const isActionId = actionId === selectedActionId.current;

              const actionName = action.displayName;
              const actionStatus = action.status;

              const inputData =
                !!action.inputDataBody && JSON.parse(action.inputDataBody);

              const outputData =
                !!action.outputData && JSON.parse(action.outputData);

              const attachments = outputData?.ExternalDocuments?.Documents;
              const messageToClient =
                inputData?.ExternalDocuments?.MessageToClient;

              const splitToParagraphs = (message: string) => {
                const result = message.split(/\r?\n/);
                return result;
              };

              const messageToClientResults =
                !!messageToClient && splitToParagraphs(messageToClient); // \n is a separator (line-break)

              return (
                <section key={action.id} className='main-section task-action'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-lg-8 col-xl-9'>
                        {isShowModal && !!workflowId && isActionId && (
                          <UploadAttachmentsModal
                            workflowId={workflowId}
                            taskId={taskId}
                            actionId={actionId}
                            setModalStatus={modalStatus => {
                              showModal(modalStatus);
                            }}
                          />
                        )}

                        <h2>{actionName}</h2>

                        {!!messageToClient && actionStatus !== 'Done' && (
                          <div className='message-to-client'>
                            {Array.isArray(messageToClientResults) &&
                            messageToClientResults.length > 1 ? (
                              messageToClientResults.map(
                                (messageToClientResult: string) => {
                                  return <p>{messageToClientResult}</p>;
                                }
                              )
                            ) : (
                              <p>{messageToClientResults}</p>
                            )}
                          </div>
                        )}

                        {action.outputData === null ? (
                          action.status === 'Done' ? (
                            <span className='system-text'>
                              {translations.reviewingSubmission}
                            </span>
                          ) : (
                            <>
                              <p className='system-text'>
                                {translations.noAttachmentsAdded}
                              </p>
                              <Button
                                primary
                                onClick={() => {
                                  showModal(true);
                                  selectedActionId.current = actionId;
                                }}
                              >
                                {translations.addFirstDocument}
                              </Button>
                            </>
                          )
                        ) : (
                          <>
                            {!!attachments && attachments.length > 0 && (
                              <ol className='attachments-list list-group list-group-numbered'>
                                {attachments.map(
                                  (attachment: {
                                    DocumentName: string;
                                    DocumentId: string;
                                  }) => {
                                    return (
                                      <li
                                        key={attachment.DocumentId}
                                        className='list-group-item'
                                      >
                                        {attachment.DocumentName}
                                      </li>
                                    );
                                  }
                                )}
                              </ol>
                            )}
                            {actionStatus === 'Pending' && (
                              <p>
                                <Button
                                  iconClass={'fal fa-plus'}
                                  className={'btn-sm'}
                                  onClick={() => {
                                    showModal(true);
                                    selectedActionId.current = actionId;
                                  }}
                                >
                                  {translations.addDocuments}
                                </Button>
                              </p>
                            )}
                            <p>
                              {actionStatus === 'Done' ? (
                                <span className='system-text'>
                                  {translations.reviewingSubmission}
                                </span>
                              ) : (
                                <Button
                                  primary
                                  onClick={() => completeAction(actionId)}
                                >
                                  {translations.completeBtnLabel}
                                </Button>
                              )}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              );
            }
          )}
        </>
      ) : (
        <div className='main-section task-action'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-8 col-xl-9'>
                <p className='system-text'>{translations.noActionAdded}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Actions;
