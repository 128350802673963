import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import envVars from '../../resources/envVars';

export enum Languages {
  EN = 'en-US',
  NB = 'nb',
  FI = 'fi',
}

let initialState = Languages.EN;
const preferredLanguage = !!envVars.LANGUAGES ? envVars.LANGUAGES[0] : null;
const userLang = localStorage.getItem('language');

if (!!userLang) {
  switch (userLang) {
    case 'en-US':
      initialState = Languages.EN;
      break;
    case 'nb':
      initialState = Languages.NB;
      break;
    case 'fi':
      initialState = Languages.FI;
      break;
  }
} else {
  switch (preferredLanguage) {
    case 'en-US':
      initialState = Languages.EN;
      break;
    case 'nb':
      initialState = Languages.NB;
      break;
    case 'fi':
      initialState = Languages.FI;
      break;
  }
}

const languageSwitcherSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<Languages>) {
      localStorage.setItem('language', action.payload);
      return action.payload;
    },
  },
});

export const { setLanguage } = languageSwitcherSlice.actions;

export const selectLanguage = (state: RootState) => state.language;

export default languageSwitcherSlice.reducer;
