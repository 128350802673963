import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import envVars from '../../resources/envVars';
import { useTranslate } from '../../resources/useTranslate';
import SignatureRequest from './SignatureRequest';
import { setAlerts } from '../../app/slices/alertSlice';
import {
  fetchSignatureRequests,
  selectDigitalIdentity,
  selectSignatures,
  selectSignaturesFetched,
  DigitalIdentityProps,
} from './signaturesSlice';

type SignatureRequestListProps = {};

const SignatureRequestList = (props: SignatureRequestListProps) => {
  const dispatch = useDispatch();
  const signatures: object[] = useSelector(selectSignatures);
  const signaturesFetched = useSelector(selectSignaturesFetched);
  const API_BASE_URI: string = envVars.API_BASE_URI || '';

  const digitalIdentity: DigitalIdentityProps | null = useSelector(
    selectDigitalIdentity
  );
  const nin = digitalIdentity?.nin;
  const idp = digitalIdentity?.idp;
  const isUserMissingNinOrIdp = !nin || !idp?.includes('bankid');

  const ns = 'construo.signatureRequests';
  const translations = {
    userMissingNinAndBankId: useTranslate(`${ns}.userMissingNinAndBankId`),
    userMissingNinAndLocal: useTranslate(`${ns}.userMissingNinAndLocal`),
  };

  useEffect(() => {
    if (signaturesFetched === null && !isUserMissingNinOrIdp) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchSignatureRequests(dataObject));
    } else if (signaturesFetched === null && isUserMissingNinOrIdp) {
      const isUserMissingNinAndBankId = !nin && idp?.includes('bankid');
      const isUserMissingNinAndLocal = !nin && idp === 'local';
      let message;
      if (isUserMissingNinAndBankId) {
        message = translations.userMissingNinAndBankId;
      } else if (isUserMissingNinAndLocal) {
        message = translations.userMissingNinAndLocal;
      }
      if (!!message) {
        dispatch(setAlerts({ message: message, type: 'info' }));
      }
    }
  }, [
    dispatch,
    signaturesFetched,
    API_BASE_URI,
    idp,
    nin,
    isUserMissingNinOrIdp,
    translations.userMissingNinAndBankId,
    translations.userMissingNinAndLocal,
  ]);

  return (
    <>
      {signatures.map((signature: any) => {
        return <SignatureRequest key={signature.id} signature={signature} />;
      })}
    </>
  );
};

export default SignatureRequestList;
