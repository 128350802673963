import React from 'react';
import { ComponentType } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  ApplicationPaths,
  QueryParameterNames,
} from './ApiAuthorizationConstants';
import { useSelector } from 'react-redux';
import { selectUser } from './userSlice';

interface Props {
  exact?: boolean;
  path: string;
  component: ComponentType<any>;
}

export const AuthorizeRoute = ({ path, component }: Props) => {
  const userState = useSelector(selectUser);
  const { component: Component, ...rest } = { path, component };
  const redirectUrl = `${ApplicationPaths.Login}?${
    QueryParameterNames.ReturnUrl
  }=${encodeURIComponent(window.location.href)}`;

  return (
    <Route
      {...rest}
      render={props => {
        if (userState.authenticated) {
          return <Component {...props} />;
        } else {
          return <Redirect to={redirectUrl} />;
        }
      }}
    />
  );
};
