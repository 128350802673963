import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
} from '@progress/kendo-react-layout';
import envVars from '../../resources/envVars';
import { useTranslate } from '../../resources/useTranslate';

import './ActionRequest.scss';

export interface ActionRequestProps {
  type:
    | 'signatureRequest'
    | 'uploadRequest'
    | 'submissionRequest'
    | 'workflowRequest';
  tagline?: string;
  heading: string;
  subHeadings?: string[];
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  href?: string;
  notifications?: string[];
}

const ActionRequest = ({
  type,
  tagline: taglineProp,
  heading,
  subHeadings,
  onClick,
  href = '/',
  notifications,
}: ActionRequestProps) => {
  const ns = 'construo.homepage';
  const translations = {
    signatureRequestTagName: useTranslate(`${ns}.signatureRequestTagName`),
    uploadRequestTagName: useTranslate(`${ns}.uploadRequestTagName`),
    submissionRequestTagName: useTranslate(`${ns}.submissionRequestTagName`),
    workflowRequestTagName: useTranslate(`${ns}.workflowRequestTagName`),
  };

  const customFolderName = envVars.CUSTOM_FOLDER_NAME;
  const [illustrationImage, setIllustrationImage] = useState();
  const [tagline, setTagline] = useState<string>();

  useEffect(() => {
    const setImage = async (imageName: string) => {
      let image;
      try {
        image = await import(
          `../../custom/${customFolderName}/assets/media/${imageName}`
        );
      } catch {
        image = await import(`../../assets/media/${imageName}`);
      } finally {
        setIllustrationImage(image.default);
      }
    };

    // Self invoked function to call setImage method - name of the image is based on image type
    (() => {
      setImage(`${type}.svg`);
    })();

    // Set tagline by ActionRequest type
    switch (type) {
      case 'signatureRequest':
        setTagline(
          taglineProp !== undefined
            ? taglineProp
            : translations.signatureRequestTagName
        );
        break;
      case 'uploadRequest':
        setTagline(
          taglineProp !== undefined
            ? taglineProp
            : translations.uploadRequestTagName
        );
        break;
      case 'submissionRequest':
        setTagline(
          taglineProp !== undefined
            ? taglineProp
            : translations.submissionRequestTagName
        );
        break;
      case 'workflowRequest':
        setTagline(
          taglineProp !== undefined
            ? taglineProp
            : translations.workflowRequestTagName
        );
        break;
      default:
      // default code
    }
  }, [
    type,
    taglineProp,
    customFolderName,
    translations.signatureRequestTagName,
    translations.uploadRequestTagName,
    translations.submissionRequestTagName,
    translations.workflowRequestTagName,
  ]);

  return (
    <NavLink to={href} className='action-request' onClick={onClick}>
      <Card orientation={'horizontal'}>
        {!!illustrationImage && (
          <figure>
            <img
              src={illustrationImage}
              alt={heading}
              className='action-request-image'
            />
          </figure>
        )}
        <CardBody>
          {tagline && <p className='tagline'>{tagline}</p>}
          <CardTitle>{heading}</CardTitle>
          {!!subHeadings?.length && (
            <CardSubtitle>
              <ul className='list-inline'>
                {subHeadings.map((subHeading, index) => (
                  <li key={index} className='list-inline-item'>
                    {subHeading}
                  </li>
                ))}
              </ul>
            </CardSubtitle>
          )}
          {!!notifications?.length && (
            <p>
              {notifications.map((notification, index) => (
                <span key={index} className='badge bg-primary rounded-pill'>
                  {notification}
                </span>
              ))}
            </p>
          )}
        </CardBody>
      </Card>
    </NavLink>
  );
};

export default ActionRequest;
