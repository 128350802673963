import React, { useEffect } from 'react';
import HelmetTitle from '../../common/HelmetTitle';
import { useTranslate } from '../../../resources/useTranslate';
import { Redirect } from 'react-router-dom';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import { useDispatch } from 'react-redux';
import { resetConversationError } from '../conversations/conversationsSlice';
import { resetWorkflowError } from '../../workflows/workflowSlice';
import { resetSubmissionError } from '../submission-forms/submissionSlice';

export interface NoResourceMatchProps {
  location?: any;
}

const NoResourceMatch = ({ location }: NoResourceMatchProps) => {
  const dispatch = useDispatch();

  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const guid = params.get('guid');
  const type = params.get('type');
  const errorMessage = params.get('error');

  useEffect(() => {
    if (errorMessage) {
      dispatch(setAlerts({ message: errorMessage, type: 'error' }));
    }
    return () => {
      dispatch(resetAlerts());
      switch (type) {
        case 'submission':
          dispatch(resetSubmissionError());
          break;
        case 'workflow':
          dispatch(resetWorkflowError());
          break;
        case 'task':
          //
          break;
        case 'conversation':
          dispatch(resetConversationError());
          break;

        default:
          break;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ns = 'construo.global';
  const translations: any = {
    submissionType: useTranslate(`${ns}.submissionType`),
    workflowType: useTranslate(`${ns}.workflowType`),
    taskType: useTranslate(`${ns}.taskType`),
    conversationType: useTranslate(`${ns}.conversationType`),
    documentType: useTranslate(`${ns}.documentType`),
    resource: useTranslate(`${ns}.resource`),
    type: useTranslate(`${ns}.type`),
    id: useTranslate(`${ns}.id`),

    invalid: useTranslate(`${ns}.invalid`),
  };

  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  let isIdValid: boolean = true;

  let resourceType: any = translations.resource;

  switch (type) {
    case 'submission':
      resourceType = translations.submissionType;
      isIdValid = !!guid && regexExp.test(guid);
      break;
    case 'workflow':
      resourceType = translations.workflowType;
      isIdValid = !!guid && regexExp.test(guid);
      break;
    case 'task':
      resourceType = translations.taskType;
      isIdValid = !!guid && regexExp.test(guid);
      break;
    case 'conversation':
      resourceType = translations.conversationType;
      isIdValid = !!guid && regexExp.test(guid);
      break;
    case 'document':
      resourceType = translations.documentType;
      // isIdValid = true;
      break;
    default:
      break;
  }

  translations['resource404Title'] = useTranslate(
    `${ns}.resource404Title`,
    resourceType
  );
  translations['resource404Text'] = useTranslate(
    `${ns}.resource404Text`,
    resourceType
  );

  return (
    <>
      <HelmetTitle title={translations.resource404Title} />

      {!guid ? (
        <Redirect to={'error'} />
      ) : (
        <div className='main-section no-resource-match'>
          <div className='container'>
            <h1>{translations.resource404Title}</h1>
            <p>{translations.resource404Text}</p>
            <br />
            <p className='small'>
              <strong>{`${translations.resource} ${translations.id}: `}</strong>
              {isIdValid ? guid : `${guid} (${translations.invalid})`} <br />
              <strong>{`${translations.resource} ${translations.type}: `}</strong>
              {resourceType}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default NoResourceMatch;
