import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';
import { getAxiosConfig } from '../../helpers/utils';
import authService from '../api-authorization/AuthorizeService';

// Gateway API version - getting eSignature Requests from Gateway API
export const fetchSignatureRequests = createAsyncThunk(
  'fetchSignatureRequests',
  async (dataObject: any) => {
    const token = await authService.getAccessToken();
    const baseUrl = dataObject.baseUrl;
    const url = baseUrl + '/user/signing-requests/';
    const config = getAxiosConfig(token, 'json');
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  }
);

export interface DigitalIdentityProps {
  nin: number;
  idp: string;
}

interface SignatureRequestsState {
  data: object[];
  error: string | null;
  signaturesFetched: boolean | null;
  digitalIdentity: DigitalIdentityProps | null;
}

const initialState: SignatureRequestsState = {
  data: [],
  error: null,
  signaturesFetched: null,
  digitalIdentity: null,
};

export const signaturesSlice = createSlice({
  name: 'signatures',
  initialState,
  reducers: {
    setDigitalIdentity: (state, action) => {
      state.digitalIdentity = action.payload;
    },
    clearSignaturesError: state => {
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSignatureRequests.pending, (state, action) => {
        // console.log('SIGNATURES LOADING');
        state.signaturesFetched = false;
      })
      .addCase(fetchSignatureRequests.fulfilled, (state, action) => {
        // console.log('SIGNATURES FULFILLED');
        state.data = action.payload;
        state.signaturesFetched = true;
      })
      .addCase(fetchSignatureRequests.rejected, (state, action) => {
        // console.log('SIGNATURES REJECTED', action);
        state.signaturesFetched = true;
        state.error = !!action.error.message
          ? `Fetch eSign Requests: ${action.error.message}`
          : null;
      });
  },
});

export const {
  clearSignaturesError,
  setDigitalIdentity,
} = signaturesSlice.actions;

export const selectSignatures = (state: RootState) => state.signatures.data;
export const selectSignatureError = (state: RootState) =>
  state.signatures.error;
export const selectSignaturesFetched = (state: RootState) =>
  state.signatures.signaturesFetched;
export const selectDigitalIdentity = (state: RootState) =>
  state.signatures.digitalIdentity;

export default signaturesSlice.reducer;
