import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslate } from '../../../resources/useTranslate';

export interface HomePublicProps {
  loginPath: string;
}

const HomePublic = ({ loginPath }: HomePublicProps) => {
  const ns = 'construo.homepage';
  const translations = {
    construoFinance: useTranslate(`${ns}.construoFinance`),
    customerPortal: useTranslate(`${ns}.customerPortal`),
    userLogoutMessage: useTranslate(`${ns}.userLogoutMessage`),
    login: useTranslate(`${ns}.login`),
  };
  const urlString = window.location.href;
  const url = new URL(urlString);
  const esignStatus = url.searchParams.get('esign');
  if (!!esignStatus) {
    window.location.replace(`/report-page?esign=${esignStatus}`);
  }
  return (
    <>
      {!esignStatus && (
        <div className='public main-section'>
          <div className='container'>
            {!!translations.construoFinance && (
              <p>{translations.construoFinance}</p>
            )}
            {!!translations.customerPortal && (
              <p>{translations.customerPortal}</p>
            )}
            <h1>{translations.userLogoutMessage}</h1>
            <NavLink className='k-button k-primary' to={loginPath}>
              {translations.login}
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePublic;
