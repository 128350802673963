import React from 'react';
import envVars from '../../resources/envVars';
import { useTranslate } from '../../resources/useTranslate';
import LoginMenu from './LoginMenu';
import Navigation, { NavigationPropTypes } from './Navigation';
import LanguageSwitcher from '../common/LanguageSwitcher';
import { UserState } from '../api-authorization/userSlice';

import './GlobalMenu.scss';

export type GlobalMenuLabelTypes = {
  close?: string;
  login?: string;
  logout?: string;
  languageSwitcherLabel?: string;
  english?: string;
  norwegian?: string;
  finnish?: string;
  home?: string;
  documents?: string;
  messages?: string;
};

export interface GlobalMenuProps {
  isMultiLanguageProp: boolean;
  userProps: UserState;
  labels: GlobalMenuLabelTypes;
  navigationProps: NavigationPropTypes;
  onHideMenu: () => void;
}

const GlobalMenu = ({
  isMultiLanguageProp,
  userProps,
  labels,
  navigationProps,
  onHideMenu,
}: GlobalMenuProps) => {
  const isMultiLanguage = !!envVars.LANGUAGES
    ? envVars.LANGUAGES?.length > 1
    : false;

  isMultiLanguageProp =
    isMultiLanguageProp === undefined ? isMultiLanguage : isMultiLanguageProp;

  const translation = {
    close: useTranslate('construo.menu.close'),
  };

  const closeString = labels === undefined ? translation.close : labels.close;

  return (
    <>
      <div className='global-menu'>
        <button type='button' className='btn-close' onClick={onHideMenu}>
          <span className='sr-only'>{closeString}</span>
        </button>

        <LoginMenu
          onHideMenu={onHideMenu}
          userProps={userProps}
          labels={labels}
        />

        <Navigation labels={labels} navigationProps={navigationProps} />

        {isMultiLanguageProp && <LanguageSwitcher labels={labels} />}
      </div>
    </>
  );
};

export default GlobalMenu;
