import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  setVisibilityMenu,
  VisibilityMenu,
} from '../../app/slices/visibilityMenuSlice';
import {
  setLanguage,
  selectLanguage,
  Languages,
} from './languageSwitcherSlice';
import envVars from '../../resources/envVars';
import { GlobalMenuLabelTypes } from '../layout/GlobalMenu';

import './LanguageSwitcher.scss';

interface LanguageSwitcherProps {
  labels: GlobalMenuLabelTypes;
}

const LanguageSwitcher = ({ labels }: LanguageSwitcherProps) => {
  const configLangKeys = envVars.LANGUAGES;

  const supportedLanguages = [
    {
      language: labels.english,
      id: 1,
      languageId: 'en-US',
      label: 'English',
    },
    {
      language: labels.finnish,
      id: 2,
      languageId: 'fi',
      label: 'Finnish',
    },
    {
      language: labels.norwegian,
      id: 3,
      languageId: 'nb',
      label: 'Norwegian',
    },
  ];

  type langProps = {
    language: string;
    id: number;
    languageId: string;
    label: string;
  };

  let filteredLangs: langProps[] = [];

  supportedLanguages.forEach((slang: any, index) => {
    configLangKeys?.forEach((key: string) => {
      if (slang.languageId === key) filteredLangs.push(slang);
    });
  });

  const languageNames = filteredLangs?.map((language: any) => {
    return language.language;
  });

  const dispatch = useDispatch();

  const valueRender = (element: any, value: string) => {
    if (!value) {
      return element;
    }
    const children = [
      <i key={1} className='fal fa-globe' />,
      <span key={2}>{element.props.children}</span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  const language = useSelector(selectLanguage);
  let languageName;
  languageName = filteredLangs?.filter(
    (languageItem: any) => languageItem.languageId === language
  )[0].language;

  const onChange = (event: DropDownListChangeEvent) => {
    const lang: langProps = event.target.value;
    const language = filteredLangs.filter(
      (item: any) => item.language === lang
    );
    let selectedLanguage = Languages.EN;
    switch (language[0].label) {
      case 'English':
        selectedLanguage = Languages.EN;
        break;
      case 'Norwegian':
        selectedLanguage = Languages.NB;
        break;
      case 'Finnish':
        selectedLanguage = Languages.FI;
        break;
      default:
      // code
    }
    dispatch(setLanguage(selectedLanguage));
    dispatch(setVisibilityMenu(VisibilityMenu.Hide));
  };

  const languageSwitcherRef: any = useRef(null);

  return (
    <div className='choose-language' ref={languageSwitcherRef}>
      <DropDownList
        label={labels.languageSwitcherLabel}
        data={languageNames}
        value={languageName}
        valueRender={valueRender}
        onChange={onChange}
        popupSettings={{
          appendTo: languageSwitcherRef?.current,
        }}
      />
    </div>
  );
};

export default LanguageSwitcher;
