import React, { useRef } from 'react';
import { Form } from '@formio/react';
import { default as flatpickrMessages } from 'flatpickr/dist/l10n';
import {
  daFormioMessages,
  enFormioMessages,
  fiFormioMessages,
  nbFormioMessages,
  nnFormioMessages,
  noFormioMessages,
  svFormioMessages,
} from '../../messages/formio';
import envVars from '../../resources/envVars';

export interface FormioFormProps {
  form: any;
  submission: any;
  onSubmit: any;
  onChange?: any;
  languageCode?: any;
}

const FormioForm = ({
  form,
  submission,
  onSubmit,
  onChange,
  languageCode,
}: FormioFormProps) => {
  const translations: any = {
    DA: {
      formio: { DA: daFormioMessages },
      flatpickr: flatpickrMessages.da,
    },
    en: {
      formio: { en: enFormioMessages },
      flatpickr: flatpickrMessages.en,
    },
    FI: {
      formio: { FI: fiFormioMessages },
      flatpickr: flatpickrMessages.fi,
    },
    nb: {
      formio: { nb: nbFormioMessages },
      flatpickr: flatpickrMessages.no,
    },
    nn: {
      formio: { nn: nnFormioMessages },
      flatpickr: flatpickrMessages.no,
    },
    no: {
      formio: { no: noFormioMessages },
      flatpickr: flatpickrMessages.no,
    },
    SV: {
      formio: { SV: svFormioMessages },
      flatpickr: flatpickrMessages.sv,
    },
  };

  const API_BASE_URI = envVars.API_BASE_URI;
  const formioFormWrapperRef = useRef<null | HTMLDivElement>(null);

  const addComponent = (component: any) => {
    if (component.type === 'datetime') {
      component.customOptions = {
        locale: translations[languageCode].flatpickr,
      };
    }
    if (component.type === 'file') {
      const url = API_BASE_URI + component.url;
      component.url = url;
    }
    return component;
  };

  const onPrevPage = () => {
    formioFormWrapperRef.current?.scrollIntoView();
  };

  const onNextPage = () => {
    formioFormWrapperRef.current?.scrollIntoView();
  };

  const onError = (errors: any) => {
    errors[0].messages[0].self.element.scrollIntoView();
  };

  return (
    <div className='formio-form-wrapper' ref={formioFormWrapperRef}>
      <Form
        form={form}
        submission={submission}
        onSubmit={onSubmit}
        onChange={onChange}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        onError={onError}
        options={{
          language: languageCode,
          i18n: translations[languageCode]?.formio,
          hooks: {
            addComponent: addComponent,
          },
        }}
      />
    </div>
  );
};

export default FormioForm;
