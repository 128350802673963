import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import jwtDecode from 'jwt-decode';

import authService from './components/api-authorization/AuthorizeService';
import { AuthorizeRoute } from './components/api-authorization/AuthorizeRoute';
import { ApiAuthorizationRoutes } from './components/api-authorization/ApiAuthorizationRoutes';
import {
  ApplicationName,
  ApplicationPaths,
} from './components/api-authorization/ApiAuthorizationConstants';

import Layout from './components/layout/Layout';
import Home from './components/pages/home/Home';

import Documents from './components/pages/documents/Documents';
import Document from './components/pages/document/Document';

import {
  selectUser,
  setUser,
  removeUser,
  populateUser,
} from './components/api-authorization/userSlice';
import { selectLanguage } from './components/common/languageSwitcherSlice';
import OverallLoader from './components/common/OverallLoader';
import { selectLoading, Loading } from './app/slices/loadingSlice';
import {
  selectMessagesLoaded,
  selectStylesLoaded,
} from './app/slices/settingsSlice';
import { setDigitalIdentity } from './components/signatures/signaturesSlice';

import IdleTimerContainer from './components/common/IdleTimerContainer';

import {
  IntlProvider,
  load,
  LocalizationProvider,
} from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
// English language
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from 'cldr-dates-full/main/en/dateFields.json';
// Norwegin language
import nbNumbers from 'cldr-numbers-full/main/nb/numbers.json';
import nbLocalCurrency from 'cldr-numbers-full/main/nb/currencies.json';
import nbCaGregorian from 'cldr-dates-full/main/nb/ca-gregorian.json';
import nbDateFields from 'cldr-dates-full/main/nb/dateFields.json';
// Finnish language
import fiNumbers from 'cldr-numbers-full/main/fi/numbers.json';
import fiLocalCurrency from 'cldr-numbers-full/main/fi/currencies.json';
import fiCaGregorian from 'cldr-dates-full/main/fi/ca-gregorian.json';
import fiDateFields from 'cldr-dates-full/main/fi/dateFields.json';

import InboundForms from './components/pages/public-forms/InboundForms';
import PublicForm from './components/pages/public-forms/PublicForm';
import OutboundForms from './components/pages/submission-forms/OutboundForms';
import SubmissionForm from './components/pages/submission-forms/SubmissionForm';
import UserSubmissionForm from './components/submissions/UserSubmissionForm';
import Workflow from './components/workflows/Workflow';
import TaskDetails from './components/workflows/taskDetails/TaskDetails';
import ConversationsList from './components/pages/conversations/ConversationsList';
import Conversation from './components/pages/conversations/Conversation';
import NewMessage from './components/pages/conversations/NewMessage';
import ReportPage from './components/pages/ReportPage';
import NoResourceMatch from './components/pages/no-resource-match/NoResourceMatch';
import NoMatch from './components/pages/no-match/NoMatch';
import { useTranslate } from './resources/useTranslate';
import envVars from './resources/envVars';

load(
  likelySubtags,
  currencyData,
  weekData,
  // en-US
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
  // norwegian
  nbNumbers,
  nbLocalCurrency,
  nbCaGregorian,
  nbDateFields,
  // finnish
  fiNumbers,
  fiLocalCurrency,
  fiCaGregorian,
  fiDateFields
);

const App = () => {
  const loading = useSelector(selectLoading);
  const isLoading = loading === Loading.Show ? true : false;
  const dispatch = useDispatch();
  let languageId = 'en-US'; // Default Language
  languageId = useSelector(selectLanguage);

  const userState = useSelector(selectUser);
  const populated = userState.populated;
  const isUserLogged = userState.authenticated;
  const messagesLoaded = useSelector(selectMessagesLoaded);
  const stylesLoaded = useSelector(selectStylesLoaded);

  const isDocumentListFeature = envVars.FEATURES?.includes('documentList');
  const isFormSharingFeature = envVars.FEATURES?.includes('formRequests');
  const isWorkflowsFeature = envVars.FEATURES?.includes('workflows');
  const isConversationsFeature = envVars.FEATURES?.includes('conversations');

  useEffect(() => {
    const checkDigitalIdentity = async () => {
      const accessToken = await authService.getAccessToken();
      let tokenDecoded: any;
      // Check user NIN and idp presence by decoding access token, IF user is set/authenticated!
      if (!!userState.authenticated) {
        tokenDecoded =
          typeof accessToken === 'string' && jwtDecode(accessToken);
        dispatch(
          setDigitalIdentity({
            nin: tokenDecoded?.nin,
            idp: tokenDecoded?.idp,
          })
        );
      }
    };
    checkDigitalIdentity();
  }, [dispatch, userState.authenticated]);

  const translations = {
    settingsError: useTranslate(
      'construo.errors.settingsError',
      ApplicationName
    ),
  };

  useEffect(() => {
    async function fetchUser() {
      const userProfile = await authService.getUser();
      if (userProfile && userProfile.name) {
        const name = `${userProfile.given_name} ${userProfile.family_name}`;
        const userSub = userProfile.sub;
        dispatch(setUser({ username: name, userSub: userSub }));
      }
      dispatch(populateUser());
    }

    let subscriptionId = 0;

    const populateAuthenticationState = async (): Promise<void> => {
      const userProfile = await authService.getUser();
      if (userProfile && userProfile.name) {
        const name = `${userProfile.given_name} ${userProfile.family_name}`;
        dispatch(setUser({ username: name }));
      } else {
        dispatch(removeUser());
      }
      dispatch(populateUser());
    };

    subscriptionId = authService.subscribe(() => populateAuthenticationState());

    if (!populated) {
      fetchUser();
    }

    return function cleanup() {
      authService.unsubscribe(subscriptionId);
    };
  }, [dispatch, populated, translations.settingsError]);

  // RENDERING PART
  if (!populated || !messagesLoaded || !stylesLoaded) {
    return <OverallLoader message={false} />;
  } else {
    return (
      <>
        <Helmet>
          <html lang={languageId} />
        </Helmet>

        <LocalizationProvider language={languageId}>
          <IntlProvider locale={languageId}>
            {isUserLogged && <IdleTimerContainer />}

            <Layout>
              <Switch>
                <Route exact path='/' component={Home} />

                {isDocumentListFeature && (
                  <AuthorizeRoute
                    exact
                    path='/documents'
                    component={Documents}
                  />
                )}

                <Route exact path='/report-page' component={ReportPage} />

                {process.env.NODE_ENV !== 'production' && (
                  <Route exact path='/forms' component={InboundForms} />
                )}

                {process.env.NODE_ENV !== 'production' && (
                  <Route exact path='/submissions' component={OutboundForms} />
                )}

                <Route exact path='/forms/:guid' component={PublicForm} />

                <Route
                  exact
                  path='/submissions/:token'
                  component={SubmissionForm}
                />

                {isFormSharingFeature && (
                  <AuthorizeRoute
                    exact
                    path='/user/submissions/:guid'
                    component={UserSubmissionForm}
                  />
                )}

                {isWorkflowsFeature && (
                  <AuthorizeRoute
                    exact
                    path='/user/workflows/:id'
                    component={Workflow}
                  />
                )}

                {isWorkflowsFeature && (
                  <AuthorizeRoute
                    exact
                    path='/user/tasks/:id'
                    component={TaskDetails}
                  />
                )}

                {isConversationsFeature && (
                  <AuthorizeRoute
                    exact
                    path='/conversations'
                    component={ConversationsList}
                  />
                )}

                {isConversationsFeature && (
                  <AuthorizeRoute
                    exact
                    path='/conversations/new-message'
                    component={NewMessage}
                  />
                )}

                {isConversationsFeature && (
                  <AuthorizeRoute
                    exact
                    path='/conversations/:id'
                    component={Conversation}
                  />
                )}

                <AuthorizeRoute
                  exact
                  path='/documents/download/:guid'
                  component={Document}
                />

                <Route
                  path={ApplicationPaths.ApiAuthorizationPrefix}
                  component={ApiAuthorizationRoutes}
                />

                <Route
                  exact
                  path='/no-resource'
                  render={({ location }) => {
                    return <NoResourceMatch location={location} />;
                  }}
                />

                <Route
                  path='*'
                  render={({ location }) => {
                    return <NoMatch location={location} />;
                  }}
                />
              </Switch>
            </Layout>

            {isLoading && <OverallLoader transparent={true} />}
          </IntlProvider>
        </LocalizationProvider>
      </>
    );
  }
};

export default App;
