const EnvVars = () => {
  const CUSTOMER_PORTAL_URL: string | null = !!process.env
    .REACT_APP_CUSTOMER_PORTAL_URL
    ? process.env.REACT_APP_CUSTOMER_PORTAL_URL
    : null;

  const GATEWAY_API_URL: string | null = !!process.env.REACT_APP_GATEWAY_API_URL
    ? process.env.REACT_APP_GATEWAY_API_URL
    : null;

  const API_BASE_URI: string | null = !!process.env.REACT_APP_API_BASE_URI
    ? process.env.REACT_APP_API_BASE_URI
    : null;

  const GATEWAY_HUB_URL: string | null = !!process.env.REACT_APP_GATEWAY_HUB_URL
    ? process.env.REACT_APP_GATEWAY_HUB_URL
    : null;

  const IDLE_TIME: number | null = !!process.env.REACT_APP_IDLE_TIME
    ? Number(process.env.REACT_APP_IDLE_TIME)
    : null;

  const IDLE_DIALOG_TIME: number | null = !!process.env
    .REACT_APP_IDLE_DIALOG_TIME
    ? Number(process.env.REACT_APP_IDLE_DIALOG_TIME)
    : null;

  const RECENT_DOC_NO: number | null = !!process.env.REACT_APP_RECENT_DOC_NO
    ? Number(process.env.REACT_APP_RECENT_DOC_NO)
    : null;

  const ITEMS_PER_DOCS_PAGE: number | null = !!process.env
    .REACT_APP_ITEMS_PER_DOCS_PAGE
    ? Number(process.env.REACT_APP_ITEMS_PER_DOCS_PAGE)
    : null;

  const SUBMISSION_AUTO_LOCK_TIME: number | null = !!process.env
    .REACT_APP_SUBMISSION_AUTO_LOCK_TIME
    ? Number(process.env.REACT_APP_SUBMISSION_AUTO_LOCK_TIME)
    : null;

  const SUBMISSION_AUTO_SAVE_TIME: number | null = !!process.env
    .REACT_APP_SUBMISSION_AUTO_SAVE_TIME
    ? Number(process.env.REACT_APP_SUBMISSION_AUTO_SAVE_TIME)
    : null;

  const CUSTOM_FOLDER_NAME: string | null = !!process.env
    .REACT_APP_CUSTOM_FOLDER_NAME
    ? process.env.REACT_APP_CUSTOM_FOLDER_NAME
    : null;

  const CUSTOMER_NAME: string | null = !!process.env.REACT_APP_CUSTOMER_NAME
    ? process.env.REACT_APP_CUSTOMER_NAME
    : null;

  const LANGUAGES: string[] | null = !!process.env.REACT_APP_LANGUAGES
    ? process.env.REACT_APP_LANGUAGES.split(' ')
    : null;

  const FEATURES: string[] | null = !!process.env.REACT_APP_FEATURES
    ? process.env.REACT_APP_FEATURES.split(' ')
    : null;

  const AUTHENTICATION_AUTHORITY: string | null = !!process.env
    .REACT_APP_AUTHENTICATION_AUTHORITY
    ? process.env.REACT_APP_AUTHENTICATION_AUTHORITY
    : null;

  const AUTHENTICATION_AUTOMATIC_SILENT_RENEW: boolean | null = !!process.env
    .REACT_APP_AUTHENTICATION_AUTOMATIC_SILENT_RENEW
    ? Boolean(process.env.REACT_APP_AUTHENTICATION_AUTOMATIC_SILENT_RENEW)
    : null;

  const AUTHENTICATION_CLIENT_ID: string | null = !!process.env
    .REACT_APP_AUTHENTICATION_CLIENT_ID
    ? process.env.REACT_APP_AUTHENTICATION_CLIENT_ID
    : null;

  const AUTHENTICATION_INCLUDE_ID_TOKEN_IN_SILENT_RENEW:
    | boolean
    | null = !!process.env
    .REACT_APP_AUTHENTICATION_INCLUDE_ID_TOKEN_IN_SILENT_RENEW
    ? Boolean(
        process.env.REACT_APP_AUTHENTICATION_INCLUDE_ID_TOKEN_IN_SILENT_RENEW
      )
    : null;

  const AUTHENTICATION_POST_LOGOUT_REDIRECT_URI: string | null = !!process.env
    .REACT_APP_AUTHENTICATION_POST_LOGOUT_REDIRECT_URI
    ? process.env.REACT_APP_AUTHENTICATION_POST_LOGOUT_REDIRECT_URI
    : null;

  const AUTHENTICATION_REDIRECT_URI: string | null = !!process.env
    .REACT_APP_AUTHENTICATION_REDIRECT_URI
    ? process.env.REACT_APP_AUTHENTICATION_REDIRECT_URI
    : null;

  const AUTHENTICATION_RESPONSE_TYPE: string | null = !!process.env
    .REACT_APP_AUTHENTICATION_RESPONSE_TYPE
    ? process.env.REACT_APP_AUTHENTICATION_RESPONSE_TYPE
    : null;

  const AUTHENTICATION_SCOPE: string | null = !!process.env
    .REACT_APP_AUTHENTICATION_SCOPE
    ? process.env.REACT_APP_AUTHENTICATION_SCOPE
    : null;

  const AUTHENTICATION_SILENT_REDIRECT_URI: string | null = !!process.env
    .REACT_APP_AUTHENTICATION_SILENT_REDIRECT_URI
    ? process.env.REACT_APP_AUTHENTICATION_SILENT_REDIRECT_URI
    : null;

  return {
    CUSTOMER_PORTAL_URL,
    GATEWAY_API_URL,
    API_BASE_URI,
    GATEWAY_HUB_URL,
    IDLE_TIME,
    IDLE_DIALOG_TIME,
    RECENT_DOC_NO,
    ITEMS_PER_DOCS_PAGE,
    SUBMISSION_AUTO_LOCK_TIME,
    SUBMISSION_AUTO_SAVE_TIME,
    CUSTOM_FOLDER_NAME,
    CUSTOMER_NAME,
    LANGUAGES,
    FEATURES,
    AUTHENTICATION_AUTHORITY,
    AUTHENTICATION_AUTOMATIC_SILENT_RENEW,
    AUTHENTICATION_CLIENT_ID,
    AUTHENTICATION_INCLUDE_ID_TOKEN_IN_SILENT_RENEW,
    AUTHENTICATION_POST_LOGOUT_REDIRECT_URI,
    AUTHENTICATION_REDIRECT_URI,
    AUTHENTICATION_RESPONSE_TYPE,
    AUTHENTICATION_SCOPE,
    AUTHENTICATION_SILENT_REDIRECT_URI,
  };
};

const envVars = EnvVars();

export default envVars;
