import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslate } from '../../resources/useTranslate';
import './TempResponse.scss';

type TempResponseProps = {
  chooseTemp: () => void;
  chooseLatest: () => void;
};

const TempResponse = ({ chooseTemp, chooseLatest }: TempResponseProps) => {
  const ns = 'construo.forms';
  const translations = {
    chooseFormSubmissionTitle: useTranslate(`${ns}.chooseFormSubmissionTitle`),
    chooseFormSubmissionP1Text: useTranslate(
      `${ns}.chooseFormSubmissionP1Text`
    ),
    chooseFormSubmissionP2Text: useTranslate(
      `${ns}.chooseFormSubmissionP2Text`
    ),
    tempButtonLabel: useTranslate(`${ns}.tempButtonLabel`),
    savedButtonLabel: useTranslate(`${ns}.savedButtonLabel`),
  };

  return (
    <div className='temp-response'>
      <h1>{translations.chooseFormSubmissionTitle}</h1>
      <p>{translations.chooseFormSubmissionP1Text}</p>
      <p>{translations.chooseFormSubmissionP2Text}</p>
      <div className='action-btns'>
        <Button className='k-secondary' onClick={chooseTemp}>
          {translations.tempButtonLabel}
        </Button>
        <Button className='k-primary' onClick={chooseLatest}>
          {translations.savedButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default TempResponse;
