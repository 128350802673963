import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, useHistory } from 'react-router-dom';
import { useTranslate } from '../../resources/useTranslate';
import envVars from '../../resources/envVars';
import HelmetTitle from '../common/HelmetTitle';
import {
  fetchWorkflows,
  selectWorkflows,
  selectWorkflowsError,
  selectWorkflowsFetched,
} from './workflowSlice';
import {
  fetchConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessagesFetched,
} from './taskDetails/messagesSlice';
import Task from './Task';

export interface WorkflowRouteParams {
  id: string;
}

export interface TaskProps {
  id: string;
  displayName: string;
  openUserActions: boolean;
  shortDescription: string;
  sortOrder: number;
  status: string;
  progress: {
    total: number;
    pendingActionsCount: number;
    doneActionsCount: number;
    errorActionsCount: number;
    skippedActionsCount: number;
    notStartedActionsCount: number;
  };
}

export interface WorkflowsProps {
  id: string;
  displayName: string;
  tasks: TaskProps[] | null;
}

export interface WorkflowProps {
  match: match<WorkflowRouteParams>;
  workflows?: WorkflowsProps[] | null;
}

const Workflow = ({ match, workflows = null }: WorkflowProps) => {
  const dispatch = useDispatch();
  const navigate = useHistory();

  const id = match.params.id;

  const ns = 'construo.workflows';
  const translations = {
    noWorkflowName: useTranslate(`${ns}.noWorkflowName`),
    workflowDescription: useTranslate(`${ns}.workflowDescription`),
    reviewingSubmission: useTranslate(`${ns}.reviewingSubmission`),
    unreadMessages: useTranslate(`${ns}.unreadMessages`),
    completedSubmission: useTranslate(`${ns}.completedSubmission`),
    workflowIdNoMatch: useTranslate(`${ns}.workflowIdNoMatch`),
  };

  const API_BASE_URI = envVars.API_BASE_URI || '';
  const unreadMessagesUrl = `${API_BASE_URI}/user/conversations/unreadactivities?page=1&pageSize=99`;

  const reduxWorkflows = useSelector(selectWorkflows);

  if (workflows === null) {
    workflows = reduxWorkflows;
  }

  const workflowMatch = workflows?.filter((wf: any) => wf.id === id)[0];
  const workflow = workflows !== null ? workflowMatch : workflows;

  const workflowsFetched = useSelector(selectWorkflowsFetched);

  const workflowsFetchedError = useSelector(selectWorkflowsError);
  const workflowMatchError = !!workflowMatch
    ? null
    : translations.workflowIdNoMatch;

  const workflowsError = workflowsFetchedError || workflowMatchError;

  useEffect(() => {
    if (workflowsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchWorkflows(dataObject));
    }
  }, [dispatch, API_BASE_URI, workflowsFetched]);

  const conversations = useSelector(selectConversationsWithUnreadMessages);
  const conversationsFetched = useSelector(
    selectConversationsWithUnreadMessagesFetched
  );

  useEffect(() => {
    if (conversationsFetched === null) {
      const dataObject = {
        url: unreadMessagesUrl,
      };
      dispatch(fetchConversationsWithUnreadMessages(dataObject));
    }
  }, [dispatch, conversationsFetched, unreadMessagesUrl]);

  const getConversationsUnreadMessages = (taskId: any) => {
    const taskConversation = conversations?.filter(
      (conversation: any) => conversation.resourceName === taskId
    )[0];
    const taskConversationUnreadMessages =
      taskConversation?.numberOfUnreadActivities;
    return taskConversationUnreadMessages;
  };

  const getTaskProgressStatus = (taskId: string) => {
    const task = workflow?.tasks?.filter((task: any) => task.id === taskId)[0];
    const taskOpenUserActions = task?.openUserActions;
    const taskProgress = task?.progress;
    const taskTotal = taskProgress?.total;
    const taskDone = taskProgress?.doneActionsCount;

    const statusNumber =
      !!taskDone && !!taskTotal && Math.round((taskDone / taskTotal) * 10) / 10;

    let statusIconClassName:
      | 'hidden-icon'
      | 'quarter-circle'
      | 'half-circle'
      | 'three-quarters-circle'
      | 'check-mark' = 'hidden-icon';
    let subtitle = task?.shortDescription;
    let subtitleClassName = 'subtitle-short-description';

    if (taskOpenUserActions) {
      statusIconClassName = 'hidden-icon';
    } else if (statusNumber < 0.5) {
      statusIconClassName = 'quarter-circle';
      subtitle = translations.reviewingSubmission;
      subtitleClassName = 'subtitle-system-text';
    } else if (statusNumber < 0.75) {
      statusIconClassName = 'half-circle';
      subtitle = translations.reviewingSubmission;
      subtitleClassName = 'subtitle-system-text';
    } else if (statusNumber < 1) {
      statusIconClassName = 'three-quarters-circle';
      subtitle = translations.reviewingSubmission;
      subtitleClassName = 'subtitle-system-text';
    } else if (statusNumber === 1) {
      statusIconClassName = 'check-mark';
      subtitle = translations.completedSubmission;
      subtitleClassName = 'subtitle-system-text';
    }

    return {
      statusIconClassName: statusIconClassName,
      subtitle: subtitle,
      subtitleClassName: subtitleClassName,
    };
  };

  // Tasks List
  const taskList = workflow?.tasks?.map((task: any) => {
    const taskId = task.id;
    const conversationsUnreadMessages: number = getConversationsUnreadMessages(
      taskId
    );

    const taskStatus: any = getTaskProgressStatus(taskId);

    return (
      <Task
        key={taskId}
        taskId={taskId}
        taskName={task.displayName}
        taskStatus={taskStatus}
        conversationsUnreadMessages={conversationsUnreadMessages}
        unreadMessage={translations.unreadMessages}
      />
    );
  });

  // In case of workflows fetching error (or workflow GUID matching error) you're redirected to "No Resource Page"
  const [showError, setShowError] = useState<boolean>(false);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setShowError(true);
    }
  }, []);
  useEffect(() => {
    if (workflowsFetched && workflow === undefined) {
      navigate.push(
        `/no-resource?type=workflow&guid=${id}${
          showError ? `&error=${workflowsError}` : ``
        }`
      );
    }
  }, [navigate, workflow, workflowsFetched, id, showError, workflowsError]);

  return (
    <>
      {!!workflow?.displayName ? (
        <HelmetTitle title={workflow.displayName} />
      ) : (
        <HelmetTitle title={translations.noWorkflowName} />
      )}

      {!!workflow && (
        <section className='workflow-details main-section'>
          <div className='container'>
            {!!workflow?.displayName && <h1>{workflow.displayName}</h1>}

            <p>{translations.workflowDescription}</p>

            {!!workflow?.tasks && workflow?.tasks?.length > 0 && (
              <div className='workflow-tasks k-card-list'>{taskList}</div>
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default Workflow;
