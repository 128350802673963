import React from 'react';
import { useTranslate } from '../../resources/useTranslate';

const FormSealed = () => {
  const ns = 'construo.forms';
  const translations = {
    formSealedHeading: useTranslate(`${ns}.formSealedHeading`),
    formSealedText: useTranslate(`${ns}.formSealedText`),
  };
  return (
    <>
      <h1>{translations.formSealedHeading}</h1>
      <p>{translations.formSealedText}</p>
    </>
  );
};

export default FormSealed;
