import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { formattedDate } from '../../../helpers/DateHelper';
import envVars from '../../../resources/envVars';
import HelmetTitle from '../../common/HelmetTitle';
import { useTranslate } from '../../../resources/useTranslate';
import { selectLanguage } from '../../common/languageSwitcherSlice';
import {
  fetchConversationsList,
  resetUnreadConversations,
  selectConversationsList,
  selectConversationsListFetched,
} from './conversationsSlice';
import ConversationsListSkeleton from './ConversationsListSkeleton';

import './ConversationsList.scss';

const ConversationsList = () => {
  const dispatch = useDispatch();

  const ns = 'construo.conversations';
  const translations = {
    title: useTranslate(`${ns}.title`),
    newMessage: useTranslate(`${ns}.newMessage`),
  };

  const API_BASE_URI = envVars.API_BASE_URI;
  const languageId = useSelector(selectLanguage);

  const conversationsList = useSelector(selectConversationsList);
  const conversationsListFetched = useSelector(selectConversationsListFetched);

  useEffect(() => {
    if (conversationsListFetched === null) {
      const url = `${API_BASE_URI}/user/conversations/list?sortby=lastActivityDate&SortDescending=true`;
      const dataObject = {
        url,
      };
      dispatch(resetUnreadConversations()); // reset number of unread conversations so app can refresh and see any changes should be made to number inside navigation item
      dispatch(fetchConversationsList(dataObject));
    }
  }, [dispatch, API_BASE_URI, conversationsListFetched]);

  const conversations = conversationsList.map((conversation: any) => {
    const conversationGuid = conversation.conversationGuid;
    const conversationTypeId = conversation.conversationTypeId;
    const resourceGuid = conversation.resourceName;
    const conversationTitle = conversation.friendlyName;
    const unreadMessages = conversation.myUnreadMessages;

    const lastActivityDate = conversation.lastActivityDate;
    let date = formattedDate(lastActivityDate, languageId);
    if (languageId === 'en-US') {
      date = formattedDate(lastActivityDate, 'nb');
    }
    const hasNewMessages = unreadMessages > 0;
    const taskId = resourceGuid;

    let goToUrl = '';
    const goToTaskUrl = `/user/tasks/${taskId}?conversationGuid=${conversationGuid}`; // redirect to task details page with conversation
    const goToConversationUrl = `/conversations/${conversationGuid}`; // redirect to dedicated conversation page

    if (conversationTypeId === 1) {
      goToUrl = goToTaskUrl;
    } else {
      goToUrl = goToConversationUrl;
    }

    // let conversationType;
    // switch (conversationTypeId) {
    //   case 1:
    //     // resource/task
    //     conversationType = 'Resource';
    //     break;
    //   case 2:
    //     // topic
    //     conversationType = 'Topic';
    //     break;
    //   case 3:
    //     // direct
    //     conversationType = 'Direct';
    //     break;
    //   default:
    //   // code
    // }

    return (
      <div className='list-item' key={conversationGuid}>
        <div className='list-item-conversation'>
          <div className='list-item-conversation-name'>
            <NavLink
              className={hasNewMessages ? 'has-new-message h5' : 'h5'}
              to={goToUrl}
            >
              {conversationTitle}
            </NavLink>
          </div>
          {/* <span>{conversationType}</span> */}
          <div className='list-item-date'>{date}</div>
        </div>
      </div>
    );
  });

  const ConversationsListContainer = () => {
    return (
      <div className='k-listview k-widget k-d-flex conversations-list'>
        <div className='k-listview-content'>{conversations}</div>
      </div>
    );
  };

  return (
    <>
      <HelmetTitle title={translations.title} />
      <section className='main-section conversations-list'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <header>
                <h1>{translations.title}</h1>
                <NavLink className='k-button' to='/conversations/new-message'>
                  {translations.newMessage}
                  <span role='presentation' className='fal fa-edit'></span>
                </NavLink>
              </header>

              {conversationsListFetched ? (
                <ConversationsListContainer />
              ) : (
                <ConversationsListSkeleton />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConversationsList;
