import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axios from 'axios';
import { getAxiosConfig } from '../../helpers/utils';
import authService from '../api-authorization/AuthorizeService';

// Gateway API version - getting user specific Workflow using WorkflowGuid from Gateway API
export const fetchWorkflows = createAsyncThunk(
  'fetchWorkflows',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const baseUrl = dataObject.baseUrl;
    const url = `${baseUrl}/user/workflows`;
    const config = getAxiosConfig(token, 'json');
    const response = await axios.get(url, config);
    const data = response.data;
    return data;
  }
);

interface WorkflowState {
  data: any;
  workflowsFetched: boolean | null;
  workflowsError?: string | null;
}

const initialState: WorkflowState = {
  data: [],
  workflowsFetched: null,
};

export const workflowsSlice = createSlice({
  name: 'workflows',
  initialState,
  reducers: {
    resetWorkflowData: state => {
      state.data = [];
      state.workflowsFetched = null;
    },
    resetWorkflowError: state => {
      state.workflowsError = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWorkflows.pending, (state, action) => {
        // console.log('WORKFLOWS LOADING');
        state.workflowsFetched = false;
      })
      .addCase(fetchWorkflows.fulfilled, (state, action) => {
        // console.log('WORKFLOWS FULFILLED');
        state.data = action.payload;
        state.workflowsFetched = true;
      })
      .addCase(fetchWorkflows.rejected, (state, action) => {
        state.workflowsFetched = true;
        // console.log('WORKFLOWS REJECTED', action);
        state.workflowsError = `Fetch Workflows Requests: ${action.error.message}`;
      });
  },
});

export const { resetWorkflowData, resetWorkflowError } = workflowsSlice.actions;

export const selectWorkflows = (state: RootState) => state.workflows.data;

export const selectWorkflowsFetched = (state: RootState) =>
  state.workflows.workflowsFetched;

export const selectWorkflowsError = (state: RootState) =>
  state.workflows.workflowsError;

export default workflowsSlice.reducer;
