import { Chat } from '@progress/kendo-react-conversational-ui';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match, useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';
import envVars from '../../../resources/envVars';
import HelmetTitle from '../../common/HelmetTitle';
import { useTranslate } from '../../../resources/useTranslate';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import {
  fetchConversation,
  resetConversation,
  resetConversationsList,
  resetUnreadConversations,
  selectConversation,
  selectConversationError,
  selectConversationFetched,
} from './conversationsSlice';

import './Conversation.scss';

export interface ConversationRouteParams {
  id: string;
}

interface ConversationProps {
  match: match<ConversationRouteParams>;
}

const Conversation = ({ match }: ConversationProps) => {
  //
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const unavailableTask = urlParams.get('unavailable');
  const isUnavailableTask = !!unavailableTask;

  const dispatch = useDispatch();
  const navigate = useHistory();
  const conversationId = match.params.id;

  const ns = 'construo.conversations';
  const translations = {
    backToInbox: useTranslate(`${ns}.backToInbox`),
    anonymousAuthorName: useTranslate(`construo.workflows.anonymousAuthorName`),
    placeholderText: useTranslate(`construo.workflows.placeholderText`),
    readReceiptsError: useTranslate(`construo.workflows.readReceiptsError`),
    warningUnavailableTask: useTranslate(`${ns}.warningUnavailableTask`),
    warningConversationStatus: useTranslate(`${ns}.warningConversationStatus`),
  };

  const baseUrl = envVars.API_BASE_URI;

  const conversation = useSelector(selectConversation);
  const conversationFetched = useSelector(selectConversationFetched);
  const conversationError = useSelector(selectConversationError);

  const conversationStatusId = conversation?.statusId;
  const isConversationClosed = !conversationStatusId
    ? false
    : conversationStatusId === 1
    ? false
    : true;

  useEffect(() => {
    if (conversationFetched === null) {
      const url = `${baseUrl}/user/conversations/${conversationId}`;
      const dataObject = {
        url,
      };
      dispatch(resetUnreadConversations()); // reset number of unread conversations so app can refresh and see any changes should be made to number inside navigation item
      dispatch(fetchConversation(dataObject));
    }
  }, [dispatch, baseUrl, conversationFetched, conversationId]);

  useEffect(() => {
    return () => {
      dispatch(resetConversation());
      dispatch(resetConversationsList());
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const addMessage = async (message: any) => {
    const url = `${baseUrl}/user/conversations/${conversationId}/activities`;
    const data = JSON.stringify({
      identifier: conversationId,
      activity: {
        content: message.text,
        activityTypeId: 2,
        contentTypeId: 1,
      },
    });
    const token = await authService.getAccessToken();
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.post(url, data, config);
      const dataResponse = response.data.data;
      dispatch(resetConversation());
      dispatch(resetUnreadConversations());
      return dataResponse;
    } catch (error) {
      // Show error message after Complete Action Event fails
      let errorResponse = error.response.data.Message;
      errorResponse = !!errorResponse ? errorResponse : error.message;
      dispatch(setAlerts({ message: errorResponse, type: 'error' }));
      // And scroll to top so that alert is visible
      window.scrollTo(0, 0);
    }
  };

  const addNewMessage = (event: any) => {
    addMessage(event.message);
  };

  const mappedParticipants = conversation?.participants?.map(
    (participant: any) => {
      return {
        id: participant.userId,
        name: participant.participantName,
        isCurrent: participant.isCurrent,
      };
    }
  );

  const currentUser = mappedParticipants?.filter(
    (participant: any) => participant.isCurrent === true
  )[0];

  const mappedMessages = conversation?.activities?.map((activity: any) => {
    const authorId = activity.userName ? activity.userId : null;
    const authorName = activity.userName
      ? activity.userName
      : translations.anonymousAuthorName;
    const timestamp = new Date(activity.dateCreated);
    const text = activity.content;
    return {
      author: {
        id: authorId,
        name: authorName,
      },
      text: text,
      timestamp: timestamp,
    };
  });

  const notCurrentUserActivities = conversation?.activities?.filter(
    (activity: any) => activity.userId !== currentUser.id
  );

  const lastActivityId = useRef(null);

  lastActivityId.current =
    !!notCurrentUserActivities &&
    notCurrentUserActivities[notCurrentUserActivities.length - 1]?.activityGuid;

  const unreadMessages = conversation.numberOfUnreadActivities;

  const [isReadReceipts, setIsReadReceipts] = useState(true);

  /**
   * 3 second after conversation is loaded trigger "Create read receipt" for last Outbound Activity (activityId) in THE Conversation (conversationId)
   * This will set all unread messages to have status "read"
   */
  useEffect(() => {
    if (!!conversationId && unreadMessages > 0 && isReadReceipts) {
      setTimeout(async () => {
        const activityId = lastActivityId.current;
        const url = `${baseUrl}/user/conversations/${conversationId}/activities/${activityId}/readReceipts`;
        const workflowId = conversation.parentId;
        const data = {
          parentId: workflowId,
        };
        const dataStringify = JSON.stringify(data);
        const token = await authService.getAccessToken();
        const config = getAxiosConfig(token, 'json');

        await axios
          .post(url, dataStringify, config)
          .then(response => response.data)
          .then(response => {
            dispatch(resetConversationsList());
            dispatch(resetUnreadConversations());
            // dispatch(resetConversation());
            setIsReadReceipts(false);
            return response;
          })
          .catch(error => {
            // Show error message after Complete Action Event fails
            const errorGenericMessage = error.message;
            const errorResponseMessage = error.response.data.Message;
            const errorMessage = !!errorResponseMessage
              ? errorResponseMessage
              : errorGenericMessage;

            dispatch(
              setAlerts({
                message: `${translations.readReceiptsError} ${errorMessage}`,
                type: 'error',
              })
            );
            // And scroll to top so that alert is visible
            window.scrollTo(0, 0);
          });
      }, 3000); // readReceipts event is delayed for 3 sec
    }
  }, [
    dispatch,
    baseUrl,
    conversationId,
    conversation.parentId,
    unreadMessages,
    isReadReceipts,
    translations.readReceiptsError,
  ]);

  // In case of conversation fetching error you're redirected to "No Resource Page"
  const [showError, setShowError] = useState<boolean>(false);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setShowError(true);
    }
  }, []);
  useEffect(() => {
    if (!!conversationFetched && !!conversationError) {
      navigate.push(
        `/no-resource?type=conversation&guid=${conversationId}${
          showError ? `&error=${conversationError}` : ``
        }`
      );
    }
  }, [
    navigate,
    conversationFetched,
    conversationId,
    showError,
    conversationError,
  ]);

  return (
    <>
      <HelmetTitle title={conversation.friendlyName} />

      <section className='main-section task-messages'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8 col-xl-9'>
              <NavLink className='back-link' to='/conversations'>
                {translations.backToInbox}
              </NavLink>
              <h2>{conversation.friendlyName}</h2>
              <Chat
                className={`conversation ${
                  (isConversationClosed || isUnavailableTask) && 'disabled'
                }`}
                user={currentUser}
                messages={mappedMessages}
                onMessageSend={addNewMessage}
                placeholder={
                  !!mappedMessages ? translations.placeholderText : undefined
                }
              />
              {(isConversationClosed || isUnavailableTask) && (
                <p className='closed-conversation alert alert-secondary'>
                  {isConversationClosed && (
                    <span>{translations.warningConversationStatus}</span>
                  )}
                  {isUnavailableTask && (
                    <span>{translations.warningUnavailableTask}</span>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Conversation;
