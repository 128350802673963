import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslate } from '../../../resources/useTranslate';

import {
  setStateSort,
  setStateFilters,
  setFiltersStatus,
  selectDocumentsFilter,
  selectFiltersStatus,
  setCurrentSortOption,
  selectDocumentsCurrentSortOption,
} from './documentsSlice';

import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from '@progress/kendo-data-query';

import './Filters.scss';

type FilterLabelTypes = {
  nameAsc: string;
  nameDesc: string;
  dateNewestFirst: string;
  dateOldestFirst: string;
  filterByContacts: string;
  filterByCategory: string;
  filterByType: string;
  filterByYear: string;
  sortBy: string;
  closeFilters: string;
  applyFilters: string;
  clearFilters: string;
};
export interface FiltersProps {
  documents: object[];
  filtersStatus?: boolean;
  labels?: FilterLabelTypes;
}

const Filters = ({ documents, filtersStatus, labels }: FiltersProps) => {
  const dispatch = useDispatch();
  const ns = 'construo.documents';
  const translations = {
    nameAsc: useTranslate(`${ns}.nameAsc`),
    nameDesc: useTranslate(`${ns}.nameDesc`),
    dateNewestFirst: useTranslate(`${ns}.dateNewestFirst`),
    dateOldestFirst: useTranslate(`${ns}.dateOldestFirst`),
    filterByContacts: useTranslate(`${ns}.filterByContacts`),
    filterByCategory: useTranslate(`${ns}.filterByCategory`),
    filterByType: useTranslate(`${ns}.filterByType`),
    filterByYear: useTranslate(`${ns}.filterByYear`),
    sortBy: useTranslate(`${ns}.sortBy`),
    closeFilters: useTranslate(`${ns}.closeFilters`),
    applyFilters: useTranslate(`${ns}.applyFilters`),
    clearFilters: useTranslate(`${ns}.clearFilters`),
  };
  labels = labels === undefined ? translations : labels;

  const reduxSortOption = useSelector(selectDocumentsCurrentSortOption);

  const filter = useSelector(selectDocumentsFilter);

  const reduxFiltersStatus = useSelector(selectFiltersStatus);

  filtersStatus =
    filtersStatus === undefined ? reduxFiltersStatus : filtersStatus;

  const initialFilterValue = {
    logic: 'and',
    filters: [],
  } as CompositeFilterDescriptor;

  const contactCombobox: any = useRef(null);
  const categoryCombobox: any = useRef(null);
  const typeCombobox: any = useRef(null);
  const yearCombobox: any = useRef(null);

  // Clear filters
  const clearFilters = (e: React.MouseEvent) => {
    dispatch(setStateFilters(initialFilterValue));
    contactCombobox.current.state.value = null;
    categoryCombobox.current.state.value = null;
    typeCombobox.current.state.value = null;
    yearCombobox.current.state.value = null;
  };

  // Unique dataCategories
  const dataCategories = documents
    .map((document: any) => document.Category && document.Category)
    .filter(
      (value, index: number, self) => !!value && self.indexOf(value) === index
    );

  // Unique dataTypes
  const dataTypes = documents
    .map((document: any) => document.DocumentType && document.DocumentType)
    .filter(
      (value, index: number, self) => !!value && self.indexOf(value) === index
    );

  // Unique Contacts
  const documentsWithContacts = documents.filter(
    (document: any) =>
      document.Contacts !== undefined && document.Contacts.length > 0
  );
  const contacts = documentsWithContacts.map(
    (document: any) => document.Contacts
  );
  let concatContacts: any = [];
  contacts.map((contactsArray: any) => {
    if (contactsArray.length > 1) {
      contactsArray.map((contact: any) => {
        concatContacts.push(contact);
        return false;
      });
    } else if (contactsArray.length === 1) {
      concatContacts.push(contactsArray[0]);
    }
    return false;
  });
  const uniqueContacts = Array.from(
    new Set(concatContacts.map((c: any) => c.ContactGuid))
  ).map(guid => {
    return {
      ContactGuid: guid,
      ContactType: concatContacts.find((c: any) => c.ContactGuid === guid)
        .ContactType,
      FullName: concatContacts.find((c: any) => c.ContactGuid === guid)
        .FullName,
      Links: concatContacts.find((c: any) => c.ContactGuid === guid).Links,
    };
  });
  const dataContacts = uniqueContacts;

  // Unique dataYears
  const dataYears = documents
    .map(
      (document: any) =>
        document.DocumentDate &&
        new Date(document.DocumentDate).getFullYear().toString()
    )
    .filter(
      (value, index: number, self) => !!value && self.indexOf(value) === index
    );

  // Filter by Contact
  const handleFilterByContact = (value: string | null) => {
    const contactObject = dataContacts.find(
      (contact: any) => contact.ContactGuid === value
    );
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'Contacts';
    });
    const newFilters = {
      ...filter,
      filters: !!value
        ? [
            ...filtersReduced,
            {
              field: 'Contacts',
              operator: (itemValue: any, value: any) => {
                return (
                  itemValue &&
                  itemValue.find(function (item: any) {
                    return item.ContactGuid === value;
                  })
                );
              },
              value: contactObject?.ContactGuid,
            },
          ]
        : [...filtersReduced],
    };
    dispatch(setStateFilters(newFilters));
  };

  // Filter by document category
  const handleFilterByDocumentCategory = (value: string | null) => {
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'Category';
    });
    const newFilters = {
      ...filter,
      filters: !!value
        ? [
            ...filtersReduced,
            { field: 'Category', operator: 'eq', value: value },
          ]
        : [...filtersReduced],
    };
    dispatch(setStateFilters(newFilters));
  };

  // Filter by document type
  const handleFilterByDocumentType = (value: string | null) => {
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'DocumentType';
    });
    const newFilters = {
      ...filter,
      filters: !!value
        ? [
            ...filtersReduced,
            { field: 'DocumentType', operator: 'eq', value: value },
          ]
        : [...filtersReduced],
    };
    dispatch(setStateFilters(newFilters));
  };

  // Filter by document year
  const handleFilterByDocumentYear = (value: string | null) => {
    const filtersReduced = filter.filters.filter((item: any) => {
      return item.field !== 'DocumentDate';
    });

    const newFilters = {
      ...filter,
      filters: !!value
        ? [
            ...filtersReduced,
            {
              field: 'DocumentDate',
              operator: (itemValue: any, value: any) => {
                const dateString = itemValue.toUpperCase();
                const date = new Date(dateString);
                const getYear = date.getFullYear();
                return itemValue && getYear.toString() === value;
              },
              value: value,
            },
          ]
        : [...filtersReduced],
    };
    dispatch(setStateFilters(newFilters));
  };

  // Sorting options
  const sortOptions = [
    {
      sortId: 1,
      sortCriteria: labels.dateNewestFirst,
    },
    {
      sortId: 2,
      sortCriteria: labels.dateOldestFirst,
    },
    {
      sortId: 3,
      sortCriteria: labels.nameAsc,
    },
    {
      sortId: 4,
      sortCriteria: labels.nameDesc,
    },
  ];

  const getCurrentSort = () => {
    const sortValue = sortOptions.filter(
      option => option.sortId === reduxSortOption
    );
    return sortValue;
  };

  const currentSortOption = getCurrentSort();

  const documentNameAsc = [
    { field: 'DocumentName', dir: 'asc' } as SortDescriptor,
  ];
  const documentNameDesc = [
    { field: 'DocumentName', dir: 'desc' } as SortDescriptor,
  ];
  const dateAsc = [{ field: 'DocumentDate', dir: 'asc' } as SortDescriptor];
  const dateDesc = [{ field: 'DocumentDate', dir: 'desc' } as SortDescriptor];

  type sortTypes = {
    sortId?: number;
    sortCriteria?: string;
  } | null;

  // Sorting list
  const handleSortChange = (value: sortTypes) => {
    const sortId = value?.sortId;
    switch (sortId) {
      case 1:
        dispatch(setStateSort(dateDesc));
        dispatch(setCurrentSortOption(3));
        break;
      case 2:
        dispatch(setStateSort(dateAsc));
        dispatch(setCurrentSortOption(4));
        break;
      case 3:
        dispatch(setStateSort(documentNameAsc));
        dispatch(setCurrentSortOption(1));
        break;
      case 4:
        dispatch(setStateSort(documentNameDesc));
        dispatch(setCurrentSortOption(2));
        break;
      default:
      // code
    }
  };

  const filterByContactsRef: any = useRef(null);
  const filterByCategoryRef: any = useRef(null);
  const filterByTypeRef: any = useRef(null);
  const filterByYearRef: any = useRef(null);
  const sortByRef: any = useRef(null);

  return (
    <div className={filtersStatus ? 'filters on' : 'filters'}>
      <Button
        className='close-btn'
        iconClass='fal fa-times'
        onClick={() => {
          dispatch(setFiltersStatus(false));
        }}
      >
        <span className='sr-only'>{labels.closeFilters}</span>
      </Button>

      <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3'>
        {dataContacts.length > 0 && (
          <div className='col'>
            <div ref={filterByContactsRef}>
              <ComboBox
                ref={contactCombobox}
                label={labels.filterByContacts}
                data={dataContacts}
                textField='FullName'
                dataItemKey='ContactGuid'
                suggest={true}
                onChange={e =>
                  handleFilterByContact(e.value ? e.value.ContactGuid : '')
                }
                popupSettings={{
                  appendTo: filterByContactsRef.current,
                }}
              />
            </div>
          </div>
        )}

        {dataCategories.length > 0 && (
          <div className='col'>
            <div ref={filterByCategoryRef}>
              <ComboBox
                ref={categoryCombobox}
                label={labels.filterByCategory}
                data={dataCategories}
                suggest={true}
                onChange={e => handleFilterByDocumentCategory(e.value)}
                popupSettings={{
                  appendTo: filterByCategoryRef.current,
                }}
              />
            </div>
          </div>
        )}

        {dataTypes.length > 0 && (
          <div className='col'>
            <div ref={filterByTypeRef}>
              <ComboBox
                ref={typeCombobox}
                label={labels.filterByType}
                data={dataTypes}
                suggest={true}
                onChange={e => handleFilterByDocumentType(e.value)}
                popupSettings={{
                  appendTo: filterByTypeRef.current,
                }}
              />
            </div>
          </div>
        )}

        <div className='col'>
          <div ref={filterByYearRef}>
            <ComboBox
              ref={yearCombobox}
              label={labels.filterByYear}
              data={dataYears}
              suggest={true}
              onChange={e => handleFilterByDocumentYear(e.value)}
              popupSettings={{
                appendTo: filterByYearRef.current,
              }}
            />
          </div>
        </div>

        <div className='col'>
          <div ref={sortByRef}>
            <DropDownList
              label={labels.sortBy}
              data={sortOptions}
              textField='sortCriteria'
              dataItemKey='sortId'
              defaultValue={currentSortOption[0]}
              onChange={e => handleSortChange(e.value)}
              popupSettings={{
                appendTo: sortByRef.current,
              }}
            />
          </div>
        </div>
      </div>

      <div className='filter-buttons'>
        <div className='row row-cols-1 row-cols-sm-2'>
          <div className='col'>
            <div className='apply-btn'>
              <Button
                primary={true}
                onClick={() => {
                  dispatch(setFiltersStatus(false));
                }}
              >
                {labels.applyFilters}
              </Button>
            </div>
          </div>
          <div className='col'>
            <div className='clear-btn'>
              <Button
                className='btn-secondary'
                look='outline'
                // primary={true}
                onClick={event => {
                  clearFilters(event);
                }}
              >
                {labels.clearFilters}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;
