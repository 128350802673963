import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const MessagesSkeleton = () => {
  return (
    <section className='main-section task-messages'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-xl-9'>
            <h2>
              <Skeleton shape={'text'} style={{ maxWidth: 300 }} />
            </h2>
            <Skeleton shape={'rectangle'} style={{ height: 150 }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MessagesSkeleton;
