import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { match } from 'react-router';
import { NavLink } from 'react-router-dom';
import envVars from '../../../resources/envVars';
import HelmetTitle from '../../common/HelmetTitle';
import { useTranslate } from '../../../resources/useTranslate';
import Actions from './Actions';
import Messages from './Messages';
import { resetAlerts, setAlerts } from '../../../app/slices/alertSlice';
import {
  fetchWorkflows,
  selectWorkflows,
  selectWorkflowsFetched,
} from '../workflowSlice';
import Details from './Details';
import {
  fetchTask,
  resetTaskDetailsData,
  selectTaskDetails,
  selectTaskDetailsFetched,
} from './taskDetailsSlice';
import {
  fetchMessages,
  selectMessages,
  selectMessagesFetched,
} from './messagesSlice';

import DetailsSkeleton from './DetailsSkeleton';
import MessagesSkeleton from './MessagesSkeleton';
import ActionsSkeleton from './ActionsSkeleton';

import './TaskDetails.scss';

export interface TaskRouteParams {
  id: string;
}

interface TaskProps {
  match: match<TaskRouteParams>;
}

const TaskDetails = ({ match }: TaskProps) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const conversationGuid = urlParams.get('conversationGuid');

  const isScrollIntoView = !!conversationGuid ? true : false;

  const dispatch = useDispatch();
  const taskId = match.params.id;

  const ns = 'construo.conversations';
  const translations = {
    backToInbox: useTranslate(`${ns}.backToInbox`),
    couldNotFoundContent: useTranslate(`${ns}.couldNotFoundContent`),
    taskDoesNotExist: useTranslate(`${ns}.taskDoesNotExist`),
    canSeeHistory: useTranslate(`${ns}.canSeeHistory`),
    conversationLinkLabel: useTranslate(`${ns}.conversationLinkLabel`),
    noWorkflowName: useTranslate('construo.workflows.noWorkflowName'),
  };

  const [workflowId, setWorkflowId] = useState<string | null>(null);

  const API_BASE_URI = envVars.API_BASE_URI || '';

  const taskDetails: taskDetailsType = useSelector(selectTaskDetails);
  const taskDetailsFetched = useSelector(selectTaskDetailsFetched);

  const taskTitle = taskDetails?.displayName;
  const taskDescription = taskDetails?.description;
  const taskContacts = taskDetails?.accessRightsContacts;
  const taskActions: [] = taskDetails?.actions;

  const workflows: workflowsType = useSelector(selectWorkflows);
  const workflowsFetched = useSelector(selectWorkflowsFetched);

  useEffect(() => {
    if (workflowsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchWorkflows(dataObject));
    }
  }, [dispatch, API_BASE_URI, workflowsFetched]);

  const findWorkflowNameByWorkflowId = (id: string | null) => {
    const workflow: {
      id: string;
      displayName: string;
      description: string;
      tasks: object[];
    } = workflows.filter(workflow => workflow.id === id)[0];
    const workflowName = workflow.displayName;
    return workflowName;
  };

  const workflowName =
    workflows.length > 0 &&
    !!workflowId &&
    findWorkflowNameByWorkflowId(workflowId);

  const findWorkflowIdByTaskId = useCallback(
    (taskId: any) => {
      let _wfId: string | null = null;
      const isTaskIncludedInWorkflow = (wf: any) => {
        const isExists =
          wf.tasks.filter((task: any) => task.id === taskId).length > 0;

        return isExists;
      };
      workflows.forEach((wf: any) => {
        if (isTaskIncludedInWorkflow(wf)) {
          _wfId = wf.id;
        } else {
          return false;
        }
      });
      return _wfId;
    },
    [workflows]
  );

  const [workflowIdAttempt, setWorkflowIdAttempt] = useState(false);

  useEffect(() => {
    if (workflows.length > 0 && workflowId === null && !workflowIdAttempt) {
      // Prevent to many renders in case conversation could not be found in workflows and tasks
      setWorkflowIdAttempt(true);
      const workflowId = findWorkflowIdByTaskId(taskId);
      setWorkflowId(workflowId);
    }
  }, [
    findWorkflowIdByTaskId,
    taskId,
    workflowId,
    workflowIdAttempt,
    workflows.length,
  ]);

  useEffect(() => {
    const dataObject = {
      baseUrl: API_BASE_URI,
      workflowId,
      taskId,
    };
    if (taskDetailsFetched === null && !!workflowId) {
      dispatch(fetchTask(dataObject));
    }
  }, [dispatch, API_BASE_URI, taskDetailsFetched, workflowId, taskId]);

  // Messages
  const messages = useSelector(selectMessages);
  const messagesFetched = useSelector(selectMessagesFetched);

  useEffect(() => {
    if (messagesFetched === null && !!workflowId) {
      const conversationsUrl: string =
        `${envVars?.API_BASE_URI}/user/conversations?resourceId=${taskId}&conversationType=1&resourceType=1&parentId=${workflowId}` ||
        '';
      const dataObject = {
        url: conversationsUrl,
      };
      dispatch(fetchMessages(dataObject));
    } else {
      if (workflowIdAttempt && !workflowId) {
        dispatch(resetAlerts());
        dispatch(
          setAlerts({
            message: translations.couldNotFoundContent,
            type: 'warning',
          })
        );
      }
    }
  }, [
    dispatch,
    messagesFetched,
    taskId,
    workflowId,
    conversationGuid,
    workflowIdAttempt,
    translations.couldNotFoundContent,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetTaskDetailsData());
      dispatch(resetAlerts());
    };
  }, [dispatch]);

  const cantShowTaskDetails = workflowIdAttempt && !workflowId;

  const workflowTitle = !!workflowName
    ? workflowName
    : translations.noWorkflowName;

  return (
    <>
      {cantShowTaskDetails ? (
        <>
          <HelmetTitle title={translations.taskDoesNotExist} />
          <section className='main-section task-details'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12'>
                  <NavLink className='back-link' to='/conversations'>
                    {translations.backToInbox}:
                  </NavLink>
                  <h1>{translations.taskDoesNotExist}</h1>
                  <p>
                    {translations.canSeeHistory}{' '}
                    <NavLink
                      to={`/conversations/${conversationGuid}?unavailable=true`}
                    >
                      {translations.conversationLinkLabel}
                    </NavLink>
                    .
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>
          <HelmetTitle title={`${workflowTitle}: ${taskTitle}`} />
          {!taskDetailsFetched ? (
            <DetailsSkeleton />
          ) : (
            <Details
              workflowId={workflowId}
              workflowName={workflowName}
              taskTitle={taskTitle}
              taskDescription={taskDescription}
              taskContacts={taskContacts}
              taskId={taskDetails.id}
            />
          )}

          {!taskDetailsFetched ? (
            <ActionsSkeleton />
          ) : (
            <Actions
              workflowId={workflowId}
              taskId={taskId}
              actions={taskActions}
            />
          )}

          {!messagesFetched ? (
            <MessagesSkeleton />
          ) : (
            <Messages
              workflowId={workflowId}
              taskId={taskId}
              taskTitle={taskTitle}
              messages={messages}
              isScrollIntoView={isScrollIntoView}
            />
          )}
        </>
      )}
    </>
  );
};

type workflowsType = {
  id: string;
  displayName: string;
  description: string;
  tasks: object[];
}[];

type taskDetailsType = {
  workflowId: string;
  id: string;
  displayName: string | null;
  description: string | null;
  accessRightsContacts: [];
  actions: [];
  openUserActions: boolean;
  status: string;
  progress: object;
};

export default TaskDetails;
