import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import Footer from './Footer';
import {
  selectVisibilityMenu,
  setVisibilityMenu,
  VisibilityMenu,
} from '../../app/slices/visibilityMenuSlice';
import { selectAlert } from '../../app/slices/alertSlice';
import Alerts from '../common/Alerts';
import GlobalMenuDataProvider from './GlobalMenuDataProvider';

import './Layout.scss';

export interface LayoutProps {
  children?: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const dispatch = useDispatch();
  const visibilityMenu = useSelector(selectVisibilityMenu);
  const isMenuVisible = visibilityMenu === VisibilityMenu.Show;
  const showMenu = () => dispatch(setVisibilityMenu(VisibilityMenu.Show));
  const hideMenu = () => dispatch(setVisibilityMenu(VisibilityMenu.Hide));

  const alerts = useSelector(selectAlert);

  return (
    <div className={`layout ${isMenuVisible ? 'global-menu-visible' : ''}`}>
      <Header onShowMenu={showMenu} onHideMenu={hideMenu} />
      <Alerts alerts={alerts} />
      <GlobalMenuDataProvider onHideMenu={hideMenu} />
      <main>{children}</main>
      <Footer />
      <div className='global-menu-overlay' onClick={hideMenu} />
    </div>
  );
};

export default Layout;
