import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import axios from 'axios';
import { getAxiosConfig } from '../../../helpers/utils';
import authService from '../../api-authorization/AuthorizeService';

// Gateway API version - getting User Conversations List from Gateway API
export const fetchConversationsList = createAsyncThunk(
  'fetchConversationsList',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.get(url, config);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Gateway API version - getting Topics List from Gateway API
export const fetchTopics = createAsyncThunk(
  'fetchTopics',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.get(url, config);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Gateway API version - get single conversation by conversation guid from Gateway API
export const fetchConversation = createAsyncThunk(
  'fetchConversation',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.get(url, config);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Gateway API version - get total number of conversations with unread messages from Gateway API
export const fetchUnreadConversations = createAsyncThunk(
  'fetchUnreadConversations',
  async (dataObject: any, thunkAPI) => {
    const token = await authService.getAccessToken();
    const url = dataObject.url;
    const config = getAxiosConfig(token, 'json');
    try {
      const response = await axios.get(url, config);
      const data = response.data;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

interface ConversationsState {
  data: any;
  conversationsListFetched: boolean | null;
  conversationsListError?: string;
  topics: any;
  topicsFetched: boolean | null;
  topicsError?: string;
  conversation: any;
  conversationFetched: boolean | null;
  conversationError?: string | null;
  unreadConversations: number;
  unreadConversationsFetched: boolean | null;
  unreadConversationsError?: string;
}

const initialState: ConversationsState = {
  data: [],
  conversationsListFetched: null,
  topics: [],
  topicsFetched: null,
  conversation: {},
  conversationFetched: null,
  unreadConversations: 0,
  unreadConversationsFetched: null,
};

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    resetConversationsList: state => {
      state.data = [];
      state.conversationsListFetched = null;
    },
    resetConversation: state => {
      state.conversation = {};
      state.conversationFetched = null;
    },
    resetUnreadConversations: state => {
      state.unreadConversations = 0;
      state.unreadConversationsFetched = null;
    },
    resetConversationError: state => {
      state.conversationError = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchConversationsList.pending, (state, action) => {
        // console.log('CONVERSATIONS LIST LOADING');
        state.conversationsListFetched = false;
      })
      .addCase(fetchConversationsList.fulfilled, (state, action) => {
        // console.log('CONVERSATIONS LIST FULFILLED');
        state.data = action.payload.data;
        state.conversationsListFetched = true;
      })
      .addCase(fetchConversationsList.rejected, (state, action: any) => {
        state.conversationsListFetched = true;
        // console.log('CONVERSATIONS LIST REJECTED', action);
        state.conversationsListError = action?.payload?.response?.data?.Message;
      })

      .addCase(fetchTopics.pending, (state, action) => {
        // console.log('TOPICS LOADING');
        state.topicsFetched = false;
      })
      .addCase(fetchTopics.fulfilled, (state, action) => {
        // console.log('TOPICS FULFILLED');
        state.topics = action.payload.data;
        state.topicsFetched = true;
      })
      .addCase(fetchTopics.rejected, (state, action: any) => {
        state.topicsFetched = true;
        // console.log('TOPICS REJECTED', action);
        state.topicsError = action?.payload?.response?.data?.Message;
      })

      .addCase(fetchConversation.pending, (state, action) => {
        // console.log('SINGLE CONVERSATION LOADING');
        state.conversationFetched = false;
      })
      .addCase(fetchConversation.fulfilled, (state, action) => {
        // console.log('SINGLE CONVERSATION FULFILLED');
        state.conversation = action.payload.data;
        state.conversationFetched = true;
      })
      .addCase(fetchConversation.rejected, (state, action: any) => {
        state.conversationFetched = true;
        // console.log('SINGLE CONVERSATION REJECTED', action);
        state.conversationError = action?.payload?.response?.data?.Message;
      })

      .addCase(fetchUnreadConversations.pending, (state, action) => {
        // console.log('UNREAD CONVERSATIONS LOADING');
        state.unreadConversationsFetched = false;
      })
      .addCase(fetchUnreadConversations.fulfilled, (state, action) => {
        // console.log('UNREAD CONVERSATIONS FULFILLED');
        state.unreadConversations = action.payload;
        state.unreadConversationsFetched = true;
      })
      .addCase(fetchUnreadConversations.rejected, (state, action: any) => {
        state.unreadConversationsFetched = true;
        // console.log('UNREAD CONVERSATIONS REJECTED', action);
        state.unreadConversationsError =
          action?.payload?.response?.data?.Message;
      });
  },
});

export const {
  resetConversationsList,
  resetConversation,
  resetUnreadConversations,
  resetConversationError,
} = conversationsSlice.actions;

export const selectConversationsList = (state: RootState) =>
  state.conversations.data;
export const selectConversationsListFetched = (state: RootState) =>
  state.conversations.conversationsListFetched;

export const selectTopics = (state: RootState) => state.conversations.topics;
export const selectTopicsFetched = (state: RootState) =>
  state.conversations.topicsFetched;

export const selectConversation = (state: RootState) =>
  state.conversations.conversation;
export const selectConversationFetched = (state: RootState) =>
  state.conversations.conversationFetched;

export const selectConversationError = (state: RootState) =>
  state.conversations.conversationError;

export const selectUnreadConversations = (state: RootState) =>
  state.conversations.unreadConversations;
export const selectUnreadConversationsFetched = (state: RootState) =>
  state.conversations.unreadConversationsFetched;

export default conversationsSlice.reducer;
