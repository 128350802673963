import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AlertProps {
  message: string | null;
  type: 'info' | 'warning' | 'error' | 'success' | null;
}

export interface Alerts {
  data: AlertProps[];
}

const initialState: Alerts = {
  data: [],
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlerts: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    replaceAlerts: (state, action) => {
      state.data = action.payload;
    },
    resetAlerts: state => {
      state.data = [];
    },
  },
});

export const { setAlerts, replaceAlerts, resetAlerts } = alertSlice.actions;

export const selectAlert = (state: RootState) => state.alert.data;

export default alertSlice.reducer;
