import React, { useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useDispatch, useSelector } from 'react-redux';
import { Loading, selectLoading } from '../../../app/slices/loadingSlice';
import { Modal, setModal } from '../../../app/slices/modalSlice';
import { selectPreviewProps } from '../../pages/documents/documentsSlice';
import {
  Dialog,
  DialogActionsBar,
  DialogCloseEvent,
} from '@progress/kendo-react-dialogs';
import { useTranslate } from '../../../resources/useTranslate';
// import { saveAs } from '@progress/kendo-file-saver';

import './DocumentModal.scss';

export interface DocumentModalProps {
  props?: any;
  notSupported?: string;
  btnLabel?: string;
  blobSrc?: string;
}

const isSafari = () => {
  const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return is_safari;
};

const DocumentModal = (props: DocumentModalProps) => {
  const dispatch = useDispatch();

  const translations = {
    download: useTranslate('construo.global.download'),
    previewNotSupported: useTranslate('construo.global.previewNotSupported'),
  };

  const isEmptyObject = Object.keys(props).length === 0;

  const reduxPreviewProps = useSelector(selectPreviewProps);
  const previewProps = !isEmptyObject ? props.props : reduxPreviewProps;

  const contentType = previewProps?.docType;
  const documentSrc = previewProps?.url;

  const documentName = previewProps?.name;
  const documentContacts = previewProps?.contacts;
  const documentCategory = previewProps?.category;

  const previewNotSupported = !!props.notSupported
    ? props.notSupported
    : translations.previewNotSupported;
  const blobSource = props.blobSrc;
  const documentBtnLabel = !!props.btnLabel
    ? props.btnLabel
    : translations.download;

  const docLoading = useSelector(selectLoading) === Loading.Show;

  const isSafariBrowser = isSafari();

  /**
   * This part of code is used to read blob - readAsDataURL
   */
  // let blob;
  // let reader: any;
  // let readerResult: any;
  // const readBlob = async () => {
  //   blob = await fetch(documentSrc).then(r => r.blob());
  //   reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   reader.onloadend = (event: any) => {
  //     // The contents of the BLOB are in reader.result:
  //     readerResult = reader.result;
  //     console.log('blob: ', readerResult);
  //   };
  // };
  // if (!!documentSrc) {
  //   readBlob();
  // }

  const contactsList =
    documentContacts?.length > 0
      ? documentContacts?.map((contact: any, index: number) => {
          const fullName = contact.FullName;
          return <span key={index}>{fullName}</span>;
        })
      : '';

  useEffect(() => {
    if (!docLoading && contentType !== '') {
      const object: HTMLElement | null = document.getElementById('object');
      const img: HTMLElement | null = document.getElementById('image');

      if (contentType === 'application/pdf') {
        if (!!blobSource) {
          object?.setAttribute('data', blobSource);
        } else {
          object?.setAttribute('data', documentSrc);
        }
      } else {
        if (!!blobSource) {
          img?.setAttribute('src', blobSource);
        } else {
          img?.setAttribute('src', documentSrc);
        }
      }
    }
  }, [documentSrc, contentType, docLoading, blobSource]);

  const downloadDocument = () => {
    const link = document.createElement('a');
    link.href = documentSrc;
    link.download = documentName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // saveAs(documentSrc, documentName);
  };

  const CustomTitleBar: any = () => {
    return (
      <>
        {!!documentCategory && (
          <div className='document-category'>{documentCategory}</div>
        )}
        <h5>{documentName}</h5>
        {!!documentContacts && (
          <div className='document-contacts'>{contactsList}</div>
        )}
      </>
    );
  };

  return (
    <>
      <Dialog
        className={
          contentType === 'application/pdf'
            ? 'document-modal-dialog pdf'
            : 'document-modal-dialog'
        }
        title={<CustomTitleBar />}
        onClose={(event: DialogCloseEvent) => dispatch(setModal(Modal.Hide))}
      >
        {contentType === 'application/pdf' ? (
          <>
            <object
              id='object'
              title=''
              type={isSafariBrowser ? '' : 'application/pdf'}
            >
              <div id='append-container'>
                {previewNotSupported}
                <br />
              </div>
            </object>
          </>
        ) : (
          <>
            <img
              id='image'
              alt={documentName}
              title={documentName}
              style={{
                maxHeight: '100%',
                maxWidth: '100%',
              }}
            />
          </>
        )}

        <DialogActionsBar>
          <Button
            primary
            onClick={() => {
              dispatch(setModal(Modal.Hide));
              downloadDocument();
            }}
          >
            <i className='fal fa-download' style={{ fontSize: '24px' }} />
            <span className='icon-label'>{documentBtnLabel}</span>
          </Button>
        </DialogActionsBar>
      </Dialog>
    </>
  );
};

export default DocumentModal;
