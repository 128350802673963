import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import envVars from '../../resources/envVars';
import WorkflowRequest from './WorkflowRequest';
import {
  fetchConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessages,
  selectConversationsWithUnreadMessagesFetched,
} from './taskDetails/messagesSlice';
import {
  fetchWorkflows,
  selectWorkflows,
  selectWorkflowsFetched,
} from './workflowSlice';

export interface WorkflowsListProps {
  workflows?: object[] | null;
}

const WorkflowsList = ({ workflows = null }: WorkflowsListProps) => {
  const dispatch = useDispatch();

  const API_BASE_URI = envVars.API_BASE_URI || '';
  const unreadMessagesUrl = `${API_BASE_URI}/user/conversations/unreadactivities?page=1&pageSize=99`;

  workflows = useSelector(selectWorkflows);

  const workflowsFetched = useSelector(selectWorkflowsFetched);

  useEffect(() => {
    if (workflowsFetched === null) {
      const dataObject = {
        baseUrl: API_BASE_URI,
      };
      dispatch(fetchWorkflows(dataObject));
    }
  }, [dispatch, workflowsFetched, API_BASE_URI]);

  const conversations = useSelector(selectConversationsWithUnreadMessages);
  const conversationsFetched = useSelector(
    selectConversationsWithUnreadMessagesFetched
  );
  useEffect(() => {
    if (conversationsFetched === null) {
      const dataObject = {
        url: unreadMessagesUrl,
      };
      dispatch(fetchConversationsWithUnreadMessages(dataObject));
    }
  }, [dispatch, conversationsFetched, unreadMessagesUrl]);

  return (
    <>
      {workflows !== null &&
        workflows.map((workflow: any) => {
          return (
            <WorkflowRequest
              key={workflow.id}
              workflow={workflow}
              conversations={conversations}
            />
          );
        })}
    </>
  );
};

export default WorkflowsList;
