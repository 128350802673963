import React, { useEffect, useState } from 'react';
import { useTranslate } from '../../resources/useTranslate';
import envVars from '../../resources/envVars';

const Logo = () => {
  const customerName = envVars.CUSTOMER_NAME;
  const customFolderName = envVars.CUSTOM_FOLDER_NAME;

  const [logotype, setLogotype] = useState();

  const ns = 'construo.header';
  const logoAltText = useTranslate(`${ns}.logoAlt`, customerName);

  useEffect(() => {
    if (customFolderName) {
      // Load customer specific Logo
      (async () => {
        const logo = await import(
          `../../custom/${customFolderName}/assets/media/logo.svg`
        );
        setLogotype(logo?.default);
      })();
    }
  }, [customFolderName]);

  return <>{!!logotype && <img src={logotype} alt={logoAltText} />}</>;
};

export default Logo;
