import React from 'react';
import { Skeleton } from '@progress/kendo-react-indicators';

export const ActionsSkeleton = () => {
  return (
    <section className='main-section task-action'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-xl-9'>
            <h2>
              <Skeleton shape={'text'} style={{ maxWidth: 300 }} />
            </h2>
            <p>
              <Skeleton shape={'text'} style={{ maxWidth: 400 }} />
              <Skeleton shape={'text'} style={{ maxWidth: 450 }} />
              <Skeleton shape={'text'} style={{ maxWidth: 350 }} />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ActionsSkeleton;
