import React from 'react';
import { useTranslate } from '../../resources/useTranslate';
import ActionRequest from '../common/ActionRequest';

const SubmissionRequest = (props: any) => {
  const { submission } = props;

  const ns = 'construo.forms';
  const translations = {
    noSubmissionName: useTranslate(`${ns}.noSubmissionName`),
    pleaseCompleteForm: useTranslate(`${ns}.pleaseCompleteForm`),
  };
  const submissionName = submission.Title;
  const heading = !!submissionName
    ? submissionName
    : translations.noSubmissionName;
  const formSubmissionGuid = submission.FormSubmissionGuid;

  return (
    <ActionRequest
      type='submissionRequest'
      href={`/user/submissions/${formSubmissionGuid}`}
      heading={heading}
      subHeadings={[translations.pleaseCompleteForm]}
    />
  );
};

export default SubmissionRequest;
